.infoRotator {
  position: relative;
  width: 100%;
  max-width: 660px;
  left: 0;
  // left: 112px;
  top: -28px;
  line-height: 0;
  font-size: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.infoRotator-circle,
.infoRotator-wrapper {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.infoRotator-placeholder {
  max-width: none;
  width: 100%;
}
.infoRotator-circle {
  border: 1px solid #e3e3e3;
  border-radius: 50%;
}
.infoRotator-link:after,
.infoRotator-marker {
  border-radius: 50%;
  position: absolute;
  display: block;
}
.infoRotator-circle--1 {
  -webkit-animation: rotate-clockwise 180s linear infinite;
  animation: rotate-clockwise 180s linear infinite;
}
.infoRotator--animation--false .infoRotator-circle--1 {
  -webkit-animation: none;
  animation: none;
}
.infoRotator-circle--2 {
  width: 96%;
  height: 96%;
  top: 2%;
  left: 2%;
}
.infoRotator-circle--3 {
  width: 92%;
  height: 92%;
  top: 4%;
  left: 4%;
  -webkit-animation: rotate-counterclockwise 180s linear infinite;
  animation: rotate-counterclockwise 180s linear infinite;
}
.infoRotator--animation--false .infoRotator-circle--3 {
  -webkit-animation: none;
  animation: none;
}
.infoRotator-marker {
  width: 6px;
  height: 6px;
  top: 0;
  left: 0;
  background-color: #b3b3b3;
  z-index: 3;
}
.infoRotator-link {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  top: -7px;
  left: -7px;
}
.infoRotator-link:after {
  width: 6px;
  height: 6px;
  top: 7px;
  left: 7px;
  background-color: #f2569a;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.infoRotator-link.active:after,
.infoRotator-link:hover:after {
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 1),
    0 0 0 7px rgba(84, 196, 57, 0.2), 0 0 0 8px rgba(255, 255, 255, 1),
    0 0 0 10px rgba(84, 196, 57, 0.1), 0 0 0 11px rgba(255, 255, 255, 1),
    0 0 0 12px rgba(84, 196, 57, 0.05);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 1), 0 0 0 7px rgba(84, 196, 57, 0.2),
    0 0 0 8px rgba(255, 255, 255, 1), 0 0 0 10px rgba(84, 196, 57, 0.1),
    0 0 0 11px rgba(255, 255, 255, 1), 0 0 0 12px rgba(84, 196, 57, 0.05);
}
.infoRotator-content {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  z-index: 1;
}
.infoRotator-box,
.infoRotator-eclipse,
.infoRotator-table {
  position: absolute;
  height: 100%;
  width: 100%;
}
.infoRotator-eclipse {
  display: block;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(../images/home/ellipse-inner.png);
  background-size: contain;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.infoRotator-eclipse.animation {
  -webkit-animation: rotate-clockwise 4s linear;
  animation: rotate-clockwise 4s linear;
}
.infoRotator-box {
  display: none;
  left: 0;
  top: 0;
  font-size: 19px;
  line-height: 30px;
}
.infoRotator-box.active {
  display: block;
}
.infoRotator-table {
  display: table;
  top: 0;
  left: 0;
}


.infoRotator-table td {
  vertical-align: middle;
  text-align:center;

  p{
      font-size: 2rem;
      color: $grey-3;
  }
}
.infoRotator-box h4 {
  margin-bottom: 0;  
  font-size: 180px;
  line-height: 180px;
  color: $secondary;
}
.infoRotator-box p {
  padding-left: 16%;
  padding-right: 16%;
}
@media (max-width: 1600px) {
  .infoRotator {
    left: 0;
  }
}
@media (max-width: 1439px) {
  .infoRotator-box h4 {
    font-size: 140px;
    line-height: 140px;
  }
}
@media (max-width: 1199px) {
  .infoRotator-box h4 {
    font-size: 100px;
    line-height: 100px;
  }
}
@-webkit-keyframes rotate-clockwise {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-clockwise {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate-counterclockwise {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes rotate-counterclockwise {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}