

.st-sprite {
    display: inline-block;
    background-image: url(../images/1_4.png);
    background-repeat: no-repeat
}

.st-sprite.-money-bhaskar {
    background-position: -29px -56px;
    width: 245px;
    height: 70px
}

.st-sprite.-tech-panda {
    background-position: -21px -166px;
    width: 241px;
    height: 70px;
}

.st-sprite.-new-delhi-times {
    background-position: -21px -363px;
    width: 250px;
    height: 70px
}

.st-sprite.-dq-week {
    background-position: -31px -483px;
    width: 250px;
    height: 70px
}

.st-sprite.-startup-journey {
    background-position: -31px -837px;
    width: 250px;
    height: 70px
}

@media (-webkit-min-device-pixel-ratio:2),
(-o-min-device-pixel-ratio:2/1),
(min-resolution:192dpi) {
    .st-sprite {
        background-image: url(../images/sprites@2x.png);
        -webkit-background-size: 125px 63px;
        -moz-background-size: 125px 63px;
        background-size: 125px 63px
    }
}

.st-svgsprite {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: middle
}

.st-view {
    min-height: 100%;
    position: relative;
    overflow: hidden
}

.st-content {
    height: 100%;
    overflow: hidden;
    overflow-y: auto
}

.st-wrap {
    width: 100%;
    padding: 0 0 50px 0
}

@media (min-width:375px) {
    .st-wrap {
        padding: 0 0 70px 0
    }
}

@media (min-width:768px) {
    .st-wrap {
        padding: 0 0 60px 0
    }
}

@media (min-width:1024px) {
    .st-wrap {
        padding: 0 0 80px 0
    }
}

@media (min-width:1280px) {
    .st-wrap {
        padding: 0 0 110px 0
    }
}

@media (min-width:1440px) {
    .st-wrap {
        padding: 0 0 175px 0
    }
}

@media (min-width:1440px) {
    .st-wrap.-lg {
        padding: 0 0 160px 0
    }
}

.st-wrap.-sm {
    width: 100%;
    padding: 0 0 50px 0
}

@media (min-width:375px) {
    .st-wrap.-sm {
        padding: 0 0 70px 0
    }
}

@media (min-width:768px) {
    .st-wrap.-sm {
        padding: 0 0 80px 0
    }
}

@media (min-width:1024px) {
    .st-wrap.-sm {
        padding: 0 0 90px 0
    }
}

@media (min-width:1280px) {
    .st-wrap.-sm {
        padding: 0 0 130px 0
    }
}

.st-wrap.-xs {
    padding: 0 0 10px 0
}

@media (min-width:375px) {
    .st-wrap.-xs {
        padding: 0 0 20px 0
    }
}

@media (min-width:768px) {
    .st-wrap.-xs {
        padding: 0
    }
}

@media (min-width:1024px) {
    .st-wrap.-xs {
        padding: 0 0 40px 0
    }
}

@media (min-width:1280px) {
    .st-wrap.-xs {
        padding: 0 0 30px 0
    }
}

@media (min-width:1440px) {
    .st-wrap.-xs {
        padding: 0 0 60px 0
    }
}

.st-container {
    padding: 0 19px
}

@media (min-width:375px) {
    .st-container {
        padding: 0 35px
    }
}

@media (min-width:768px) {
    .st-container {
        padding: 0 56px
    }
}

@media (min-width:1024px) {
    .st-container {
        padding: 0 54px
    }
}

@media (min-width:1280px) {
    .st-container {
        padding: 0 62px
    }
}

@media (min-width:1440px) {
    .st-container {
        width: 1200px;
        margin: 0 auto;
        padding: 0
    }
}

@media (min-width:1440px) {
    .st-container.-xs {
        width: 1100px;
        margin: 0 auto;
        padding: 0
    }
}

.st-bound {
    width: 100%;
    overflow: hidden
}

.-bg-grey {
    background: #f1f3f4
}

.-bg-white {
    background: #fbfcfc
}

.st-navbar {
    position: fixed;
    width: 100%;
    top: inherit;
    z-index: 100;
    will-change: transform;
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out
}

.st-navbar-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    -webkit-transform: translateY(-60px);
    -moz-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    -o-transform: translateY(-60px);
    transform: translateY(-60px);
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
    background: #0097ff;
    background: -moz-linear-gradient(45deg, #0097ff 0, #5163de 100%);
    background: -webkit-linear-gradient(45deg, #0097ff 0, #5163de 100%);
    background: -o-linear-gradient(45deg, #0097ff 0, #5163de 100%);
    background: linear-gradient(45deg, #0097ff 0, #5163de 100%);
    background-repeat: no-repeat;
    will-change: transform;
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out
}

@media (min-width:768px) {
    .st-navbar-bg {
        height: 84px;
        -webkit-transform: translateY(-84px);
        -moz-transform: translateY(-84px);
        -ms-transform: translateY(-84px);
        -o-transform: translateY(-84px);
        transform: translateY(-84px)
    }
}

.st-navbar-container {
    padding: 0 30px;
    height: 100%
}

@media (min-width:375px) {
    .st-navbar-container {
        padding: 0 37px
    }
}

@media (min-width:768px) {
    .st-navbar-container {
        padding: 0 57px 0 30px
    }
}

@media (min-width:1024px) {
    .st-navbar-container {
        padding: 0 54px
    }
}

@media (min-width:1280px) {
    .st-navbar-container {
        padding: 0 49px
    }
}

@media (min-width:1440px) {
    .st-navbar-container {
        padding: 0 60px
    }
}

.st-navbar-wrap {
    padding: 28px 0 0 0;
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out
}

@media (min-width:768px) {
    .st-navbar-wrap {
        padding: 35px 0 0 0
    }
}

.st-navbar-grid {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.st-navbar-grid-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.st-navbar-logo {
    position: relative;
    z-index: 1002;
    display: block;
    max-width: 90px;
    -webkit-transition: max-width .3s ease-in-out;
    -o-transition: max-width .3s ease-in-out;
    -moz-transition: max-width .3s ease-in-out;
    transition: max-width .3s ease-in-out
}

@media (min-width:768px) {
    .st-navbar-logo {
        max-width: 120px
    }
}

@media (min-width:1280px) {
    .st-navbar-logo {
        max-width: 144px
    }
}

.st-navbar-logo img {
    width: 100%
}

.st-navbar-logo img:last-child {
    display: none
}

.st-navbar-menu {
    display: block
}

@media (max-width:1023px) {
    .st-navbar-menu {
        padding: 0;
        position: fixed;
        z-index: 1001;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        -webkit-transition: .3s;
        -o-transition: .3s;
        -moz-transition: .3s;
        transition: .3s
    }
}

@media (min-width:1024px) {
    .st-navbar-menu {
        padding: 0 0 0 35px
    }
}

@media (min-width:1280px) {
    .st-navbar-menu {
        padding: 0 0 0 130px
    }
}

.st-navbar-menu-item {
    display: inline-block;
    margin: 0 0 25px 0;
    opacity: 0;
    font-size: 18px;
    letter-spacing: .02px;
    line-height: 24px;
    color: #414d5b
}

.st-navbar-menu-item:hover {
    text-decoration: none;
    color: #fff
}

.st-navbar-menu-item:hover span:after {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1)
}

.st-navbar-menu-item:active,
.st-navbar-menu-item:focus {
    color: #fff;
    text-decoration: none
}

@media (min-width:375px) {
    .st-navbar-menu-item {
        margin: 0 0 35px 0;
        font-size: 24px;
        letter-spacing: .03px;
        line-height: 33px
    }
}

@media (min-width:768px) {
    .st-navbar-menu-item {
        margin: 0 0 50px 0
    }
}

@media (min-width:1024px) {
    .st-navbar-menu-item {
        margin: 0;
        padding: 0 17px;
        font-size: 14px;
        letter-spacing: .02px;
        line-height: 19px;
        opacity: 1;
        color: #fff
    }
}

@media (min-width:1280px) {
    .st-navbar-menu-item {
        padding: 0 20px;
        font-size: 16px;
        letter-spacing: .02px;
        line-height: 22px
    }
}

@media (min-width:1440px) {
    .st-navbar-menu-item {
        padding: 0 30px
    }
}

.st-navbar-menu-item span {
    position: relative
}

.st-navbar-menu-item span:after {
    content: '';
    position: absolute;
    left: 50%;
    right: 0;
    bottom: -10px;
    width: 14px;
    height: 3px;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    margin: 0 0 0 -7px;
    background: #0097ff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

@media (min-width:375px) {
    .st-navbar-menu-item span:after {
        width: 16px;
        margin: 0 0 0 -8px
    }
}

@media (min-width:1024px) {
    .st-navbar-menu-item span:after {
        left: 0;
        width: 12px;
        margin: 0;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -ms-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
        background: #fff
    }
}

.st-navbar-menu-item.-active span:after {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1)
}

@media (min-width:1024px) {
    .st-navbar-menu-item.-active span:after {
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -o-transform: scaleX(1);
        transform: scaleX(1)
    }
}

.st-navbar-menu-action {
    opacity: 0
}

.st-navbar-menu-action-item {
    margin: 0 0 20px 0
}

@media (min-width:375px) {
    .st-navbar-menu-action-item {
        margin: 0 0 30px 0
    }
}

.st-navbar-menu-action-item.-md {
    display: block
}

@media (min-width:1024px) {
    .st-navbar-menu-action-item.-md {
        display: none
    }
}

.st-navbar-menu-action-item.-sm {
    display: block
}

@media (min-width:768px) {
    .st-navbar-menu-action-item.-sm {
        display: none
    }
}

@media (max-width:1023px) {
    .st-navbar-menu.-visible {
        opacity: 1;
        pointer-events: auto
    }
}

.st-navbar-menu.-visible .st-navbar-menu-item {
    opacity: 1;
    -webkit-animation: st-navbar-menu-item .4s cubic-bezier(.75, -.5, 0, 1.75) both;
    -moz-animation: st-navbar-menu-item .4s cubic-bezier(.75, -.5, 0, 1.75) both;
    -o-animation: st-navbar-menu-item .4s cubic-bezier(.75, -.5, 0, 1.75) both;
    animation: st-navbar-menu-item .4s cubic-bezier(.75, -.5, 0, 1.75) both
}

.st-navbar-menu.-visible .st-navbar-menu-item:focus,
.st-navbar-menu.-visible .st-navbar-menu-item:hover {
    color: #0097ff
}

.st-navbar-menu.-visible .st-navbar-menu-item:focus span:after,
.st-navbar-menu.-visible .st-navbar-menu-item:hover span:after {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1)
}

@media (min-width:1024px) {
    .st-navbar-menu.-visible .st-navbar-menu-item:focus span:after,
    .st-navbar-menu.-visible .st-navbar-menu-item:hover span:after {
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -o-transform: scaleX(1);
        transform: scaleX(1)
    }
}

.st-navbar-menu.-visible .st-navbar-menu-item:nth-child(0) {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s
}

.st-navbar-menu.-visible .st-navbar-menu-item:nth-child(1) {
    -webkit-animation-delay: 50ms;
    -moz-animation-delay: 50ms;
    -o-animation-delay: 50ms;
    animation-delay: 50ms
}

.st-navbar-menu.-visible .st-navbar-menu-item:nth-child(2) {
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -o-animation-delay: .1s;
    animation-delay: .1s
}

.st-navbar-menu.-visible .st-navbar-menu-item:nth-child(3) {
    -webkit-animation-delay: .15s;
    -moz-animation-delay: .15s;
    -o-animation-delay: .15s;
    animation-delay: .15s
}

.st-navbar-menu.-visible .st-navbar-menu-item:nth-child(4) {
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -o-animation-delay: .2s;
    animation-delay: .2s
}

.st-navbar-menu.-visible .st-navbar-menu-item:nth-child(5) {
    -webkit-animation-delay: .25s;
    -moz-animation-delay: .25s;
    -o-animation-delay: .25s;
    animation-delay: .25s
}

.st-navbar-menu.-visible .st-navbar-menu-item:nth-child(6) {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -o-animation-delay: .3s;
    animation-delay: .3s
}

.st-navbar-menu.-visible .st-navbar-menu-item:nth-child(7) {
    -webkit-animation-delay: .35s;
    -moz-animation-delay: .35s;
    -o-animation-delay: .35s;
    animation-delay: .35s
}

.st-navbar-menu.-visible .st-navbar-menu-action {
    opacity: 1;
    -webkit-animation: st-navbar-menu-item .4s cubic-bezier(.75, -.5, 0, 1.75) both;
    -moz-animation: st-navbar-menu-item .4s cubic-bezier(.75, -.5, 0, 1.75) both;
    -o-animation: st-navbar-menu-item .4s cubic-bezier(.75, -.5, 0, 1.75) both;
    animation: st-navbar-menu-item .4s cubic-bezier(.75, -.5, 0, 1.75) both
}

.st-navbar-menu.-visible .st-navbar-menu-action:nth-child(0) {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s
}

.st-navbar-menu.-visible .st-navbar-menu-action:nth-child(1) {
    -webkit-animation-delay: 50ms;
    -moz-animation-delay: 50ms;
    -o-animation-delay: 50ms;
    animation-delay: 50ms
}

.st-navbar-menu.-visible .st-navbar-menu-action:nth-child(2) {
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -o-animation-delay: .1s;
    animation-delay: .1s
}

.st-navbar-menu.-visible .st-navbar-menu-action:nth-child(3) {
    -webkit-animation-delay: .15s;
    -moz-animation-delay: .15s;
    -o-animation-delay: .15s;
    animation-delay: .15s
}

.st-navbar-menu.-visible .st-navbar-menu-action:nth-child(4) {
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -o-animation-delay: .2s;
    animation-delay: .2s
}

.st-navbar-menu.-visible .st-navbar-menu-action:nth-child(5) {
    -webkit-animation-delay: .25s;
    -moz-animation-delay: .25s;
    -o-animation-delay: .25s;
    animation-delay: .25s
}

.st-navbar-menu.-visible .st-navbar-menu-action:nth-child(6) {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -o-animation-delay: .3s;
    animation-delay: .3s
}

.st-navbar-menu.-visible .st-navbar-menu-action:nth-child(7) {
    -webkit-animation-delay: .35s;
    -moz-animation-delay: .35s;
    -o-animation-delay: .35s;
    animation-delay: .35s
}

.st-navbar-action {
    height: 100%;
    padding: 0;
    position: relative;
    z-index: 1
}

.st-navbar-action-text {
    display: none;
    margin: 0 20px 0 0;
    color: rgba(65, 77, 91, .4)
}

@media (min-width:768px) {
    .st-navbar-action-text {
        display: inline-block
    }
}

.st-navbar-action-item {
    margin: 0 60px 0 0;
    display: none
}

@media (min-width:1024px) {
    .st-navbar-action-item {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 20px
    }
}

.st-navbar-action-item.-sm {
    display: none
}

@media (min-width:768px) {
    .st-navbar-action-item.-sm {
        display: inline-block;
        vertical-align: middle
    }
}

.st-navbar-circle {
    background: #fff;
    width: 23px;
    height: 23px;
    position: fixed;
    top: 32px;
    right: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    z-index: 1;
    -webkit-transition: .2s cubic-bezier(.8, 0, .2, 1);
    -o-transition: .2s cubic-bezier(.8, 0, .2, 1);
    -moz-transition: .2s cubic-bezier(.8, 0, .2, 1);
    transition: .2s cubic-bezier(.8, 0, .2, 1);
    pointer-events: none
}

@media (min-width:375px) {
    .st-navbar-circle {
        right: 40px
    }
}

@media (min-width:768px) {
    .st-navbar-circle {
        top: 46px;
        right: 30px
    }
}

.st-navbar-circle.-full {
    -webkit-transform: scale(25);
    -moz-transform: scale(25);
    -ms-transform: scale(25);
    -o-transform: scale(25);
    transform: scale(25)
}

.st-navbar-toggle {
    display: block;
    position: absolute;
    top: 32px;
    right: 30px;
    width: 23px;
    height: 23px;
    z-index: 1002;
    padding: 0;
    border: none;
    background: 0 0;
    -webkit-appearance: button;
    -webkit-transition: top .2s ease-out;
    -o-transition: top .2s ease-out;
    -moz-transition: top .2s ease-out;
    transition: top .2s ease-out
}

@media (min-width:375px) {
    .st-navbar-toggle {
        right: 40px
    }
}

@media (min-width:768px) {
    .st-navbar-toggle {
        top: 46px;
        right: 30px
    }
}

@media (min-width:1024px) {
    .st-navbar-toggle {
        display: none
    }
}

.st-navbar-toggle-item {
    display: block;
    width: 100%;
    height: 3px;
    margin: 0 0 5px 0;
    background: #fff;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -webkit-transition: .4s cubic-bezier(.175, .885, .32, 1.275);
    -o-transition: .4s cubic-bezier(.175, .885, .32, 1.275);
    -moz-transition: .4s cubic-bezier(.175, .885, .32, 1.275);
    transition: .4s cubic-bezier(.175, .885, .32, 1.275)
}

.st-navbar-toggle.-active {
    position: fixed
}

.st-navbar-toggle.-active .st-navbar-toggle-item {
    background: #0097ff
}

.st-navbar-toggle.-active .st-navbar-toggle-item.-top {
    -webkit-transform: translateY(6px) translateY(-50%) rotate(-135deg);
    -moz-transform: translateY(6px) translateY(-50%) rotate(-135deg);
    -ms-transform: translateY(6px) translateY(-50%) rotate(-135deg);
    -o-transform: translateY(6px) translateY(-50%) rotate(-135deg);
    transform: translateY(6px) translateY(-50%) rotate(-135deg)
}

.st-navbar-toggle.-active .st-navbar-toggle-item.-bottom {
    -webkit-transform: translateY(-5px) translateY(50%) rotate(135deg);
    -moz-transform: translateY(-5px) translateY(50%) rotate(135deg);
    -ms-transform: translateY(-5px) translateY(50%) rotate(135deg);
    -o-transform: translateY(-5px) translateY(50%) rotate(135deg);
    transform: translateY(-5px) translateY(50%) rotate(135deg)
}

.st-navbar-fill {
    opacity: 0;
    position: fixed;
    top: inherit;
    right: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100vh;
    z-index: 99;
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
    background: #fff;
    overflow: hidden;
    pointer-events: none;
    -webkit-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    will-change: transform
}

.st-navbar-fill.-visible {
    position: -webkit-sticky;
    position: sticky;
    opacity: 1;
    visibility: visible;
    pointer-events: none
}

.st-navbar.-open.-down .st-navbar-logo {
    position: fixed;
    top: 18px
}

@media (min-width:768px) {
    .st-navbar.-open.-down .st-navbar-logo {
        top: 26px
    }
}

.st-navbar.-open.-down .st-navbar-action {
    height: auto;
    position: fixed;
    z-index: 9999
}

@media (min-width:768px) {
    .st-navbar.-open.-down .st-navbar-action {
        top: 22px;
        right: 57px
    }
}

.st-navbar.-open .st-navbar-logo {
    position: fixed;
    top: 28px
}

@media (min-width:768px) {
    .st-navbar.-open .st-navbar-logo {
        top: 38px
    }
}

.st-navbar.-open .st-navbar-action {
    height: auto;
    position: fixed;
    z-index: 9999
}

@media (min-width:768px) {
    .st-navbar.-open .st-navbar-action {
        top: 35px;
        right: 57px
    }
}

.st-navbar.-inverse .st-navbar-logo img:first-child {
    display: none
}

.st-navbar.-inverse .st-navbar-logo img:last-child {
    display: block
}

@media (min-width:1024px) {
    .st-navbar.-inverse .st-navbar-menu-item {
        color: #414d5b
    }
    .st-navbar.-inverse .st-navbar-menu-item:active,
    .st-navbar.-inverse .st-navbar-menu-item:focus,
    .st-navbar.-inverse .st-navbar-menu-item:hover {
        color: #414d5b
    }
}

.st-navbar.-inverse .st-navbar-menu-item span {
    position: relative
}

@media (min-width:1024px) {
    .st-navbar.-inverse .st-navbar-menu-item span:after {
        background: #0097ff
    }
}

.st-navbar.-inverse .st-navbar-toggle-item {
    background: #0097ff
}

.st-navbar.-down {
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out
}

.st-navbar.-down .st-navbar-bg {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out
}

.st-navbar.-down .st-navbar-toggle {
    top: 22px
}

@media (min-width:768px) {
    .st-navbar.-down .st-navbar-toggle {
        top: 33px
    }
}

.st-navbar.-down .st-navbar-wrap {
    padding: 18px 0 0 0;
    opacity: 1;
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out
}

@media (min-width:768px) {
    .st-navbar.-down .st-navbar-wrap {
        padding: 22px 0 0 0
    }
}

.st-navbar.-down .st-navbar-circle {
    top: 22px
}

@media (min-width:768px) {
    .st-navbar.-down .st-navbar-circle {
        top: 33px
    }
}

.st-navbar.-up {
    -webkit-transform: translateY(-60px);
    -moz-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    -o-transform: translateY(-60px);
    transform: translateY(-60px);
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out
}

@media (min-width:768px) {
    .st-navbar.-up {
        -webkit-transform: translateY(-84px);
        -moz-transform: translateY(-84px);
        -ms-transform: translateY(-84px);
        -o-transform: translateY(-84px);
        transform: translateY(-84px)
    }
}

.st-navbar.-up .st-navbar-bg {
    -webkit-transition: 1s ease-out;
    -o-transition: 1s ease-out;
    -moz-transition: 1s ease-out;
    transition: 1s ease-out
}

.st-navbar.-up .st-navbar-wrap {
    opacity: 0;
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out
}

.st-navbar.-notfixed {
    position: absolute
}

.st-navbar.-notfixed .st-navbar-logo img:first-child {
    display: none
}

.st-navbar.-notfixed .st-navbar-logo img:last-child {
    display: block
}

@media (min-width:768px) {
    .st-navbar.-notfixed .st-navbar-logo img:first-child {
        display: block
    }
    .st-navbar.-notfixed .st-navbar-logo img:last-child {
        display: none
    }
}

@-webkit-keyframes st-navbar-menu-item {
    from {
        opacity: 0;
        -webkit-transform: translateX(50px);
        transform: translateX(50px)
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-moz-keyframes st-navbar-menu-item {
    from {
        opacity: 0;
        -moz-transform: translateX(50px);
        transform: translateX(50px)
    }
    to {
        opacity: 1;
        -moz-transform: translateX(0);
        transform: translateX(0)
    }
}

@-o-keyframes st-navbar-menu-item {
    from {
        opacity: 0;
        -o-transform: translateX(50px);
        transform: translateX(50px)
    }
    to {
        opacity: 1;
        -o-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes st-navbar-menu-item {
    from {
        opacity: 0;
        -webkit-transform: translateX(50px);
        -moz-transform: translateX(50px);
        -o-transform: translateX(50px);
        transform: translateX(50px)
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }
}

.st-loader {
    display: inline
}

.st-loader_overlay {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    opacity: 1;
    overflow: hidden;
    visibility: hidden;
    -webkit-transition: all .1s cubic-bezier(.175, .885, .32, 1.275) .7s;
    -o-transition: all .1s cubic-bezier(.175, .885, .32, 1.275) .7s;
    -moz-transition: all .1s cubic-bezier(.175, .885, .32, 1.275) .7s;
    transition: all .1s cubic-bezier(.175, .885, .32, 1.275) .7s
}

.st-loader_overlay.-visible {
    opacity: 1;
    visibility: visible
}

.st-loader_overlay-fill {
    height: 100vh;
    background: #0097ff;
}

.st-loader_overlay-wrap {
    margin: -5px 0 0 0;
}

.st-loader_overlay-bg {
    height: 200vh;
}

@media (min-width:1024px) {
    .st-form-grid {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.st-form-grid.-between {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width:768px) {
    .st-form-grid.-full {
        margin: 0 -22px
    }
}

@media (min-width:1024px) {
    .st-form-grid.-full {
        margin: 0 -30px
    }
}

@media (min-width:1440px) {
    .st-form-grid.-full {
        margin: 0 -20px
    }
}

@media (min-width:768px) {
    .st-form-grid.-full .st-form-grid-col {
        width: 50%;
        margin: 0 22px
    }
}

@media (min-width:1024px) {
    .st-form-grid.-full .st-form-grid-col {
        margin: 0 30px
    }
}

@media (min-width:1440px) {
    .st-form-grid.-full .st-form-grid-col {
        margin: 0 20px
    }
}

.st-form-grid.-lg {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

@media (min-width:768px) {
    .st-form-grid.-lg {
        margin: 0 -22px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex
    }
}

@media (min-width:1024px) {
    .st-form-grid.-lg {
        margin: 0 -13px
    }
}

@media (min-width:1280px) {
    .st-form-grid.-lg {
        margin: 0 -25px
    }
}

@media (min-width:1440px) {
    .st-form-grid.-lg {
        margin: 0 -20px
    }
}

@media (min-width:768px) {
    .st-form-grid.-lg .st-form-grid-col {
        width: 50%;
        margin: 0 22px
    }
}

@media (min-width:1024px) {
    .st-form-grid.-lg .st-form-grid-col {
        margin: 0 13px
    }
}

@media (min-width:1280px) {
    .st-form-grid.-lg .st-form-grid-col {
        margin: 0 25px
    }
}

@media (min-width:1440px) {
    .st-form-grid.-lg .st-form-grid-col {
        margin: 0 20px
    }
}

.st-form-group {
    position: relative;
    margin: 0 0 36px 0
}

@media (min-width:768px) {
    .st-form-group {
        margin: 0 0 25px 0
    }
    .st-form{
        margin-top: -20px;
    }
}

.st-form-group.-sm {
    margin: 0 0 30px 0
}

.st-form-group.-xs {
    margin: 0
}

.st-form-submit {
    margin: 35px 0 0 0
}

@media (min-width:768px) {
    .st-form-submit {
        margin: 40px 0 0 0
    }
}

@media (min-width:768px) {
    .st-form-submit-grid {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.st-form-submit-grid-col.-right {
    text-align: center
}

.st-form-submit-grid.-full {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.st-form-submit-item {
    display: inline-block;
    vertical-align: middle
}

.st-form-submit-brief {
    max-width: 265px;
    padding: 15px 0 0 0;
    font-size: 12px;
    letter-spacing: .01px;
    line-height: 22px;
    color: rgba(65, 77, 91, .6)
}

@media (min-width:768px) {
    .st-form-submit-brief {
        padding: 0 0 0 32px
    }
}

@media (min-width:1024px) {
    .st-form-submit-brief {
        padding: 0 0 0 42px
    }
}

@media (min-width:1280px) {
    .st-form-submit-brief {
        padding: 0 0 0 22px
    }
}

@media (min-width:1440px) {
    .st-form-submit-brief {
        padding: 0 0 0 33px
    }
}

.st-form-submit-brief a {
    position: relative
}

.st-form-submit-brief a:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background: rgba(44, 44, 44, .2)
}

.st-form-submit-brief a:focus,
.st-form-submit-brief a:hover {
    color: rgba(65, 77, 91, .8);
    text-decoration: none
}

.st-form-submit-brief.-xs {
    padding: 0 0 25px 0
}

@media (min-width:768px) {
    .st-form-submit-brief.-xs {
        padding: 0
    }
}

.st-form-submit.-sm {
    margin: 30px 0 0 0
}

.st-form-brief {
    max-width: 265px;
    padding: 16px 0 0 0;
    font-size: 12px;
    letter-spacing: .01px;
    line-height: 22px;
    color: rgba(65, 77, 91, .6)
}

@media (min-width:1024px) {
    .st-form-brief {
        padding: 0 0 0 20px
    }
}

@media (min-width:1280px) {
    .st-form-brief {
        padding: 0 0 0 44px
    }
}

@media (min-width:1440px) {
    .st-form-brief {
        padding: 0 0 0 32px
    }
}

.st-form-brief a {
    position: relative
}

.st-form-brief a:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background: rgba(44, 44, 44, .2)
}

.st-form-brief a:focus,
.st-form-brief a:hover {
    color: rgba(65, 77, 91, .8);
    text-decoration: none
}

.st-form-brief br {
    display: none
}

@media (min-width:1024px) {
    .st-form-brief br {
        display: block
    }
}

.st-form-link {
    font-size: 14px;
    letter-spacing: .1px;
    line-height: 19px
}

.st-form-link a:focus,
.st-form-link a:hover {
    opacity: .8;
    text-decoration: none
}

.st-form-error {
    position: absolute;
    bottom: -27px;
    font-size: 12px;
    letter-spacing: .01px;
    line-height: 22px;
    color: #f58d9a
}

@media (min-width:768px) {
    .st-form-error {
        bottom: -35px
    }

}
.st-submit-btn {
    width: 255px;
    height: 40px;
    background: 0 0;
    border: none;
    color: #fff !important;
}
@media (min-width:375px) {
    .st-submit-btn {
        width: 300px!important;
    }
}

.st-submit{
    border-radius: 30px;
    background: -webkit-linear-gradient(45deg, #0097ff 0, #5163de 100%);
}

.st-submit-text{
    color: white;
}

.st-input {
    display: inline
}
.st-input-success{
    color: #ffffff;
    margin: -12px 0 -10px 0;
}
.contact-form-success{
    color: darkgray!important;
    margin: -40px 0 -10px 0!important;
}
.career-form-success{
    color: darkgray!important;
}
.st-call-form-input_margin{
    margin-bottom: -35px;
}

.call-form-success{
    margin: -10px 0 15px 0;
    font-size: 14px;
    letter-spacing: -.13px;
    line-height: 16px;
    color: darkgray;

}
.st-call-form-submit{
    width: 136px!important;
    height: 35px!important;
    line-height: 35px!important;
    font-size: 12px!important;
}

.st-input_footer,
.st-input_light {
    position: relative;
    color: #414d5b
}

.st-input_footer input,
.st-input_light input {
    margin: 0;
    padding: 7px 0 9px 0;
    width: 100%;
    background: 0 0;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    outline: 0;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: .22px;
    color: #fff;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s
}

.st-input_footer input::-moz-placeholder,
.st-input_light input::-moz-placeholder {
    color: rgba(65, 77, 91, .4);
    opacity: 1
}

.st-input_footer input:-ms-input-placeholder,
.st-input_light input:-ms-input-placeholder {
    color: rgba(65, 77, 91, .4)
}

.st-input_footer input::-webkit-input-placeholder,
.st-input_light input::-webkit-input-placeholder {
    color: rgba(65, 77, 91, .4)
}

.st-input_footer input:hover,
.st-input_light input:hover {
    border-bottom: 1px solid #dedede
}

.st-input_footer input:focus,
.st-input_light input:focus {
    border-bottom: 1px solid #c5c5c5
}

.st-input_footer input:invalid,
.st-input_light input:invalid {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.st-input_footer input:disabled,
.st-input_light input:disabled {
    pointer-events: none
}

@media (min-width:768px) {
    .st-input_footer input,
    .st-input_light input {
        padding: 10px 0 20px 0;
        font-size: 16px;
        line-height: 22px
    }
}

.st-input_light-label {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 14px;
    letter-spacing: .02px;
    line-height: 19px;
    color: rgba(255, 255, 255, .5);
    pointer-events: none;
    -webkit-transition: .2s;
    -o-transition: .2s;
    -moz-transition: .2s;
    transition: .2s
}

@media (min-width:768px) {
    .st-input_light-label {
        font-size: 16px;
        line-height: 22px
    }
}

.st-input_light-addon {
    display: inline-block;
    position: absolute;
    bottom: 2px;
    right: 0;
    font-size: 14px;
    letter-spacing: .02px;
    line-height: 19px;
    color: rgba(65, 77, 91, .4)
}

@media (min-width:768px) {
    .st-input_light-addon {
        font-size: 16px;
        line-height: 22px
    }
}

.st-input_light-error {
    position: absolute;
    top: -17px;
    right: 0;
    font-size: 12px;
    letter-spacing: .01px;
    line-height: 22px;
    color: #f58d9a
}

@media (min-width:768px) {
    .st-input_light-error {
        top: -27px
    }
}

.-inverse.st-input_footer input,
.st-input_light.-inverse input {
    padding: 7px 0 9px 0;
    border-bottom: 1px solid rgba(222, 222, 222, .6);
    color: #414d5b
}

.-inverse.st-input_footer input:hover,
.st-input_light.-inverse input:hover {
    border-bottom: 1px solid #dedede
}

.-inverse.st-input_footer input:focus,
.st-input_light.-inverse input:focus {
    border-bottom: 1px solid #c5c5c5
}

@media (min-width:768px) {
    .-inverse.st-input_footer input,
    .st-input_light.-inverse input {
        padding: 7px 0 14px 0
    }
}

.-inverse.st-input_footer .st-input_light-label,
.st-input_light.-inverse .st-input_light-label {
    color: rgba(65, 77, 91, .4)
}

.-top.st-input_footer .st-input_light-label,
.st-input_light.-top .st-input_light-label {
    font-size: 12px;
    letter-spacing: .01px;
    line-height: 17px;
    -webkit-transform: translateY(-26px);
    -moz-transform: translateY(-26px);
    -ms-transform: translateY(-26px);
    -o-transform: translateY(-26px);
    transform: translateY(-26px)
}

@media (min-width:768px) {
    .-top.st-input_footer .st-input_light-label,
    .st-input_light.-top .st-input_light-label {
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px)
    }
}

.-error.st-input_footer input,
.st-input_light.-error input {
    color: #f58d9a;
    border-color: #f58d9a
}

.-error.st-input_footer input::-moz-placeholder,
.st-input_light.-error input::-moz-placeholder {
    color: #f58d9a;
    opacity: 1
}

.-error.st-input_footer input:-ms-input-placeholder,
.st-input_light.-error input:-ms-input-placeholder {
    color: #f58d9a
}

.-error.st-input_footer input::-webkit-input-placeholder,
.st-input_light.-error input::-webkit-input-placeholder {
    color: #f58d9a
}

.-error.st-input_footer .st-input_light-label,
.st-input_light.-error .st-input_light-label {
    color: #f58d9a
}

.-error.st-input_footer .st-input_light-addon,
.st-input_light.-error .st-input_light-addon {
    color: #f58d9a
}

.-disabled.st-input_footer,
.st-input_light.-disabled {
    pointer-events: none;
    opacity: .5
}

.st-input_footer {
    display: block;
    position: relative;
    overflow: hidden
}

.st-input_footer input {
    padding: 12px 0 14px 0;
    color: #414d5b;
    border-bottom: 1px solid rgba(76, 85, 166, .5)
}

.st-input_footer input::-moz-placeholder {
    color: rgba(65, 77, 91, .5);
    opacity: 1
}

.st-input_footer input:-ms-input-placeholder {
    color: rgba(65, 77, 91, .5)
}

.st-input_footer input::-webkit-input-placeholder {
    color: rgba(65, 77, 91, .5)
}

.st-input_footer input:hover {
    border-bottom: 1px solid rgba(76, 85, 166, .7)
}

.st-input_footer input:focus {
    border-bottom: 1px solid rgba(76, 85, 166, .8)
}

.st-input_footer-error {
    margin: 5px 0 0 0;
    font-size: 14px;
    letter-spacing: -.13px;
    line-height: 16px;
    color: #f58d9a
}

.st-input_footer-addon {
    position: absolute;
    right: -10px;
    top: 6px;
    opacity: 0;
    -webkit-transition: .1s;
    -o-transition: .1s;
    -moz-transition: .1s;
    transition: .1s
}

.st-input_footer-addon.-visible {
    right: 1px;
    opacity: 1
}

.st-input_footer.-error input {
    color: #414d5b;
    border-color: #f58d9a
}

.st-input_footer.-error input::-moz-placeholder {
    color: #414d5b;
    opacity: 1
}

.st-input_footer.-error input:-ms-input-placeholder {
    color: #414d5b
}

.st-input_footer.-error input::-webkit-input-placeholder {
    color: #414d5b
}

.st-textarea {
    position: relative
}

.st-textarea_light textarea {
    margin: 0;
    padding: 7px 7px 9px 7px;
    width: 100%;
    height: 76px;
    background: 0 0;
    border: 1px solid rgba(222, 222, 222, .6);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    letter-spacing: .02px;
    line-height: 19px;
    color: #414d5b;
    resize: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s
}

.st-textarea_light textarea::-moz-placeholder {
    color: rgba(65, 77, 91, .4);
    opacity: 1
}

.st-textarea_light textarea:-ms-input-placeholder {
    color: rgba(65, 77, 91, .4)
}

.st-textarea_light textarea::-webkit-input-placeholder {
    color: rgba(65, 77, 91, .4)
}

.st-textarea_light textarea:hover {
    border: 1px solid #dedede
}

.st-textarea_light textarea:focus {
    border: 1px solid #c5c5c5
}

.st-textarea_light textarea:invalid {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.st-textarea_light textarea:disabled {
    pointer-events: none
}

@media (min-width:768px) {
    .st-textarea_light textarea {
        padding: 10px 10px 20px 10px;
        font-size: 16px;
        line-height: 22px
    }
}

.st-textarea_light-label {
    margin: 0 0 12px 0;
    font-size: 12px;
    letter-spacing: .01px;
    line-height: 17px;
    color: rgba(65, 77, 91, .4);
    pointer-events: none;
    -webkit-transition: .2s;
    -o-transition: .2s;
    -moz-transition: .2s;
    transition: .2s
}

.st-btn_main {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 15px 0 15px 25px;
    width: 134px;
    background: #0097ff;
    border: none;
    outline: 0;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    letter-spacing: .12px;
    text-align: left;
    color: #fff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

@media (min-width:768px) {
    .st-btn_main {
        width: 148px
    }
}

.st-btn_main:hover .st-btn_main-icon {
    -webkit-transform: translateX(3px);
    -moz-transform: translateX(3px);
    -ms-transform: translateX(3px);
    -o-transform: translateX(3px);
    transform: translateX(3px)
}

.st-btn_main:hover {
    -webkit-box-shadow: -2px 2px 14px rgba(76, 85, 166, .35);
    -moz-box-shadow: -2px 2px 14px rgba(76, 85, 166, .35);
    box-shadow: -2px 2px 14px rgba(76, 85, 166, .35)
}

.st-btn_main-icon {
    position: absolute;
    right: 22px;
    margin: -2px 0 0 0;
    font-size: 18px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    -moz-transition: .2s;
    transition: .2s
}

.st-btn_main.-disabled {
    pointer-events: none;
    background: rgba(65, 77, 91, .2)
}

.st-btn_box {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 19px 0 19px 25px;
    width: 216px;
    background: #fff;
    border: none;
    outline: 0;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    letter-spacing: -.28px;
    font-weight: 600;
    text-align: left;
    color: #0097ff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

@media (min-width:768px) {
    .st-btn_box {
        width: 208px
    }
}

.st-btn_box:focus,
.st-btn_box:hover {
    color: #0097ff;
    text-decoration: none
}

.st-btn_box:hover .st-btn_box-icon span svg:nth-child(1) {
    -webkit-transform: translate3d(200%, 0, 0) rotateY(90deg);
    -moz-transform: translate3d(200%, 0, 0) rotateY(90deg);
    transform: translate3d(200%, 0, 0) rotateY(90deg);
    opacity: 0
}

.st-btn_box:hover .st-btn_box-icon span svg:nth-child(2) {
    -webkit-transform: translate3d(0, 0, 0) rotateY(0);
    -moz-transform: translate3d(0, 0, 0) rotateY(0);
    transform: translate3d(0, 0, 0) rotateY(0);
    opacity: 1
}

.st-btn_box-fill {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #fff;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    -webkit-transition: .25s cubic-bezier(.25, .25, .5, 1.9);
    -o-transition: .25s cubic-bezier(.25, .25, .5, 1.9);
    -moz-transition: .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: .25s cubic-bezier(.25, .25, .5, 1.9)
}

.st-btn_box-icon {
    position: absolute;
    width: 52px;
    height: 52px;
    top: 4px;
    right: 5px;
    background: rgba(76, 85, 166, .1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 20px;
    line-height: 52px;
    text-align: center;
    overflow: hidden;
    -webkit-transition: .25s;
    -o-transition: .25s;
    -moz-transition: .25s;
    transition: .25s
}

.st-btn_box-icon span {
    position: relative
}

.st-btn_box-icon span svg {
    margin: -2px 0 0 0;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.st-btn_box-icon span svg:nth-child(1) {
    -webkit-transform: translate3d(0, 0, 0) rotateY(0);
    -moz-transform: translate3d(0, 0, 0) rotateY(0);
    transform: translate3d(0, 0, 0) rotateY(0);
    opacity: 1;
    -webkit-perspective: 500px;
    -moz-perspective: 500px;
    perspective: 500px
}

.st-btn_box-icon span svg:nth-child(2) {
    position: absolute;
    left: 0;
    top: 7px;
    -webkit-transform: translate3d(-200%, 0, 0) rotateY(-90deg);
    -moz-transform: translate3d(-200%, 0, 0) rotateY(-90deg);
    transform: translate3d(-200%, 0, 0) rotateY(-90deg);
    opacity: 0
}

.st-btn_box.-xs {
    width: 136px;
    padding: 19px 0;
    text-align: center
}

.st-btn_box.-xs:hover .st-btn_box-fill {
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
    -o-transform: scale(1.08);
    transform: scale(1.08)
}

.st-btn_box.-lg {
    padding: 19px 0 19px 34px
}

.st-lh{
    line-height: 50px!important;
}

.st-btn_nav {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    // width: 100px;
    // height: 40px;
    background: 0 0;
    border: none;
    outline: 0;
    letter-spacing: .02px;
    line-height: 38px;
    text-align: center;
    color: #fff;
    -webkit-transition: .3s, -webkit-transform .2s;
    transition: .3s, -webkit-transform .2s;
    -o-transition: .3s, -o-transform .2s;
    -moz-transition: .3s, transform .2s, -moz-transform .2s;
    transition: .3s, transform .2s;
    transition: .3s, transform .2s, -webkit-transform .2s, -moz-transform .2s, -o-transform .2s;
    padding: 4px 24px;
}

.st-btn_nav:hover .st-btn_nav-fill {
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
    -o-transform: scale(1.08);
    transform: scale(1.08)
}

.st-btn_nav:focus,
.st-btn_nav:hover {
    color: #fff;
    text-decoration: none
}

.st-btn_nav-fill {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: 0 0;
    border: 1px solid #fff;
    outline: 0;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    -webkit-transition: .25s cubic-bezier(.25, .25, .5, 1.9);
    -o-transition: .25s cubic-bezier(.25, .25, .5, 1.9);
    -moz-transition: .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: .25s cubic-bezier(.25, .25, .5, 1.9)
}

.st-btn_nav.-inverse {
    color: #414d5b
}

.st-btn_nav.-inverse:focus,
.st-btn_nav.-inverse:hover {
    color: #414d5b;
    text-decoration: none
}

.st-btn_nav.-inverse .st-btn_nav-fill {
    border: 1px solid #dedede
}

.st-btn_nav.-painted {
    color: #3d426f
}

.st-btn_nav.-painted:focus,
.st-btn_nav.-painted:hover {
    color: #3d426f;
    text-decoration: none
}

.st-btn_nav.-painted .st-btn_nav-fill {
    background: #fff
}

.st-btn_nav.-painted.-inverse {
    color: #fff;
    z-index: 1
}

.st-btn_nav.-painted.-inverse:focus,
.st-btn_nav.-painted.-inverse:hover {
    color: #fff;
    text-decoration: none
}

.st-btn_nav.-painted.-inverse .st-btn_nav-fill {
    background: #0097ff;
    border: none
}

.st-btn_nav.-lg {
    width: 114px
}

.st-btn_nav.-md {
    width: 153px;
    height: 54px;
    line-height: 54px
}

.st-btn_nav.-xl {
    width: 172px;
    height: 52px
}

.st-btn_nav.-xxl {
    width: 204px;
    height: 54px
}

.st-btn_nav.-transparent {
    background: 0 0;
    border: none;
    color: #414d5b;
    -webkit-transition: color .2s;
    -o-transition: color .2s;
    -moz-transition: color .2s;
    transition: color .2s
}

.st-btn_nav.-transparent:hover {
    color: rgba(65, 77, 91, .7)
}

.st-btn_nav.-bold {
    font-weight: 600
}

.st-btn_nav.-small {
    width: 80px;
    height: 32px;
    font-size: 14px;
    line-height: 31px
}

.st-btn_round {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
    background: rgba(65, 77, 91, .05);
    border: none;
    outline: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    line-height: 1;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

.st-btn_round:focus,
.st-btn_round:hover {
    background: rgba(65, 77, 91, .08)
}

.st-btn_round svg {
    font-size: 15px;
    color: #0097ff
}
.st-btn_nav.-md_plus {
    width: 170px;
    height: 54px;
    line-height: 54px;
}

.st-btn_simple {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    width: 184px;
    height: 54px;
    background: rgba(76, 85, 166, .05);
    border: none;
    outline: 0;
    -webkit-border-radius: 27px;
    -moz-border-radius: 27px;
    border-radius: 27px;
    letter-spacing: .1px;
    line-height: 54px;
    text-align: center;
    font-weight: 600;
    color: #0097ff;
    -webkit-transition: .2s;
    -o-transition: .2s;
    -moz-transition: .2s;
    transition: .2s
}

.st-btn_simple:focus,
.st-btn_simple:hover {
    color: #fff;
    background: #0097ff;
    text-decoration: none
}

.st-btn_simple.-full {
    width: 100%
}

.st-btn_action {
    position: relative;
    width: 33px;
    height: 33px;
    padding: 0;
    margin: 0;
    z-index: 3;
    line-height: 1;
    color: #fff;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: 0 0;
    border: none
}

.st-btn_action:hover .st-btn_action-fill {
    -webkit-transform: scale(.95);
    -moz-transform: scale(.95);
    -ms-transform: scale(.95);
    -o-transform: scale(.95);
    transform: scale(.95)
}

.st-btn_action-fill {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #0097ff;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    -o-transition: -o-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    -moz-transition: transform .25s cubic-bezier(.25, .25, .5, 1.9), -moz-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: transform .25s cubic-bezier(.25, .25, .5, 1.9), -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9), -moz-transform .25s cubic-bezier(.25, .25, .5, 1.9), -o-transform .25s cubic-bezier(.25, .25, .5, 1.9)
}

.st-btn_action-icon {
    display: block;
    position: relative;
    z-index: 2;
    font-size: 12px
}

.st-btn_action.-disabled {
    opacity: .4;
    color: #414d5b;
    pointer-events: none
}

.st-btn_action.-disabled .st-btn_action:hover .st-btn_action.-disabled .st-btn_action-fill {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.st-btn_action.-disabled .st-btn_action-fill {
    background: #f1f3f4;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.st-checkbox {
    display: inline
}

.st-radio {
    display: inline
}

.st-dropdown {
    display: inline;
    position: relative
}

.st-paginator {
    display: inline
}

.st-modal {
    display: inline
}

.st-modal_full {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: inherit;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 110;
    overflow: hidden;
    overflow-y: auto;
    padding: 0;
    -webkit-overflow-scrolling: touch
}

.st-modal_full-backdrop {
    position: fixed;
    top: inherit;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background: #fbfcfc
}

.st-modal_full-container {
    width: 100%;
    height: 100%
}

@media (min-width:768px) {
    .st-modal_full-container {
        padding: 0 56px
    }
}

@media (min-width:1280px) {
    .st-modal_full-container {
        padding: 0 135px
    }
}

@media (min-width:1440px) {
    .st-modal_full-container {
        width: 1100px;
        margin: 0 auto;
        padding: 0
    }
}

.st-modal_full-dialog {
    position: relative;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    z-index: 1;
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    background: #fff;
    pointer-events: auto;
    opacity: 0;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, -webkit-transform .3s;
    -o-transition: opacity .3s, -o-transform .3s;
    -moz-transition: opacity .3s, transform .3s, -moz-transform .3s;
    transition: opacity .3s, transform .3s;
    transition: opacity .3s, transform .3s, -webkit-transform .3s, -moz-transform .3s, -o-transform .3s
}

.st-modal_full-dialog-wrap {
    width: 100%;
    height: 100%;
    padding: 34px 0 40px 0
}

@media (min-width:768px) {
    .st-modal_full-dialog-wrap {
        padding: 40px 0 110px 0
    }
}

.st-modal_full-dialog-container {
    padding: 0 19px
}

@media (min-width:375px) {
    .st-modal_full-dialog-container {
        padding: 0 35px
    }
}

@media (min-width:768px) {
    .st-modal_full-dialog-container {
        padding: 0 73px
    }
}

@media (min-width:1024px) {
    .st-modal_full-dialog-container {
        padding: 0 170px 0 62px
    }
}

@media (min-width:1280px) {
    .st-modal_full-dialog-container {
        padding: 0 195px 0 45px
    }
}

@media (min-width:1440px) {
    .st-modal_full-dialog-container {
        padding: 0 76px 0 60px
    }
}

.st-modal_full-dialog-name {
    margin: 0 0 56px 0;
    max-width: 127px;
    font-size: 16px;
    letter-spacing: -.27px;
    line-height: 24px;
    font-weight: 700
}

@media (min-width:768px) {
    .st-modal_full-dialog-name {
        margin: 0 0 46px 0;
        max-width: 100%
    }
}

@media (min-width:1024px) {
    .st-modal_full-dialog-name {
        margin: 0 0 32px 0;
        font-size: 18px;
        letter-spacing: -.3px;
        line-height: 32px
    }
}

.st-modal_full-dialog-title {
    margin: 0 0 20px 0;
    font-size: 24px;
    letter-spacing: -.29px;
    line-height: 34px
}

@media (min-width:375px) {
    .st-modal_full-dialog-title {
        margin: 0 0 36px 0
    }
}

@media (min-width:1024px) {
    .st-modal_full-dialog-title {
        font-size: 32px;
        letter-spacing: -.38px;
        line-height: 52px
    }
}

@media (min-width:1280px) {
    .st-modal_full-dialog-title {
        margin: 0 0 24px 0
    }
}

.st-modal_full-dialog-description {
    margin: 0 0 30px 0;
    font-size: 16px;
    letter-spacing: .27px;
    line-height: 30px
}

@media (min-width:768px) {
    .st-modal_full-dialog-description {
        margin: 0 0 46px 0;
        font-size: 18px;
        letter-spacing: .3px;
        line-height: 34px
    }
}

.st-modal_full-dialog-subtitle {
    margin: 0 0 15px 0;
    font-size: 18px;
    letter-spacing: -.3px;
    line-height: 28px;
    font-weight: 700
}

@media (min-width:1024px) {
    .st-modal_full-dialog-subtitle {
        margin: 0 0 24px 0
    }
}

.st-modal_full-dialog-list li {
    padding: 0;
    margin: 0;
    font-size: 14px;
    letter-spacing: .23px;
    line-height: 30px
}

@media (min-width:768px) {
    .st-modal_full-dialog-list li {
        font-size: 16px;
        letter-spacing: .27px;
        line-height: 30px
    }
}

.st-modal_full-dialog-text {
    font-size: 14px;
    letter-spacing: .23px;
    line-height: 30px
}

@media (min-width:768px) {
    .st-modal_full-dialog-text {
        font-size: 16px;
        letter-spacing: .27px;
        line-height: 30px
    }
}

.st-modal_full-dialog-row {
    margin: 0 0 30px 0
}

@media (min-width:768px) {
    .st-modal_full-dialog-row {
        margin: 0 0 50px 0
    }
}

@media (min-width:1024px) {
    .st-modal_full-dialog-row {
        margin: 0 0 37px 0
    }
}

@media (min-width:1440px) {
    .st-modal_full-dialog-row {
        max-width: 800px
    }
}

@media (min-width:375px) {
    .st-modal_full-dialog-row.-lg {
        margin: 0 0 70px 0
    }
}

@media (min-width:1024px) {
    .st-modal_full-dialog-form {
        max-width: 580px
    }
}

@media (min-width:1280px) {
    .st-modal_full-dialog-form {
        max-width: 642px
    }
}

.st-modal_full-dialog-form-title {
    margin: 0 0 20px 0;
    font-size: 26px;
    letter-spacing: -.31px;
    line-height: 40px;
    font-weight: 700
}

@media (min-width:375px) {
    .st-modal_full-dialog-form-title {
        margin: 0 0 55px 0;
    }
}

.st-modal_full-close {
    position: absolute;
    pointer-events: auto;
    z-index: 200;
    top: 40px;
    right: 40px;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s
}

@media (min-width:375px) {
    .st-modal_full-close {
        top: 40px;
        right: 40px
    }
}

@media (min-width:768px) {
    .st-modal_full-close {
        top: 40px;
        right: 70px
    }
}

@media (min-width:1024px) {
    .st-modal_full-close {
        top: 40px;
        right: 60px
    }
}

@media (min-width:1280px) {
    .st-modal_full-close {
        top: 40px;
        right: 45px
    }
}

@media (min-width:1440px) {
    .st-modal_full-close {
        top: 45px;
        right: 40px
    }
}

.st-modal_full-close a {
    display: block;
    height: 100%;
    width: 100%
}

.st-modal_full.in {
    display: block
}

.st-modal_full.-visible .st-modal_full-dialog,
.st-modal_full.in .st-modal_full-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.st-header {
    display: inline
}

.st-table {
    position: relative;
    width: 100%
}

.st-table-decore {
    position: absolute;
    top: -15px;
    bottom: 0;
    right: 0;
    left: 0;
    -webkit-border-radius: 20px 20px 0 0;
    -moz-border-radius: 20px 20px 0 0;
    border-radius: 20px 20px 0 0;
    background-color: #fff;
    -webkit-box-shadow: 0 -3px 24px 0 rgba(174, 189, 205, .2);
    -moz-box-shadow: 0 -3px 24px 0 rgba(174, 189, 205, .2);
    box-shadow: 0 -3px 24px 0 rgba(174, 189, 205, .2);
    z-index: -1;
}

.st-table-decore.-bottom {
    top: 0;
    bottom: -22px;
    -webkit-box-shadow: 0 7px 4px 0 rgba(174, 189, 205, .2);
    -moz-box-shadow: 0 7px 4px 0 rgba(174, 189, 205, .2);
    box-shadow: 0 7px 4px 0 rgba(174, 189, 205, .2);
    -webkit-border-radius: 0 0 20px 20px;
    -moz-border-radius: 0 0 20px 20px;
    border-radius: 0 0 20px 20px;
}

.st-table thead td:first-of-type {
    vertical-align: top
}

.st-table thead td:last-of-type {
    position: relative;
    z-index: 10;
    -webkit-border-radius: 25px 25px 0 0;
    -moz-border-radius: 25px 25px 0 0;
    border-radius: 25px 25px 0 0
}

.st-table thead td:last-of-type:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 7px;
    height: 95%;
    -webkit-box-shadow: -7px 0 16px 0 rgba(174, 189, 205, .2);
    -moz-box-shadow: -7px 0 16px 0 rgba(174, 189, 205, .2);
    box-shadow: -7px 0 16px 0 rgba(174, 189, 205, .2)
}

.st-table thead td:last-of-type:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 7px;
    height: 95%;
    -webkit-box-shadow: 7px 0 16px 0 rgba(174, 189, 205, .2);
    -moz-box-shadow: 7px 0 16px 0 rgba(174, 189, 205, .2);
    box-shadow: 7px 0 16px 0 rgba(174, 189, 205, .2)
}

.st-table thead td:not(:first-of-type) {
    padding: 31px 15px 42px;
    background-color: #fff;
    border-bottom: 1px solid #e6e9eb
}

// @media (min-width:1280px) {
//     .st-table thead td:not(: first-of-type) {
//         padding: 31px 20px 42px
//     }
// }

// @media (min-width:1440px) {
//     .st-table thead td:not(: first-of-type) {
//         padding: 31px 40px 42px
//     }
// }

.st-table thead td:nth-of-type(2) {
    border-right: 1px solid #e6e9eb;
    -webkit-border-radius: 20px 0 0 0;
    -moz-border-radius: 20px 0 0 0;
    border-radius: 20px 0 0 0
}

.st-table tbody tr:not(:last-of-type) td:first-of-type {
    position: relative;
    background-color: #f8f8f8
}

.st-table tbody tr:last-of-type td:last-of-type {
    z-index: 10
}

.st-table tbody tr:last-of-type td:first-of-type {
    background-color: #fbfcfc
}

.st-table tbody tr:last-of-type td:not(:first-of-type) {
    padding: 25px 0 35px
}

.st-table tbody tr:last-of-type td:nth-of-type(2) {
    -webkit-border-radius: 0 0 0 20px;
    -moz-border-radius: 0 0 0 20px;
    border-radius: 0 0 0 20px
}

.st-table tbody tr:first-of-type td:first-of-type {
    padding: 18px 20px;
    -webkit-border-radius: 20px 0 0 0;
    -moz-border-radius: 20px 0 0 0;
    border-radius: 20px 0 0 0
}

.st-table tbody tr:not(:first-of-type) td:first-of-type {
    padding: 18px 20px 18px 32px
}

.st-table tbody tr:not(:first-of-type) td:first-of-type.-lg {
    padding: 38px 20px 38px 32px
}

.st-table tbody tr:not(:first-of-type) td:first-of-type.-pt {
    padding-top: 25px
}

.st-table tbody tr:not(:first-of-type) td:first-of-type.-pb {
    padding-bottom: 25px
}

.st-table tbody tr:not(:first-of-type) td:first-of-type.-pb-none {
    padding-bottom: 0
}

.st-table tbody tr:not(:first-of-type) td:first-of-type.-allotted {
    padding-left: 18px
}

.st-table tbody tr.-border td {
    border-bottom: 1px solid #e6e9eb
}

.st-table tbody tr td {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: .5px;
    background-color: #fff
}

.st-table tbody tr td:not(:first-of-type) {
    text-align: center
}

.st-table tbody tr td:last-of-type {
    position: relative
}

.st-table tbody tr td:last-of-type:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    height: 100%;
    -webkit-box-shadow: -7px 0 16px 0 rgba(174, 189, 205, .2);
    -moz-box-shadow: -7px 0 16px 0 rgba(174, 189, 205, .2);
    box-shadow: -7px 0 16px 0 rgba(174, 189, 205, .2)
}

.st-table tbody tr td:last-of-type:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 7px;
    height: 100%;
    -webkit-box-shadow: 7px 0 16px 0 rgba(174, 189, 205, .2);
    -moz-box-shadow: 7px 0 16px 0 rgba(174, 189, 205, .2);
    box-shadow: 7px 0 16px 0 rgba(174, 189, 205, .2)
}

.st-table tbody tr td:first-of-type {
    width: 290px
}

@media (min-width:1440px) {
    .st-table tbody tr td:first-of-type {
        width: 270px
    }
}

.st-table tbody tr td:nth-of-type(2) {
    border-right: 1px solid #e6e9eb
}

.st-table tbody tr td.-bold {
    font-weight: 600
}

.st-intro {
    position: relative;
    height: 950px;
    color: #fff
}

@media (min-width:768px) {
    .st-intro {
        height: 805px
    }
}

@media (min-width:1024px) {
    .st-intro {
        height: 875px
    }
}

@media (min-width:1280px) {
    .st-intro {
        height: 985px
    }
}

@media (min-width:1440px) {
    .st-intro {
        height: 1125px
    }
}

.st-intro-fill {
    position: absolute;
    top: -40px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1012px;
    z-index: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: 76% center;
    background-repeat: no-repeat
}

@media (min-width:768px) {
    .st-intro-fill {
        height: 843px;
        background-position: 85% center
    }
}

@media (min-width:1024px) {
    .st-intro-fill {
        height: 1125px;
        top: -250px;
        background-position: 85% center
    }
}

@media (min-width:1280px) {
    .st-intro-fill {
        top: -140px;
        background-position: 95% center
    }
}

@media (min-width:1440px) {
    .st-intro-fill {
        top: 0
    }
}

.st-intro-wrap {
    position: relative;
    padding: 80px 0;
    height: 100%
}

@media (min-width:375px) {
    .st-intro-wrap {
        padding: 95px 0 0 0
    }
}

@media (min-width:768px) {
    .st-intro-wrap {
        padding: 130px 0 0 0
    }
}

@media (min-width:1024px) {
    .st-intro-wrap {
        padding: 163px 0 0 0
    }
}

@media (min-width:1280px) {
    .st-intro-wrap {
        padding: 159px 0 0 0
    }
}

@media (min-width:1440px) {
    .st-intro-wrap {
        padding: 221px 0 0 0
    }
}

.st-intro-wrap.-xs {
    padding: 80px 0 0 0
}

@media (min-width:375px) {
    .st-intro-wrap.-xs {
        padding: 95px 0 10px 0
    }
}

@media (min-width:768px) {
    .st-intro-wrap.-xs {
        padding: 130px 0 75px 0
    }
}

@media (min-width:1024px) {
    .st-intro-wrap.-xs {
        padding: 170px 0 75px 0
    }
}

@media (min-width:1280px) {
    .st-intro-wrap.-xs {
        padding: 170px 0 120px 0
    }
}

.st-intro-wrap.-sm {
    padding: 105px 0 0 0
}

@media (min-width:375px) {
    .st-intro-wrap.-sm {
        padding: 110px 0 0 0
    }
}

@media (min-width:768px) {
    .st-intro-wrap.-sm {
        padding: 126px 0 0 0
    }
}

@media (min-width:1024px) {
    .st-intro-wrap.-sm {
        padding: 150px 0 0 0
    }
}

@media (min-width:1280px) {
    .st-intro-wrap.-sm {
        padding: 160px 0 0 0
    }
}

@media (min-width:1440px) {
    .st-intro-wrap.-sm {
        padding: 200px 0 0 0
    }
}

.st-intro-container {
    padding: 0 30px
}

@media (min-width:375px) {
    .st-intro-container {
        padding: 0 38px
    }
}

@media (min-width:768px) {
    .st-intro-container {
        padding: 0 0 0 55px
    }
}

@media (min-width:1280px) {
    .st-intro-container {
        padding: 0 0 0 82px
    }
}

@media (min-width:1440px) {
    .st-intro-container {
        padding: 0 0 0 121px
    }
}

.st-intro-container.-sm {
    margin: 0 auto;
    padding: 0 30px
}

@media (min-width:375px) {
    .st-intro-container.-sm {
        padding: 0 38px
    }
}

@media (min-width:768px) {
    .st-intro-container.-sm {
        max-width: 565px;
        padding: 0
    }
}

@media (min-width:1024px) {
    .st-intro-container.-sm {
        max-width: 578px
    }
}

@media (min-width:1280px) {
    .st-intro-container.-sm {
        max-width: 550px
    }
}

@media (min-width:1440px) {
    .st-intro-container.-sm {
        max-width: 737px
    }
}

.st-intro-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -moz-box-orient: vertical;
    -moz-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%
}

@media (min-width:768px) {
    .st-intro-grid {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

@media (min-width:768px) {
    .st-intro-grid-col {
        width: 50%
    }
}

@media (min-width:1024px) {
    .st-intro-grid-col {
        width: 56%
    }
}

@media (min-width:1280px) {
    .st-intro-grid-col {
        width: 52%
    }
}

@media (min-width:1440px) {
    .st-intro-grid-col {
        width: 50%
    }
}

.st-intro-grid-col.-right {
    -webkit-perspective: 400px;
    -moz-perspective: 400px;
    perspective: 400px
}

@media (min-width:1024px) {
    .st-intro-grid-col.-right {
        width: 44%
    }
}

@media (min-width:1280px) {
    .st-intro-grid-col.-right {
        width: 48%
    }
}

@media (min-width:1440px) {
    .st-intro-grid-col.-right {
        width: 50%
    }
}

@media (min-width:1024px) {
    .st-intro-grid.-top {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

.st-intro-grid.-center {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width:768px) {
    .st-intro-body {
        max-width: 267px
    }
}

@media (min-width:1024px) {
    .st-intro-body {
        max-width: 375px
    }
}

@media (min-width:1280px) {
    .st-intro-body {
        margin: 17px 0 0 0;
        max-width: 410px
    }
}

.st-intro-body-wrap {
    padding: 50px 0 0 0
}

@media (min-width:375px) {
    .st-intro-body-wrap {
        padding: 40px 0 0 0
    }
}

@media (min-width:1440px) {
    .st-intro-body-wrap {
        padding: 0
    }
}

.st-intro-body.-sm {
    margin: 0 auto
}

@media (min-width:375px) {
    .st-intro-body.-sm {
        max-width: 100%
    }
}

@media (min-width:768px) {
    .st-intro-body.-sm {
        max-width: 430px
    }
}

@media (min-width:1024px) {
    .st-intro-body.-sm {
        max-width: 410px
    }
}

@media (min-width:1280px) {
    .st-intro-body.-sm {
        max-width: 565px
    }
}

.st-intro-body.-lg {
    margin: 0;
    max-width: 265px
}

@media (min-width:375px) {
    .st-intro-body.-lg {
        max-width: 100%
    }
}

@media (min-width:768px) {
    .st-intro-body.-lg {
        max-width: 350px
    }
}

@media (min-width:1024px) {
    .st-intro-body.-lg {
        max-width: 385px
    }
}

@media (min-width:1280px) {
    .st-intro-body.-lg {
        max-width: 575px
    }
}

.st-intro-body.-xl {
    margin: 0 auto;
    max-width: 605px
}

.st-intro-body.-center {
    text-align: center
}

.st-intro-body.-full {
    margin: 0;
    max-width: 100%;
    text-align: center
}

.st-intro-subtitle {
    margin: 0 0 20px 0
}

.st-intro-subtitle h2 {
    margin: 0;
    font-size: 28px;
    letter-spacing: -.34px;
    line-height: 44px
}

@media (min-width:1280px) {
    .st-intro-subtitle h2 {
        font-size: 32px;
        letter-spacing: -.38px;
        line-height: 52px
    }
}

.st-intro-subtitle.-lg {
    margin: 0 0 45px 0
}


.st-intro-bg {
    width: 260px;
    height: 250px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0;
    -moz-transform-origin: 0;
    -ms-transform-origin: 0;
    -o-transform-origin: 0;
    transform-origin: 0;
    will-change: transform
}

@media (min-width:768px) {
    .st-intro-bg {
        width: 346px;
        height: 330px
    }
}

@media (min-width:1024px) {
    .st-intro-bg {
        width: 426px;
        height: 406px
    }
}

@media (min-width:1280px) {
    .st-intro-bg {
        width: 535px;
        height: 512px;
        margin: 0 0 0 20px
    }
}

@media (min-width:1440px) {
    .st-intro-bg {
        width: 572px;
        height: 548px;
        margin: 0 0 0 38px
    }
}

@media (min-width:1440px) {
    .st-intro-bg.-xs {
        width: 529px;
        height: 512px;
        margin: 0 0 0 50px
    }
}

.st-intro-action {
    width: 100%
}

.st-intro-action-item {
    margin: 0 0 23px 0
}

@media (min-width:768px) {
    .st-intro-action-item {
        display: inline-block;
        vertical-align: bottom;
        margin: 0 0 20px 16px
    }
    .st-intro-action-item:first-child {
        margin: 0
    }
}

.st-intro-action-item.-lg {
    max-width: 276px;
    width: 100%
}

@media (min-width:768px) {
    .st-intro-action-item.-lg {
        width: 276px;
        max-width: 100%
    }
}

@media (min-width:1280px) {
    .st-intro-action-item.-lg {
        width: 342px
    }
}

@media (min-width:1440px) {
    .st-intro-action-item.-lg {
        width: 360px
    }
}

@media (min-width:768px) {
    .st-intro-action.-sm {
        position: absolute
    }
}

@media (min-width:1024px) {
    .st-intro-action.-sm {
        position: relative
    }
}

.st-intro-pic {
    display: none;
    position: absolute
}

@media (min-width:768px) {
    .st-intro-pic {
        display: block;
        -webkit-transform: scale(.75);
        -moz-transform: scale(.75);
        -ms-transform: scale(.75);
        -o-transform: scale(.75);
        transform: scale(.75)
    }
}

@media (min-width:1280px) {
    .st-intro-pic {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.st-intro-figure {
    position: absolute;
    will-change: transform
}

.st-intro-figure:nth-child(1) {
    display: none;
    left: 288px;
    top: -105px
}

@media (min-width:1440px) {
    .st-intro-figure:nth-child(1) {
        display: block
    }
}

.st-intro-figure:nth-child(2) {
    top: 30px;
    right: 0
}

@media (min-width:768px) {
    .st-intro-figure:nth-child(2) {
        top: 85px;
        right: 35%
    }
}

@media (min-width:1024px) {
    .st-intro-figure:nth-child(2) {
        top: 355px;
        right: 170px
    }
}

@media (min-width:1280px) {
    .st-intro-figure:nth-child(2) {
        top: 235px;
        right: 180px
    }
}

@media (min-width:1440px) {
    .st-intro-figure:nth-child(2) {
        top: 104px;
        right: 230px
    }
}

.st-intro-figure:nth-child(3) {
    right: -160px;
    bottom: -35px;
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8)
}

@media (min-width:768px) {
    .st-intro-figure:nth-child(3) {
        right: auto;
        left: -110px;
        bottom: -75px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1024px) {
    .st-intro-figure:nth-child(3) {
        left: 0;
        bottom: -60px
    }
}

@media (min-width:1280px) {
    .st-intro-figure:nth-child(3) {
        left: 50px;
        bottom: -60px
    }
}

@media (min-width:1440px) {
    .st-intro-figure:nth-child(3) {
        left: 200px;
        bottom: -60px
    }
}

.st-intro-figure:nth-child(4) {
    display: none
}

@media (min-width:768px) {
    .st-intro-figure:nth-child(4) {
        bottom: -125px;
        right: 80px;
        display: block
    }
}

@media (min-width:1024px) {
    .st-intro-figure:nth-child(4) {
        bottom: -110px;
        right: 180px
    }
}

@media (min-width:1280px) {
    .st-intro-figure:nth-child(4) {
        bottom: -100px;
        right: 380px
    }
}

.st-intro-figure:nth-child(5) {
    right: -80px;
    top: 365px;
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8)
}

@media (min-width:768px) {
    .st-intro-figure:nth-child(5) {
        top: auto;
        right: -90px;
        bottom: 225px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1024px) {
    .st-intro-figure:nth-child(5) {
        right: -90px;
        bottom: 195px
    }
}

@media (min-width:1280px) {
    .st-intro-figure:nth-child(5) {
        right: -50px;
        bottom: 220px
    }
}

@media (min-width:1440px) {
    .st-intro-figure:nth-child(5) {
        right: -5px;
        bottom: 260px
    }
}

.st-intro-figure img {
    -webkit-transition: -webkit-transform 1.5s ease-out;
    transition: -webkit-transform 1.5s ease-out;
    -o-transition: -o-transform 1.5s ease-out;
    -moz-transition: transform 1.5s ease-out, -moz-transform 1.5s ease-out;
    transition: transform 1.5s ease-out;
    transition: transform 1.5s ease-out, -webkit-transform 1.5s ease-out, -moz-transform 1.5s ease-out, -o-transform 1.5s ease-out;
    will-change: transform
}

.st-intro.-xs {
    height: 100%
}

.st-intro.-features {
    position: relative;
    height: 770px
}

@media (min-width:375px) {
    .st-intro.-features {
        height: 820px
    }
}

@media (min-width:768px) {
    .st-intro.-features {
        height: 760px
    }
}

@media (min-width:1280px) {
    .st-intro.-features {
        height: 855px
    }
}

@media (min-width:1440px) {
    .st-intro.-features {
        height: 935px
    }
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-wrap {
        padding: 126px 0 0 0
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-wrap {
        padding: 140px 0 0 0
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-wrap {
        padding: 160px 0 0 0
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-wrap {
        padding: 200px 0 0 0
    }
}

.st-intro.-features .st-intro-fill {
    height: 890px;
    top: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: 55% center;
    background-repeat: no-repeat
}

@media (min-width:375px) {
    .st-intro.-features .st-intro-fill {
        height: 1012px;
        top: -190px;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-position: 75% center;
        background-repeat: no-repeat
    }
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-fill {
        height: 950px;
        top: -190px;
        background-position: 82% center
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-fill {
        height: 1125px;
        top: -270px;
        background-position: 85% center
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-fill {
        background-position: 90% center;
        top: -190px
    }
}

.st-intro.-features .st-intro-pic {
    position: absolute;
    z-index: 1
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-pic:nth-child(1) {
        left: 108px;
        bottom: 601px
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-pic:nth-child(1) {
        left: 190px;
        bottom: 581px
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-pic:nth-child(1) {
        left: 287px;
        bottom: 671px
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-pic:nth-child(1) {
        left: 327px;
        bottom: 682px
    }
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-pic:nth-child(2) {
        left: -30px;
        bottom: 518px
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-pic:nth-child(2) {
        left: -25px;
        bottom: 506px
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-pic:nth-child(2) {
        left: 39px;
        bottom: 572px
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-pic:nth-child(2) {
        left: 79px;
        bottom: 592px
    }
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-pic:nth-child(3) {
        left: 60px;
        bottom: 259px
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-pic:nth-child(3) {
        left: 96px;
        bottom: 351px
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-pic:nth-child(3) {
        left: 204px;
        bottom: 370px
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-pic:nth-child(3) {
        left: 240px;
        bottom: 390px
    }
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-pic:nth-child(4) {
        right: -15px;
        bottom: 547px
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-pic:nth-child(4) {
        right: 65px;
        bottom: 558px
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-pic:nth-child(4) {
        right: 140px;
        bottom: 626px
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-pic:nth-child(4) {
        right: 196px;
        bottom: 666px
    }
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-pic:nth-child(5) {
        right: 65px;
        bottom: 297px
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-pic:nth-child(5) {
        right: 127px;
        bottom: 294px
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-pic:nth-child(5) {
        right: 229px;
        bottom: 307px
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-pic:nth-child(5) {
        right: 285px;
        bottom: 347px
    }
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-pic:nth-child(6) {
        right: 206px;
        bottom: 120px
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-pic:nth-child(6) {
        right: 219px;
        bottom: 117px
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-pic:nth-child(6) {
        right: 340px;
        bottom: 95px
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-pic:nth-child(6) {
        right: 396px;
        bottom: 135px
    }
}

.st-intro.-features .st-intro-figure {
    position: absolute
}

.st-intro.-features .st-intro-figure:nth-child(1) {
    display: none;
    right: auto;
    top: auto
}

@media (min-width:375px) {
    .st-intro.-features .st-intro-figure:nth-child(1) {
        left: -20px;
        bottom: -15px;
        display: block;
        -webkit-transform: scale(.7);
        -moz-transform: scale(.7);
        -ms-transform: scale(.7);
        -o-transform: scale(.7);
        transform: scale(.7)
    }
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-figure:nth-child(1) {
        left: 150px;
        bottom: -20px
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-figure:nth-child(1) {
        left: 100px;
        bottom: -30px;
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-figure:nth-child(1) {
        left: 10px;
        bottom: 170px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-figure:nth-child(1) {
        left: 40px;
        bottom: 155px
    }
}

.st-intro.-features .st-intro-figure:nth-child(2) {
    display: none;
    left: auto;
    top: auto
}

@media (min-width:375px) {
    .st-intro.-features .st-intro-figure:nth-child(2) {
        right: -150px;
        bottom: 270px;
        display: block;
        -webkit-transform: scale(.5);
        -moz-transform: scale(.5);
        -ms-transform: scale(.5);
        -o-transform: scale(.5);
        transform: scale(.5)
    }
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-figure:nth-child(2) {
        right: -150px;
        bottom: 270px;
        -webkit-transform: scale(.6);
        -moz-transform: scale(.6);
        -ms-transform: scale(.6);
        -o-transform: scale(.6);
        transform: scale(.6)
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-figure:nth-child(2) {
        right: -165px;
        bottom: 270px;
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-figure:nth-child(2) {
        right: -185px;
        bottom: 400px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-figure:nth-child(2) {
        right: -185px;
        bottom: 500px
    }
}

.st-intro.-features .st-intro-figure:nth-child(3) {
    display: none;
    left: auto;
    top: auto
}

@media (min-width:768px) {
    .st-intro.-features .st-intro-figure:nth-child(3) {
        right: 0;
        bottom: -95px;
        display: block
    }
}

@media (min-width:1024px) {
    .st-intro.-features .st-intro-figure:nth-child(3) {
        right: 40px;
        bottom: -85px
    }
}

@media (min-width:1280px) {
    .st-intro.-features .st-intro-figure:nth-child(3) {
        right: 140px;
        bottom: -90px
    }
}

@media (min-width:1440px) {
    .st-intro.-features .st-intro-figure:nth-child(3) {
        right: 240px;
        bottom: -70px
    }
}

.st-intro.-about {
    position: relative
}

.st-intro.-about .st-intro-figure:nth-child(1) {
    display: none;
    top: auto
}

@media (min-width:768px) {
    .st-intro.-about .st-intro-figure:nth-child(1) {
        left: -90px;
        bottom: -60px;
        display: block;
        -webkit-transform: scale(.7);
        -moz-transform: scale(.7);
        -ms-transform: scale(.7);
        -o-transform: scale(.7);
        transform: scale(.7)
    }
}

@media (min-width:1024px) {
    .st-intro.-about .st-intro-figure:nth-child(1) {
        left: -90px;
        bottom: -60px;
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@media (min-width:1280px) {
    .st-intro.-about .st-intro-figure:nth-child(1) {
        left: -70px;
        bottom: -60px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1440px) {
    .st-intro.-about .st-intro-figure:nth-child(1) {
        left: 90px;
        bottom: -60px
    }
}

.st-intro.-about .st-intro-figure:nth-child(2) {
    display: none;
    top: auto
}

@media (min-width:768px) {
    .st-intro.-about .st-intro-figure:nth-child(2) {
        display: block;
        right: -200px;
        bottom: -90px;
        -webkit-transform: scale(.7);
        -moz-transform: scale(.7);
        -ms-transform: scale(.7);
        -o-transform: scale(.7);
        transform: scale(.7)
    }
}

@media (min-width:1024px) {
    .st-intro.-about .st-intro-figure:nth-child(2) {
        right: -200px;
        bottom: -150px;
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@media (min-width:1280px) {
    .st-intro.-about .st-intro-figure:nth-child(2) {
        -webkit-transform: scale(.9);
        -moz-transform: scale(.9);
        -ms-transform: scale(.9);
        -o-transform: scale(.9);
        transform: scale(.9)
    }
}

@media (min-width:1440px) {
    .st-intro.-about .st-intro-figure:nth-child(2) {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.st-loginbox {
    position: relative;
    height: 100%
}

.st-loginbox-wrap {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 92px 0 0 0
}

@media (min-width:768px) {
    .st-loginbox-wrap {
        padding: 170px 0 90px 0
    }
}

@media (min-width:1024px) {
    .st-loginbox-wrap {
        padding: 110px 0 120px 0
    }
}

@media (min-width:1440px) {
    .st-loginbox-wrap {
        padding: 150px 0 30px 0
    }
}

@media (min-width:1024px) {
    .st-loginbox-wrap.-xs {
        padding: 110px 0 0 0
    }
}

@media (min-width:1440px) {
    .st-loginbox-wrap.-xs {
        padding: 150px 0 30px 0
    }
}

.st-loginbox-container {
    width: 100%;
    padding: 0 30px
}

@media (min-width:375px) {
    .st-loginbox-container {
        padding: 0 40px
    }
}

@media (min-width:768px) {
    .st-loginbox-container {
        width: 490px;
        margin: 0 auto;
        padding: 0 0 0 180px
    }
}

@media (min-width:1024px) {
    .st-loginbox-container {
        width: 620px;
        padding: 0 0 0 220px
    }
}

@media (min-width:1280px) {
    .st-loginbox-container {
        width: 460px;
        padding: 0 0 0 60px
    }
}

@media (min-width:1440px) {
    .st-loginbox-container {
        width: 430px;
        padding: 0 0 0 30px
    }
}

.st-loginbox-title {
    max-width: 170px
}

@media (min-width:768px) {
    .st-loginbox-title {
        max-width: 100%
    }
}

.st-loginbox-title h1 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    letter-spacing: -.29px;
    line-height: 34px
}

@media (min-width:768px) {
    .st-loginbox-title h1 {
        font-size: 32px;
        letter-spacing: -.38px;
        line-height: 52px
    }
}

.st-loginbox-header {
    margin: 0 0 33px 0
}

@media (min-width:768px) {
    .st-loginbox-header {
        margin: 0 0 55px 0
    }
}

.st-loginbox-social {
    margin: 55px 0 60px 0
}

@media (min-width:1024px) {
    .st-loginbox-social {
        margin: 60px 0 60px 0
    }
}

@media (min-width:1440px) {
    .st-loginbox-social {
        margin: 80px 0 60px 0
    }
}

.st-loginbox-fill {
    display: none;
    width: 431px;
    height: 1022px;
    position: absolute;
    top: -131px;
    left: -15px;
    z-index: 1;
    -webkit-transition: .2s;
    -o-transition: .2s;
    -moz-transition: .2s;
    transition: .2s
}

@media (min-width:768px) {
    .st-loginbox-fill {
        display: block;
        top: -220px;
        left: -190px
    }
}

@media (min-width:1024px) {
    .st-loginbox-fill {
        top: -305px;
        left: -100px
    }
}

@media (min-width:1280px) {
    .st-loginbox-fill {
        left: -80px
    }
}

@media (min-width:1440px) {
    .st-loginbox-fill {
        top: -270px;
        left: -15px
    }
}

.st-loginbox-fill-pic {
    position: absolute
}

.st-loginbox-fill-pic.-top {
    top: 240px;
    right: -140px;
    max-width: 210px
}

@media (min-width:1024px) {
    .st-loginbox-fill-pic.-top {
        top: 320px;
        max-width: 100%
    }
}

@media (min-width:1440px) {
    .st-loginbox-fill-pic.-top {
        top: 285px
    }
}

.st-loginbox-fill-pic.-bottom {
    right: 20px;
    bottom: -10px;
    max-width: 175px
}

@media (min-width:1024px) {
    .st-loginbox-fill-pic.-bottom {
        right: auto;
        left: 20px;
        bottom: -40px;
        max-width: 100%
    }
}

.st-loginbox-fill-pic.-small {
    right: 30px;
    bottom: -30px
}

.st-loginbox-fill-pic img {
    width: 100%
}

.st-loginbox-steps {
    display: none;
    position: absolute;
    top: 170px;
    left: 30px;
    z-index: 2;
    color: #fff
}

@media (min-width:768px) {
    .st-loginbox-steps {
        display: block
    }
}

@media (min-width:1024px) {
    .st-loginbox-steps {
        top: 149px;
        left: 50px
    }
}

@media (min-width:1440px) {
    .st-loginbox-steps {
        top: 190px;
        left: 60px
    }
}

.st-loginbox-steps ul {
    margin: 0;
    padding: 0
}

.st-loginbox-steps ul li {
    list-style: none
}

.st-loginbox-steps-title {
    margin: 0 0 32px 0;
    max-width: 200px;
    font-size: 20px;
    letter-spacing: -.33px;
    line-height: 29px
}

@media (min-width:1024px) {
    .st-loginbox-steps-title {
        font-size: 22px;
        letter-spacing: -.37px;
        line-height: 32px
    }
}

@media (min-width:1280px) {
    .st-loginbox-steps-title {
        max-width: 246px;
        font-weight: 600
    }
}

.st-loginbox-steps-items {
    max-width: 285px
}

.st-loginbox-steps-item {
    margin: 0 0 23px 0;
    font-size: 14px;
    letter-spacing: .44px;
    line-height: 27px
}

@media (min-width:1024px) {
    .st-loginbox-steps-item {
        font-size: 16px;
        letter-spacing: .5px
    }
}

.st-loginbox-steps-item span {
    display: inline-block;
    vertical-align: top;
    max-width: 180px
}

@media (min-width:1280px) {
    .st-loginbox-steps-item span {
        max-width: 230px
    }
}

.st-loginbox-steps-item-icon {
    margin: 0 15px 0 0
}

@media (min-width:1024px) {
    .st-loginbox-steps-item-icon {
        margin: 0 22px 0 0
    }
}

.st-loginbox-action {
    margin: 30px 0 0 0
}

@media (min-width:1024px) {
    .st-loginbox-action {
        margin: 40px 0 0 0
    }
}

.st-loginbox-action-btn {
    display: inline-block;
    vertical-align: middle
}

.st-loginbox-action-text {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 10px;
    font-size: 16px;
    letter-spacing: .12px;
    line-height: 22px
}

@media (min-width:1440px) {
    .st-loginbox-action-text {
        margin: 0 0 0 16px
    }
}

.st-newsbox {
    position: relative
}

.st-newsbox-container {
    position: relative;
    padding: 0 12px
}

@media (min-width:375px) {
    .st-newsbox-container {
        padding: 0 19px
    }
}

@media (min-width:768px) {
    .st-newsbox-container {
        padding: 0 56px
    }
}

@media (min-width:1024px) {
    .st-newsbox-container {
        padding: 0 84px
    }
}

@media (min-width:1280px) {
    .st-newsbox-container {
        padding: 0 134px
    }
}

@media (min-width:1440px) {
    .st-newsbox-container {
        width: 1200px;
        margin: 0 auto;
        padding: 0
    }
}

.st-newsbox-figure {
    position: absolute
}

.st-newsbox-figure:nth-child(1) {
    display: none
}

@media (min-width:768px) {
    .st-newsbox-figure:nth-child(1) {
        display: block;
        top: -170px;
        right: -115px
    }
}

@media (min-width:1024px) {
    .st-newsbox-figure:nth-child(1) {
        top: -170px;
        right: -105px
    }
}

@media (min-width:1280px) {
    .st-newsbox-figure:nth-child(1) {
        top: -170px;
        right: 0
    }
}

@media (min-width:1440px) {
    .st-newsbox-figure:nth-child(1) {
        top: -170px;
        right: -125px
    }
}

.st-newsbox-figure:nth-child(2) {
    z-index: 2;
    top: -90px;
    right: -60px
}

@media (min-width:768px) {
    .st-newsbox-figure:nth-child(2) {
        top: -90px;
        right: 120px
    }
}

@media (min-width:1024px) {
    .st-newsbox-figure:nth-child(2) {
        top: -90px;
        right: 130px
    }
}

@media (min-width:1280px) {
    .st-newsbox-figure:nth-child(2) {
        top: -90px;
        right: 260px
    }
}

@media (min-width:1440px) {
    .st-newsbox-figure:nth-child(2) {
        top: -90px;
        right: 280px
    }
}

.st-newsbox-figure:nth-child(3) {
    left: -15px;
    bottom: -55px
}

@media (min-width:768px) {
    .st-newsbox-figure:nth-child(3) {
        left: 110px;
        bottom: -60px
    }
}

@media (min-width:1024px) {
    .st-newsbox-figure:nth-child(3) {
        left: 180px;
        bottom: -60px
    }
}

@media (min-width:1280px) {
    .st-newsbox-figure:nth-child(3) {
        left: 280px;
        bottom: -60px
    }
}

@media (min-width:1440px) {
    .st-newsbox-figure:nth-child(3) {
        left: 200px;
        bottom: -60px
    }
}

.st-newsbox-nav {
    display: none
}

@media (min-width:1024px) {
    .st-newsbox-nav {
        display: block
    }
}

.st-newsbox-nav-item {
    position: absolute;
    top: 90px;
    z-index: 3
}

.st-newsbox-nav-item button {
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 1;
    background: 0 0;
    border: none
}

.st-newsbox-nav-item button:hover .st-newsbox-nav-item-fill {
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
    transform: scale(.9)
}

.st-newsbox-nav-item-fill {
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #f1f3f4;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -webkit-transition: -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    -o-transition: -o-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    -moz-transition: transform .25s cubic-bezier(.25, .25, .5, 1.9), -moz-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: transform .25s cubic-bezier(.25, .25, .5, 1.9), -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9), -moz-transform .25s cubic-bezier(.25, .25, .5, 1.9), -o-transform .25s cubic-bezier(.25, .25, .5, 1.9)
}

@media (min-width:1024px) {
    .st-newsbox-nav-item.-prev {
        left: 25px
    }
}

@media (min-width:1280px) {
    .st-newsbox-nav-item.-prev {
        left: 60px
    }
}

@media (min-width:1440px) {
    .st-newsbox-nav-item.-prev {
        left: -80px
    }
}

@media (min-width:1024px) {
    .st-newsbox-nav-item.-next {
        right: 25px
    }
}

@media (min-width:1280px) {
    .st-newsbox-nav-item.-next {
        right: 60px
    }
}

@media (min-width:1440px) {
    .st-newsbox-nav-item.-next {
        right: -80px
    }
}

.st-newsbox-dots {
    margin: 15px 0 0 0;
    text-align: center
}

@media (min-width:375px) {
    .st-newsbox-dots {
        margin: 20px 0 0 0
    }
}

.st-newsbox-dots button {
    display: inline-block;
    vertical-align: middle;
    width: 4px;
    height: 4px;
    padding: 0;
    margin: 0 20px 0 0;
    border: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: rgba(65, 77, 91, .3)
}

.st-newsbox-dots button:last-child {
    margin: 0
}

.st-newsbox-dots button.active {
    width: 8px;
    height: 8px;
    background: #0097ff
}

.st-newsbox-items {
    background: rgba(151, 151, 151, .11)
}

.st-newsbox-item {
    height: 260px;
    background: #fff
}

.st-newsbox-item-wrap {
    padding: 40px 0
}

.st-newsbox-item-container {
    padding: 0 30px
}

@media (min-width:768px) {
    .st-newsbox-item-container {
        padding: 0 35px
    }
}

@media (min-width:1024px) {
    .st-newsbox-item-container {
        padding: 0 30px
    }
}

@media (min-width:1280px) {
    .st-newsbox-item-container {
        padding: 0 20px 0 24px
    }
}

@media (min-width:1440px) {
    .st-newsbox-item-container {
        padding: 0 35px 0 28px
    }
}

.st-newsbox-logo {
    margin: 0 0 10px 0;
}

.st-newsbox-text {
    font-size: 16px;
    letter-spacing: .1px;
    line-height: 32px;
    font-style: italic
}

.st-career {
    position: relative;
    height: 1410px;
    color: #fff
}

@media (min-width:375px) {
    .st-career {
        height: 1340px
    }
}

@media (min-width:768px) {
    .st-career {
        height: 1260px
    }
}

@media (min-width:1024px) {
    .st-career {
        height: 1275px
    }
}

@media (min-width:1280px) {
    .st-career {
        height: 1365px
    }
}

@media (min-width:1440px) {
    .st-career {
        height: 1585px
    }
}

.st-career-fill {
    position: absolute;
    top: -80px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1585px;
    z-index: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: 77% center;
    background-repeat: no-repeat
}

@media (min-width:375px) {
    .st-career-fill {
        top: -140px
    }
}

@media (min-width:768px) {
    .st-career-fill {
        top: -325px;
        background-position: 89% center
    }
}

@media (min-width:1024px) {
    .st-career-fill {
        top: -310px;
        background-position: 95% center
    }
}

@media (min-width:1280px) {
    .st-career-fill {
        top: -220px;
        background-position: 93% center
    }
}

@media (min-width:1440px) {
    .st-career-fill {
        top: -50px;
        background-position: 100% center
    }
}

.st-career-figure {
    position: absolute
}

.st-career-figure:nth-child(1) {
    display: none
}

@media (min-width:1024px) {
    .st-career-figure:nth-child(1) {
        top: 485px;
        left: -45px;
        display: block
    }
}

@media (min-width:1280px) {
    .st-career-figure:nth-child(1) {
        top: 435px;
        left: -45px
    }
}

@media (min-width:1440px) {
    .st-career-figure:nth-child(1) {
        top: 373px;
        left: 0
    }
}

.st-career-figure:nth-child(2) {
    display: none
}

@media (min-width:768px) {
    .st-career-figure:nth-child(2) {
        top: 660px;
        left: 150px;
        display: block
    }
}

@media (min-width:1024px) {
    .st-career-figure:nth-child(2) {
        top: 660px;
        left: 260px
    }
}

@media (min-width:1280px) {
    .st-career-figure:nth-child(2) {
        top: 600px;
        left: 380px
    }
}

@media (min-width:1440px) {
    .st-career-figure:nth-child(2) {
        top: 524px;
        left: 440px
    }
}

.st-career-figure:nth-child(3) {
    display: none
}

@media (min-width:1024px) {
    .st-career-figure:nth-child(3) {
        top: 810px;
        left: 20px;
        display: block
    }
}

@media (min-width:1280px) {
    .st-career-figure:nth-child(3) {
        top: 740px;
        left: 90px
    }
}

@media (min-width:1440px) {
    .st-career-figure:nth-child(3) {
        top: 665px;
        left: 135px
    }
}

.st-career-figure:nth-child(4) {
    bottom: 75px;
    left: 70px;
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8)
}

@media (min-width:375px) {
    .st-career-figure:nth-child(4) {
        bottom: 65px;
        left: 110px
    }
}

@media (min-width:768px) {
    .st-career-figure:nth-child(4) {
        bottom: 75px;
        left: 160px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1024px) {
    .st-career-figure:nth-child(4) {
        bottom: 85px;
        left: 40px
    }
}

@media (min-width:1280px) {
    .st-career-figure:nth-child(4) {
        bottom: 70px;
        left: 15px
    }
}

@media (min-width:1440px) {
    .st-career-figure:nth-child(4) {
        bottom: 45px;
        left: 30px
    }
}

.st-career-figure:nth-child(5) {
    display: none
}

@media (min-width:768px) {
    .st-career-figure:nth-child(5) {
        top: 720px;
        right: -45px;
        display: block
    }
}

@media (min-width:1024px) {
    .st-career-figure:nth-child(5) {
        top: 665px;
        right: -55px
    }
}

@media (min-width:1280px) {
    .st-career-figure:nth-child(5) {
        top: 640px;
        right: -50px
    }
}

@media (min-width:1440px) {
    .st-career-figure:nth-child(5) {
        top: 440px;
        right: -40px
    }
}

.st-career-figure:nth-child(6) {
    display: none
}

@media (min-width:768px) {
    .st-career-figure:nth-child(6) {
        top: 685px;
        right: 105px;
        display: block
    }
}

@media (min-width:1024px) {
    .st-career-figure:nth-child(6) {
        top: 720px;
        right: 170px
    }
}

@media (min-width:1280px) {
    .st-career-figure:nth-child(6) {
        top: 630px;
        right: 240px
    }
}

@media (min-width:1440px) {
    .st-career-figure:nth-child(6) {
        top: 560px;
        right: 340px
    }
}

.st-career-figure:nth-child(7) {
    -webkit-transform: rotate(-60deg) scale(.75);
    -moz-transform: rotate(-60deg) scale(.75);
    -ms-transform: rotate(-60deg) scale(.75);
    -o-transform: rotate(-60deg) scale(.75);
    transform: rotate(-60deg) scale(.75);
    display: none
}

@media (min-width:768px) {
    .st-career-figure:nth-child(7) {
        top: 870px;
        right: -55px;
        display: block
    }
}

@media (min-width:1024px) {
    .st-career-figure:nth-child(7) {
        top: 830px;
        right: -75px;
        -webkit-transform: rotate(-60deg) scale(1);
        -moz-transform: rotate(-60deg) scale(1);
        -ms-transform: rotate(-60deg) scale(1);
        -o-transform: rotate(-60deg) scale(1);
        transform: rotate(-60deg) scale(1)
    }
}

@media (min-width:1280px) {
    .st-career-figure:nth-child(7) {
        top: 780px;
        right: 0
    }
}

@media (min-width:1440px) {
    .st-career-figure:nth-child(7) {
        top: 805px;
        right: 90px;
        -webkit-transform: rotate(0) scale(1);
        -moz-transform: rotate(0) scale(1);
        -ms-transform: rotate(0) scale(1);
        -o-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1)
    }
}

.st-career-figure:nth-child(8) {
    display: none
}

@media (min-width:1024px) {
    .st-career-figure:nth-child(8) {
        bottom: 40px;
        right: -360px;
        display: block
    }
}

@media (min-width:1280px) {
    .st-career-figure:nth-child(8) {
        bottom: 120px;
        right: -310px
    }
}

@media (min-width:1440px) {
    .st-career-figure:nth-child(8) {
        bottom: 155px;
        right: -270px
    }
}

.st-career-pic {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none
}

@media (min-width:768px) {
    .st-career-pic {
        display: block
    }
}

.st-career-pic-img {
    position: absolute
}

@media (min-width:768px) {
    .st-career-pic-img:nth-child(1) {
        left: 56px;
        top: 195px
    }
}

@media (min-width:1024px) {
    .st-career-pic-img:nth-child(1) {
        left: 112px;
        top: 122px
    }
}

@media (min-width:1280px) {
    .st-career-pic-img:nth-child(1) {
        left: 126px;
        top: 160px
    }
}

@media (min-width:1440px) {
    .st-career-pic-img:nth-child(1) {
        left: 166px;
        top: 177px
    }
}

.st-career-pic-img:nth-child(1) svg {
    width: 48px;
    height: 46px
}

.st-career-pic-img:nth-child(2) {
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
    transform: scale(.85)
}

@media (min-width:768px) {
    .st-career-pic-img:nth-child(2) {
        left: -39px;
        top: 294px
    }
}

@media (min-width:1024px) {
    .st-career-pic-img:nth-child(2) {
        left: 54px;
        top: 359px
    }
}

@media (min-width:1280px) {
    .st-career-pic-img:nth-child(2) {
        left: 150px;
        top: 374px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1440px) {
    .st-career-pic-img:nth-child(2) {
        left: 190px;
        top: 474px
    }
}

.st-career-pic-img:nth-child(2) svg {
    width: 152px;
    height: 138px
}

.st-career-pic-img:nth-child(3) {
    display: none
}

@media (min-width:1024px) {
    .st-career-pic-img:nth-child(3) {
        left: -24px;
        bottom: 522px;
        display: block
    }
}

@media (min-width:1280px) {
    .st-career-pic-img:nth-child(3) {
        left: 24px;
        bottom: 600px
    }
}

@media (min-width:1440px) {
    .st-career-pic-img:nth-child(3) {
        left: 64px;
        bottom: 606px
    }
}

.st-career-pic-img:nth-child(3) svg {
    width: 78px;
    height: 75px
}

@media (min-width:768px) {
    .st-career-pic-img:nth-child(4) {
        right: -35px;
        top: 179px
    }
}

@media (min-width:1024px) {
    .st-career-pic-img:nth-child(4) {
        right: -12px;
        top: 154px
    }
}

@media (min-width:1280px) {
    .st-career-pic-img:nth-child(4) {
        right: 34px;
        top: 152px
    }
}

@media (min-width:1440px) {
    .st-career-pic-img:nth-child(4) {
        right: 106px;
        top: 200px
    }
}

.st-career-pic-img:nth-child(4) svg {
    width: 113px;
    height: 109px
}

@media (min-width:768px) {
    .st-career-pic-img:nth-child(5) {
        right: 105px;
        top: 339px
    }
}

@media (min-width:1024px) {
    .st-career-pic-img:nth-child(5) {
        right: 137px;
        top: 301px
    }
}

@media (min-width:1280px) {
    .st-career-pic-img:nth-child(5) {
        right: 182px;
        top: 384px
    }
}

@media (min-width:1440px) {
    .st-career-pic-img:nth-child(5) {
        right: 261px;
        top: 474px
    }
}

.st-career-pic-img:nth-child(5) svg {
    width: 48px;
    height: 46px
}

.st-career-pic-img image {
    width: 100%;
    height: 100%
}

.st-career-container {
    margin: 0 19px
}

@media (min-width:375px) {
    .st-career-container {
        margin: 0 35px
    }
}

@media (min-width:768px) {
    .st-career-container {
        max-width: 595px;
        margin: 0 auto
    }
}

@media (min-width:1024px) {
    .st-career-container {
        max-width: 815px
    }
}

.st-career-row {
    margin: 0 0 40px 0;
    text-align: center
}

@media (min-width:768px) {
    .st-career-row {
        margin: 0 0 50px 0
    }
}

@media (min-width:1440px) {
    .st-career-row {
        margin: 0 0 74px 0
    }
}

.st-career-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.st-career-grid-col {
    width: 50%;
    margin: 0 0 30px 0
}

@media (min-width:768px) {
    .st-career-grid-col {
        width: 25%;
        margin: 0
    }
}

.st-career-image {
    min-height: 80px;
    margin: 0 0 10px 0
}

@media (min-width:768px) {
    .st-career-image {
        margin: 0
    }
}

@media (min-width:1024px) {
    .st-career-image {
        margin: 0 0 10px 0
    }
}

@media (min-width:1440px) {
    .st-career-image {
        margin: 0 0 30px 0
    }
}

.st-career-image img {
    -webkit-transform: scale(.7);
    -moz-transform: scale(.7);
    -ms-transform: scale(.7);
    -o-transform: scale(.7);
    transform: scale(.7)
}

@media (min-width:1280px) {
    .st-career-image img {
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@media (min-width:1440px) {
    .st-career-image img {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.st-career-text {
    max-width: 125px;
    margin: 0 auto;
    font-size: 16px;
    letter-spacing: -.27px;
    line-height: 28px
}

.st-card-title {
    margin: 0 0 30px 0;
    font-size: 28px;
    letter-spacing: -.34px;
    line-height: 44px;
    text-align: center
}

@media (min-width:768px) {
    .st-card-title {
        margin: 0 0 40px 0
    }
}

@media (min-width:1024px) {
    .st-card-title {
        font-size: 32px;
        letter-spacing: -.36px;
        line-height: 52px
    }
}

@media (min-width:1280px) {
    .st-card-title {
        margin: 0 0 50px 0
    }
}

@media (min-width:1440px) {
    .st-card-title {
        margin: 0 0 60px 0
    }
}

.st-card-wrap {
    position: relative;
    height: 100%;
    padding: 50px 0 35px 0
}

@media (min-width:375px) {
    .st-card-wrap {
        padding: 60px 0 45px 0
    }
}

@media (min-width:768px) {
    .st-card-wrap {
        padding: 42px 0
    }
}

@media (min-width:1024px) {
    .st-card-wrap {
        padding: 60px 0 45px 0
    }
}

.st-card-container {
    padding: 0 30px
}

@media (min-width:375px) {
    .st-card-container {
        padding: 0 40px
    }
}

@media (min-width:768px) {
    .st-card-container {
        padding: 0 30px
    }
}

@media (min-width:1440px) {
    .st-card-container {
        padding: 0 26px
    }
}

.st-card-figure {
    position: absolute;
    z-index: 2
}

.st-card-figure:nth-child(1) {
    top: 20px;
    left: -20px
}

@media (min-width:375px) {
    .st-card-figure:nth-child(1) {
        top: 35px;
        left: -10px
    }
}

@media (min-width:768px) {
    .st-card-figure:nth-child(1) {
        top: 10px;
        left: -40px;
        -webkit-transform: scale(.7);
        -moz-transform: scale(.7);
        -ms-transform: scale(.7);
        -o-transform: scale(.7);
        transform: scale(.7)
    }
}

@media (min-width:1024px) {
    .st-card-figure:nth-child(1) {
        top: 45px;
        left: -30px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1280px) {
    .st-card-figure:nth-child(1) {
        top: 60px;
        left: -5px
    }
}

@media (min-width:1440px) {
    .st-card-figure:nth-child(1) {
        top: 60px;
        left: 0
    }
}

.st-card-figure:nth-child(2) {
    bottom: 25px;
    right: 20px
}

@media (min-width:375px) {
    .st-card-figure:nth-child(2) {
        bottom: 45px;
        right: 20px
    }
}

@media (min-width:768px) {
    .st-card-figure:nth-child(2) {
        bottom: 35px;
        right: -10px;
        -webkit-transform: scale(.7);
        -moz-transform: scale(.7);
        -ms-transform: scale(.7);
        -o-transform: scale(.7);
        transform: scale(.7)
    }
}

@media (min-width:1024px) {
    .st-card-figure:nth-child(2) {
        bottom: 45px;
        right: 20px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1280px) {
    .st-card-figure:nth-child(2) {
        bottom: 60px;
        right: 40px
    }
}

@media (min-width:1440px) {
    .st-card-figure:nth-child(2) {
        bottom: 65px;
        right: 40px
    }
}

.st-card-figure.-one {
    left: auto;
    top: auto;
    display: none
}

@media (min-width:1024px) {
    .st-card-figure.-one {
        bottom: -320px;
        right: -280px;
        display: block
    }
}

@media (min-width:1280px) {
    .st-card-figure.-one {
        bottom: -320px;
        right: -290px
    }
}

@media (min-width:1440px) {
    .st-card-figure.-one {
        bottom: -320px;
        right: -330px
    }
}

.st-card-figure.-two {
    bottom: auto;
    right: auto;
    left: -35px;
    top: 5px
}

@media (min-width:375px) {
    .st-card-figure.-two {
        left: -35px;
        top: 5px
    }
}

@media (min-width:768px) {
    .st-card-figure.-two {
        left: -40px;
        top: 0
    }
}

@media (min-width:1024px) {
    .st-card-figure.-two {
        left: 110px;
        top: -20px
    }
}

@media (min-width:1280px) {
    .st-card-figure.-two {
        left: 130px;
        top: -20px
    }
}

@media (min-width:1440px) {
    .st-card-figure.-two {
        left: 160px;
        top: -20px
    }
}

.st-card-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.st-card-grid-col {
    padding: 0 0 0 13px
}

.st-card-grid-col:first-child {
    padding: 0
}

.st-card-items {
    position: relative
}

@media (min-width:768px) {
    .st-card-items {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.st-card-item {
    margin: 0 0 20px 0;
    background: #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px
}

@media (min-width:768px) {
    .st-card-item {
        width: 33.3333%;
        margin: 0 30px 0 0
    }
}

@media (min-width:1024px) {
    .st-card-item {
        margin: 0 45px 0 0
    }
}

.st-card-item:last-child {
    margin: 0
}

@media (min-width:768px) {
    .st-card-item-brief {
        display: inline-block;
        vertical-align: top;
        margin: 0 0 0 45px
    }
}

@media (min-width:1024px) {
    .st-card-item-brief {
        display: block;
        margin: 0
    }
}

.st-card-item-title {
    margin: 0 0 20px 0;
    font-size: 18px;
    letter-spacing: -.3px;
    line-height: 28px;
    font-weight: 700
}

.st-card-item-title.-xs {
    margin: 0
}

@media (min-width:768px) {
    .st-card-item-title span {
        display: block
    }
}

@media (min-width:1024px) {
    .st-card-item-title span {
        display: inline-block
    }
}

.st-card-item-description {
    font-size: 16px;
    letter-spacing: .27px;
    line-height: 30px;
    color: rgba(65, 77, 91, .5)
}

.st-card-item-description.-black {
    color: #414d5b
}

.st-card-item-icon {
    padding: 4px 0 0 0;
    font-size: 14px
}

.st-card-item.-center {
    text-align: center
}

.st-card-body {
    height: 100%;
    background: #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

.st-card-body:focus,
.st-card-body:hover {
    -webkit-box-shadow: 0 7px 24px rgba(174, 189, 205, .15);
    -moz-box-shadow: 0 7px 24px rgba(174, 189, 205, .15);
    box-shadow: 0 7px 24px rgba(174, 189, 205, .15)
}

.st-card-photo {
    margin: 0 0 35px 0
}

@media (min-width:375px) {
    .st-card-photo {
        margin: 0 0 40px 0
    }
}

@media (min-width:768px) {
    .st-card-photo {
        margin: 0 0 30px 0
    }
}

@media (min-width:1024px) {
    .st-card-photo {
        margin: 0 0 45px 0
    }
}

.st-card-photo svg {
    width: 200px;
    height: 197px;
    -webkit-transform: scale(.86);
    -moz-transform: scale(.86);
    -ms-transform: scale(.86);
    -o-transform: scale(.86);
    transform: scale(.86)
}

@media (min-width:768px) {
    .st-card-photo svg {
        -webkit-transform: scale(.68);
        -moz-transform: scale(.68);
        -ms-transform: scale(.68);
        -o-transform: scale(.68);
        transform: scale(.68)
    }
}

@media (min-width:1024px) {
    .st-card-photo svg {
        -webkit-transform: scale(.88);
        -moz-transform: scale(.88);
        -ms-transform: scale(.88);
        -o-transform: scale(.88);
        transform: scale(.88)
    }
}

@media (min-width:1280px) {
    .st-card-photo svg {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.st-card-action {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 25px 0 0 0;
    padding: 22px 0 28px 0;
    border-top: 1px solid rgba(151, 151, 151, .11)
}

@media (min-width:1024px) {
    .st-card-action {
        margin: 36px 0 0 0;
        padding: 28px 0
    }
}

.st-card.-xs .st-card-items {
    display: block
}

@media (min-width:1024px) {
    .st-card.-xs .st-card-items {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.st-card.-xs .st-card-wrap {
    padding: 40px 0 35px 0
}

@media (min-width:1440px) {
    .st-card.-xs .st-card-wrap {
        padding: 40px 0
    }
}

.st-card.-xs .st-card-item {
    min-height: 210px;
    width: 100%;
    margin: 0 0 20px 0
}

.st-card.-xs .st-card-item:last-child {
    margin: 0
}

@media (min-width:768px) {
    .st-card.-xs .st-card-item {
        min-height: 140px
    }
}

@media (min-width:1024px) {
    .st-card.-xs .st-card-item {
        min-height: 215px;
        width: 33.3333%;
        margin: 0 45px 0 0
    }
}

@media (min-width:1440px) {
    .st-card.-xs .st-card-item {
        min-height: 192px
    }
}

@media (min-width:768px) {
    .st-card.-xs .st-card-item-title {
        display: inline-block;
        vertical-align: top
    }
}

@media (min-width:1024px) {
    .st-card.-xs .st-card-item-title {
        display: block
    }
}

.st-card.-career .st-card-wrap {
    padding: 25px 0
}

@media (min-width:1024px) {
    .st-card.-career .st-card-wrap {
        padding: 28px 0
    }
}

.st-card.-career .st-card-items {
    display: block
}

@media (min-width:768px) {
    .st-card.-career .st-card-items {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 -45px 0 0
    }
}

.st-card.-career .st-card-item {
    width: 100%;
    height: 294px;
    margin: 0 0 40px 0;
    background: 0 0
}

@media (min-width:768px) {
    .st-card.-career .st-card-item {
        width: 50%;
        padding-right: 45px
    }
}

@media (min-width:1024px) {
    .st-card.-career .st-card-item {
        width: 33.3333%;
        height: 316px
    }
}

@media (min-width:1280px) {
    .st-card.-career .st-card-item {
        width: 25%
    }
}

.st-card.-career .st-card-item-brief {
    display: block;
    margin: 0
}

.st-card.-career .st-card-item-title {
    max-width: 190px;
    min-height: 56px;
    margin: 0 0 30px 0
}

@media (min-width:1280px) {
    .st-card.-career .st-card-item-title {
        max-width: 215px
    }
}

.st-card.-career .st-card-item-body {
    max-width: 100%
}

@media (min-width:1440px) {
    .st-card.-career .st-card-item-body {
        max-width: 306px
    }
}

@media (min-width:1024px) {
    .st-card.-career .st-card-item-body {
        max-width: 276px
    }
}

@media (min-width:1280px) {
    .st-card.-career .st-card-item-body {
        max-width: 246px
    }
}

@media (min-width:1440px) {
    .st-card.-career .st-card-item-body {
        max-width: 266px
    }
}

.st-list-title {
    margin: 0 0 20px 0;
    font-size: 28px;
    letter-spacing: -.34px;
    line-height: 44px;
    text-align: center
}

@media (min-width:768px) {
    .st-list-title {
        margin: 0 0 30px 0;
        font-size: 32px;
        letter-spacing: -.38px;
        line-height: 52px
    }
}

@media (min-width:1280px) {
    .st-list-title {
        margin: 0 0 40px 0
    }
}

@media (min-width:1440px) {
    .st-list-title {
        margin: 0 0 60px 0
    }
}

.st-list-wrap {
    width: 100%;
    padding: 0 0 50px 0
}

@media (min-width:375px) {
    .st-list-wrap {
        padding: 0 0 70px 0
    }
}

@media (min-width:768px) {
    .st-list-wrap {
        padding: 0 0 60px 0
    }
}

@media (min-width:1024px) {
    .st-list-wrap {
        padding: 0 0 80px 0
    }
}

@media (min-width:1280px) {
    .st-list-wrap {
        padding: 0 0 93px 0
    }
}

@media (min-width:768px) {
    .st-list-grid {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

@media (min-width:768px) {
    .st-list-grid-col {
        width: 33.3333%;
        padding: 0 15px
    }
    .st-list-grid-col:first-child {
        padding: 0 20px 0 0
    }
    .st-list-grid-col:last-child {
        padding: 0 0 0 20px
    }
}

@media (min-width:1024px) {
    .st-list-grid-col {
        width: auto;
        padding: 0 20px 0 0
    }
    .st-list-grid-col:last-child {
        padding: 0
    }
}

.st-list-items {
    padding: 0;
    margin: 0
}

.st-list-items-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 20px 0;
    font-size: 14px;
    letter-spacing: .44px;
    line-height: 28px;
    list-style: none
}

@media (min-width:768px) {
    .st-list-items-item {
        margin: 0 0 25px 0
    }
}

@media (min-width:1024px) {
    .st-list-items-item {
        margin: 0 0 22px 0;
        font-size: 16px;
        letter-spacing: .5px;
        line-height: 34px
    }
}

.st-list-items-item span {
    padding: 0 0 0 20px
}

@media (min-width:1024px) {
    .st-list-items-item span {
        padding: 0 0 0 28px
    }
}

.st-list-items-item span:first-child {
    padding: 0
}

.st-list-items-item-icon {
    font-size: 18px;
    color: #0097ff
}

.st-case-title {
    margin: 0 0 30px 0;
    text-align: center
}

@media (min-width:1280px) {
    .st-case-title {
        margin: 0 0 50px 0
    }
}

.st-case-title h1 {
    font-size: 24px;
    letter-spacing: -.29px;
    line-height: 34px
}

@media (min-width:768px) {
    .st-case-title h1 {
        font-size: 32px;
        letter-spacing: -.36px;
        line-height: 52px
    }
}

@media (min-width:768px) {
    .st-case-title h1 {
        font-size: 36px;
        letter-spacing: -.43px;
        line-height: 52px
    }
}

.st-case-title.-sm {
    margin: 0 0 30px 0;
    text-align: left
}

@media (min-width:768px) {
    .st-case-title.-sm {
        margin: 0 0 40px 0
    }
}

@media (min-width:768px) {
    .st-case-title.-sm {
        max-width: 540px
    }
}

@media (min-width:1280px) {
    .st-case-title.-sm {
        margin: 0 0 50px 0;
        max-width: 640px
    }
}

@media (min-width:1440px) {
    .st-case-title.-sm {
        max-width: 670px
    }
}

.st-case-title.-sm h1 {
    font-size: 24px;
    letter-spacing: -.29px;
    line-height: 34px
}

@media (min-width:768px) {
    .st-case-title.-sm h1 {
        font-size: 32px;
        letter-spacing: -.36px;
        line-height: 52px
    }
}

.st-case-subtitle {
    margin: 0 0 20px 0
}

.st-case-subtitle h2 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    letter-spacing: -.3px;
    line-height: 32px;
    font-weight: 700
}

.st-case-description {
    font-size: 14px;
    letter-spacing: .23px;
    line-height: 30px
}

@media (min-width:768px) {
    .st-case-description {
        font-size: 16px;
        letter-spacing: .5px;
        line-height: 34px
    }
}

.st-case-description p {
    padding: 0;
    margin: 0 0 30px 0
}

.st-case-description p:last-child {
    margin: 0
}

@media (min-width:768px) {
    .st-case-description p {
        margin: 0 0 40px 0
    }
}

@media (min-width:1280px) {
    .st-case-description p {
        margin: 0 0 20px 0
    }
}

@media (min-width:768px) {
    .st-case-text {
        max-width: 600px
    }
}

@media (min-width:1024px) {
    .st-case-text {
        max-width: 640px
    }
}

@media (min-width:1280px) {
    .st-case-text {
        max-width: 800px
    }
}

.st-case-row {
    margin: 0 0 30px 0
}

@media (min-width:768px) {
    .st-case-row {
        margin: 0 0 40px 0
    }
}

.st-case-wrap {
    padding: 100px 0 35px 0
}

@media (min-width:375px) {
    .st-case-wrap {
        padding: 100px 0 60px 0
    }
}

@media (min-width:768px) {
    .st-case-wrap {
        padding: 140px 0 20px 0
    }
}

@media (min-width:1024px) {
    .st-case-wrap {
        padding: 140px 0 25px 0
    }
}

@media (min-width:1280px) {
    .st-case-wrap {
        padding: 140px 0 50px 0
    }
}

@media (min-width:1440px) {
    .st-case-wrap {
        padding: 140px 0 100px 0
    }
}

.st-case-container {
    position: relative;
    padding: 0 12px
}

@media (min-width:375px) {
    .st-case-container {
        padding: 0 19px
    }
}

@media (min-width:768px) {
    .st-case-container {
        padding: 0 56px
    }
}

@media (min-width:1024px) {
    .st-case-container {
        padding: 0 54px
    }
}

@media (min-width:1280px) {
    .st-case-container {
        padding: 0 62px
    }
}

@media (min-width:1440px) {
    .st-case-container {
        width: 1200px;
        margin: 0 auto;
        padding: 0
    }
}

.st-case-container.-xs {
    position: relative;
    padding: 0 12px
}

@media (min-width:375px) {
    .st-case-container.-xs {
        padding: 0 19px
    }
}

@media (min-width:768px) {
    .st-case-container.-xs {
        padding: 0 56px
    }
}

@media (min-width:1024px) {
    .st-case-container.-xs {
        width: 790px;
        margin: 0 auto;
        padding: 0
    }
}

@media (min-width:1280px) {
    .st-case-container.-xs {
        width: 923px
    }
}

@media (min-width:1440px) {
    .st-case-container.-xs {
        width: 994px
    }
}

.st-case-container.-xs.-full {
    padding: 0
}

.st-case-body {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 188px;
    margin: 0 0 15px 0;
    background: #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px
}

@media (min-width:375px) {
    .st-case-body {
        height: 217px;
        margin: 0 0 20px 0
    }
}

@media (min-width:768px) {
    .st-case-body {
        height: 222px;
        margin: 0 0 15px 0
    }
}

@media (min-width:1024px) {
    .st-case-body {
        height: 200px
    }
}

@media (min-width:1280px) {
    .st-case-body {
        height: 250px;
        margin: 0 0 28px 0
    }
}

.st-case-body-link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.st-case-body-link a {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

.st-case-body-link a:focus,
.st-case-body-link a:hover {
    -webkit-box-shadow: 0 7px 24px rgba(174, 189, 205, .15);
    -moz-box-shadow: 0 7px 24px rgba(174, 189, 205, .15);
    box-shadow: 0 7px 24px rgba(174, 189, 205, .15)
}

.st-case-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.st-case-grid-col {
    padding: 0 0 0 13px
}

.st-case-grid-col:first-child {
    padding: 0
}

.st-case-items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 -45px 0 0
}

.st-case-item {
    width: 100%;
    padding-right: 45px;
    margin: 0 0 40px 0
}

@media (min-width:768px) {
    .st-case-item {
        width: 50%
    }
}

@media (min-width:1024px) {
    .st-case-item {
        width: 33.3333%;
        margin: 0 0 45px 0
    }
}

@media (min-width:1280px) {
    .st-case-item {
        margin: 0 0 50px 0
    }
}

@media (min-width:1440px) {
    .st-case-item {
        margin: 0 0 60px 0
    }
}

.st-case-item-title {
    font-size: 18px;
    letter-spacing: -.3px;
    line-height: 28px;
    font-weight: 700
}

.st-case-figure {
    position: absolute
}

.st-case-figure.-one {
    top: 150px;
    right: -125px;
    -webkit-transform: scale(.7);
    -moz-transform: scale(.7);
    -ms-transform: scale(.7);
    -o-transform: scale(.7);
    transform: scale(.7)
}

@media (min-width:375px) {
    .st-case-figure.-one {
        top: 190px;
        right: -120px
    }
}

@media (min-width:768px) {
    .st-case-figure.-one {
        top: -30px;
        right: -115px;
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@media (min-width:1024px) {
    .st-case-figure.-one {
        top: -30px;
        right: -115px
    }
}

@media (min-width:1280px) {
    .st-case-figure.-one {
        top: -20px;
        right: -110px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1440px) {
    .st-case-figure.-one {
        top: 0;
        right: -102px
    }
}

.st-case-figure.-two {
    top: -20px;
    left: -55px;
    -webkit-transform: scale(.7);
    -moz-transform: scale(.7);
    -ms-transform: scale(.7);
    -o-transform: scale(.7);
    transform: scale(.7)
}

@media (min-width:375px) {
    .st-case-figure.-two {
        top: -20px;
        left: -45px;
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@media (min-width:768px) {
    .st-case-figure.-two {
        top: 90px;
        left: -25px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1024px) {
    .st-case-figure.-two {
        top: 90px;
        left: -25px
    }
}

@media (min-width:1280px) {
    .st-case-figure.-two {
        top: 340px;
        left: -85px
    }
}

@media (min-width:1440px) {
    .st-case-figure.-two {
        left: -155px
    }
}

.st-case-image {
    margin: 30px 0 0 0
}

.st-case-image-img {
    max-height: 320px;
    background: #f5f5f5;
    overflow: hidden;
    -webkit-transition: max-height .8s cubic-bezier(0, 1, 0, 1);
    -o-transition: max-height .8s cubic-bezier(0, 1, 0, 1);
    -moz-transition: max-height .8s cubic-bezier(0, 1, 0, 1);
    transition: max-height .8s cubic-bezier(0, 1, 0, 1)
}

@media (min-width:375px) {
    .st-case-image-img {
        max-height: 375px
    }
}

@media (min-width:768px) {
    .st-case-image-img {
        max-height: 500px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px
    }
}

@media (min-width:1024px) {
    .st-case-image-img {
        max-height: 400px
    }
}

@media (min-width:1280px) {
    .st-case-image-img {
        max-height: 450px
    }
}

.st-case-image-img img {
    width: 100%
}

.st-case-image-img.-full {
    max-height: 9999px;
    -webkit-transition: max-height 1.2s cubic-bezier(.5, 0, 1, 0);
    -o-transition: max-height 1.2s cubic-bezier(.5, 0, 1, 0);
    -moz-transition: max-height 1.2s cubic-bezier(.5, 0, 1, 0);
    transition: max-height 1.2s cubic-bezier(.5, 0, 1, 0)
}

.st-case-image-action {
    position: relative;
    z-index: 1;
    margin: 25px 0 0 0;
    text-align: center
}

.st-case-adv {
    margin: 60px 0 0 0;
    color: #fff
}

.st-case-adv-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%
}

@media (min-width:768px) {
    .st-case-adv-grid {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.st-case-adv-grid-col {
    margin: 0 0 25px 0
}

.st-case-adv-grid-col:last-child {
    padding: 0;
    margin: 0
}

@media (min-width:768px) {
    .st-case-adv-grid-col {
        padding: 0 40px 0 0;
        margin: 0
    }
}

@media (min-width:1024px) {
    .st-case-adv-grid-col {
        padding: 0 50px 0 0
    }
}

@media (min-width:1280px) {
    .st-case-adv-grid-col {
        padding: 0 40px 0 0
    }
}

@media (min-width:1440px) {
    .st-case-adv-grid-col {
        padding: 0 50px 0 0
    }
}

.st-case-adv-container {
    margin: 0 0 0 54px;
    height: 100%
}

@media (min-width:375px) {
    .st-case-adv-container {
        margin: 0 0 0 60px
    }
}

@media (min-width:768px) {
    .st-case-adv-container {
        margin: 0 0 0 72px
    }
}

@media (min-width:1024px) {
    .st-case-adv-container {
        margin: 0 0 0 44px
    }
}

@media (min-width:1280px) {
    .st-case-adv-container {
        margin: 0 0 0 97px
    }
}

@media (min-width:1440px) {
    .st-case-adv-container {
        margin: 0 0 0 104px
    }
}

.st-case-adv-box {
    width: 100%;
    height: 236px;
    margin: 0 0 40px 0;
    background: #f7ce68;
    background: -moz-linear-gradient(left, #f7ce68 0, #fbab7e 100%);
    background: -webkit-linear-gradient(left, #f7ce68 0, #fbab7e 100%);
    background: -webkit-gradient(linear, left top, right top, from(#f7ce68), to(#fbab7e));
    background: -o-linear-gradient(left, #f7ce68 0, #fbab7e 100%);
    background: linear-gradient(to right, #f7ce68 0, #fbab7e 100%);
    -webkit-box-shadow: 30px 21px 64px rgba(250, 181, 121, .42);
    -moz-box-shadow: 30px 21px 64px rgba(250, 181, 121, .42);
    box-shadow: 30px 21px 64px rgba(250, 181, 121, .42);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px
}

@media (min-width:375px) {
    .st-case-adv-box {
        margin: 0 0 50px 0
    }
}

@media (min-width:768px) {
    .st-case-adv-box {
        width: 466px;
        height: 137px;
        margin: 0 0 50px 0
    }
}

@media (min-width:1024px) {
    .st-case-adv-box {
        margin: 0 0 60px 0
    }
}

@media (min-width:1280px) {
    .st-case-adv-box {
        width: 578px;
        height: 170px;
        margin: 0 0 70px 0
    }
}

.st-case-adv-action {
    position: relative;
    z-index: 1;
    text-align: center
}

@media (min-width:768px) {
    .st-case-adv-action {
        text-align: left
    }
}

.st-case-adv-title {
    font-size: 36px;
    letter-spacing: -.43px;
    line-height: 40px;
    font-weight: 700
}

@media (min-width:1280px) {
    .st-case-adv-title {
        margin: 0 0 7px 0;
        font-size: 46px;
        letter-spacing: -.58px
    }
}

.st-case-adv-description {
    font-size: 20px;
    letter-spacing: -.24px;
    line-height: 32px
}

@media (min-width:1280px) {
    .st-case-adv-description {
        font-size: 24px;
        letter-spacing: -.29px
    }
}

.st-case-carousel {
    position: relative;
    margin: 80px 0 50px 0
}

@media (min-width:768px) {
    .st-case-carousel {
        margin: 80px 0 90px 0
    }
}

@media (min-width:1024px) {
    .st-case-carousel {
        margin: 80px 0 110px 0
    }
}

@media (min-width:1280px) {
    .st-case-carousel {
        margin: 125px 0 80px 0
    }
}

@media (min-width:1440px) {
    .st-case-carousel {
        margin: 125px 0 50px 0
    }
}

.st-case-carousel-container {
    position: relative;
    padding: 0 12px
}

@media (min-width:375px) {
    .st-case-carousel-container {
        padding: 0 19px
    }
}

@media (min-width:768px) {
    .st-case-carousel-container {
        padding: 0 56px
    }
}

@media (min-width:1024px) {
    .st-case-carousel-container {
        padding: 0 84px
    }
}

@media (min-width:1280px) {
    .st-case-carousel-container {
        padding: 0 134px
    }
}

@media (min-width:1440px) {
    .st-case-carousel-container {
        width: 1200px;
        margin: 0 auto;
        padding: 0
    }
}

.st-case-carousel-nav {
    display: none
}

@media (min-width:1024px) {
    .st-case-carousel-nav {
        display: block
    }
}

.st-case-carousel-nav-item {
    position: absolute;
    top: 80px;
    z-index: 3
}

@media (min-width:1280px) {
    .st-case-carousel-nav-item {
        top: 110px
    }
}

.st-case-carousel-nav-item button {
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 1;
    background: 0 0;
    border: none
}

.st-case-carousel-nav-item button:hover .st-case-carousel-nav-item-fill {
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
    transform: scale(.9)
}

.st-case-carousel-nav-item-fill {
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #f1f3f4;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -webkit-transition: -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    -o-transition: -o-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    -moz-transition: transform .25s cubic-bezier(.25, .25, .5, 1.9), -moz-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: transform .25s cubic-bezier(.25, .25, .5, 1.9), -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9), -moz-transform .25s cubic-bezier(.25, .25, .5, 1.9), -o-transform .25s cubic-bezier(.25, .25, .5, 1.9)
}

@media (min-width:1024px) {
    .st-case-carousel-nav-item.-prev {
        left: 25px
    }
}

@media (min-width:1280px) {
    .st-case-carousel-nav-item.-prev {
        left: 60px
    }
}

@media (min-width:1440px) {
    .st-case-carousel-nav-item.-prev {
        left: -80px
    }
}

@media (min-width:1024px) {
    .st-case-carousel-nav-item.-next {
        right: 25px
    }
}

@media (min-width:1280px) {
    .st-case-carousel-nav-item.-next {
        right: 60px
    }
}

@media (min-width:1440px) {
    .st-case-carousel-nav-item.-next {
        right: -80px
    }
}

.st-sheet-container {
    position: relative;
    padding: 0 20px;
    margin: 0 auto
}

@media (min-width:768px) {
    .st-sheet-container {
        width: 702px;
        padding: 0
    }
}

@media (min-width:1024px) {
    .st-sheet-container {
        width: 916px
    }
}

@media (min-width:1280px) {
    .st-sheet-container {
        width: 1011px
    }
}

@media (min-width:1440px) {
    .st-sheet-container {
        width: 993px
    }
}

.st-sheet-item {
    position: relative;
    margin: 0 0 40px 0;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background: #fff;
    z-index: 1
}

@media (min-width:768px) {
    .st-sheet-item {
        display: inline-block;
        vertical-align: middle;
        width: 339px;
        height: 610px;
        margin: 0 19px 0 0
    }
    .st-sheet-item:last-child {
        margin: 0
    }
}

@media (min-width:1024px) {
    .st-sheet-item {
        display: block;
        width: 100%;
        height: 450px;
        margin: 0 0 40px 0
    }
}

@media (min-width:1440px) {
    .st-sheet-item {
        height: 450px
    }
}

.st-sheet-item-wrap {
    padding: 30px 0 45px 0
}

@media (min-width:768px) {
    .st-sheet-item-wrap {
        padding: 40px 0 0 0
    }
}

@media (min-width:1024px) {
    .st-sheet-item-wrap {
        padding: 0;
        height: 100%
    }
}

@media (min-width:1024px) {
    .st-sheet-grid {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        height: 100%;
        width: 100%
    }
}

.st-sheet-grid-col {
    margin: 0 0 12px 0
}

.st-sheet-grid-col:last-child {
    margin: 0
}

@media (min-width:375px) {
    .st-sheet-grid-col {
        margin: 0 0 23px 0
    }
}

@media (min-width:768px) {
    .st-sheet-grid-col {
        margin: 0 0 68px 0
    }
}

@media (min-width:1024px) {
    .st-sheet-grid-col {
        margin: 0
    }
}

@media (min-width:1024px) {
    .st-sheet-grid-col.-left {
        width: 48%
    }
}

@media (min-width:1024px) {
    .st-sheet-grid-col.-right {
        width: 52%
    }
}

@media (min-width:768px) {
    .st-sheet-grid-col.-xs {
        margin: 0 0 18px 0
    }
}

@media (min-width:1024px) {
    .st-sheet-grid-col.-xs {
        margin: 0
    }
}

.st-sheet-body {
    padding: 0 23px
}

@media (min-width:768px) {
    .st-sheet-body {
        padding: 0 31px
    }
}

@media (min-width:1024px) {
    .st-sheet-body {
        max-width: 462px;
        padding: 0
    }
}

@media (min-width:1440px) {
    .st-sheet-body {
        max-width: 415px
    }
}

.st-sheet-title {
    margin: 0 0 20px 0;
}

@media (min-width:1024px) {
    .st-sheet-title {
        margin: 0 0 23px 0
    }
}

.st-sheet-title h2 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    letter-spacing: -.29px;
    line-height: 34px
}

@media (min-width:1024px) {
    .st-sheet-title h2 {
        font-size: 32px;
        letter-spacing: -.38px;
        line-height: 52px
    }
}

.st-sheet-description {
    font-size: 14px;
    letter-spacing: .24px;
    line-height: 30px
}

.st-privacy-description{
    font-size: 15px;
    letter-spacing: .3px;
    line-height: 34px;
}

.st-privacy-subtitle{
    font-size: 15px;
    letter-spacing: -.37px;
    line-height: 32px
}

@media (min-width:1024px) {
    .st-sheet-description {
        font-size: 16px;
        letter-spacing: .3px;
        line-height: 34px
    }
}

.st-sheet-image {
    max-width: 210px;
    margin: 0 auto;
    text-align: center
}

@media (min-width:1024px) {
    .st-sheet-image {
        max-width: 300px
    }
}

@media (min-width:1280px) {
    .st-sheet-image {
        max-width: 310px
    }
}

@media (min-width:1440px) {
    .st-sheet-image {
        max-width: 330px
    }
}

.st-sheet-image img:not(.-small) {
    width: 100%
}

.st-sheet-image img.-small {
    width: 80%
}

@media (min-width:1024px) {
    .st-sheet-image img {
        width: auto
    }
}

.st-sheet-figure {
    display: none;
    position: absolute;
    z-index: 2
}

@media (min-width:768px) {
    .st-sheet-figure {
        display: block
    }
}

.st-sheet-figure:nth-child(1) {
    top: -60px;
    right: -60px
}

@media (min-width:1024px) {
    .st-sheet-figure:nth-child(1) {
        top: -60px;
        right: -40px
    }
}

@media (min-width:1280px) {
    .st-sheet-figure:nth-child(1) {
        top: -60px;
        right: 0
    }
}

.st-sheet-figure:nth-child(2) {
    left: -80px;
    bottom: -100px
}

@media (min-width:1024px) {
    .st-sheet-figure:nth-child(2) {
        left: -80px;
        bottom: 0
    }
}

.st-brand {
    background: #fbfcfc
}

.st-brand-wrap {
    padding: 50px 0
}

@media (min-width:375px) {
    .st-brand-wrap {
        padding: 50px 0 70px 0
    }
}

@media (min-width:768px) {
    .st-brand-wrap {
        padding: 0 0 50px 0
    }
}

@media (min-width:1024px) {
    .st-brand-wrap {
        padding: 0 0 60px 0
    }
}

@media (min-width:1280px) {
    .st-brand-wrap {
        padding: 0 0 100px 0
    }
}

@media (min-width:1440px) {
    .st-brand-wrap {
        padding: 45px 0 150px 0
    }
}

.st-brand-container {
    max-width: 691px;
    margin: 0 auto;
    padding: 0 12px
}

@media (min-width:375px) {
    .st-brand-container {
        padding: 0 19px
    }
}

@media (min-width:768px) {
    .st-brand-container {
        padding: 0
    }
}

.st-brand-title {
    margin: 0 0 20px 0;
    font-size: 20px;
    letter-spacing: -.24px;
    line-height: 34px
}

@media (min-width:375px) {
    .st-brand-title {
        font-size: 20px;
        letter-spacing: -.24px
    }
}

@media (min-width:768px) {
    .st-brand-title {
        text-align: center;
        line-height: 52px
    }
}

@media (min-width:1024px) {
    .st-brand-title {
        font-size: 24px;
        letter-spacing: -.29px
    }
}

@media (min-width:1280px) {
    .st-brand-title {
        margin: 0 0 30px 0;
        font-size: 26px;
        letter-spacing: -.31px
    }
}

@media (min-width:1440px) {
    .st-brand-title {
        margin: 0 0 60px 0
    }
}

@media (min-width:768px) {
    .st-brand-items {
        text-align: center
    }
}

.st-brand-items-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 20px 20px 0
}

@media (min-width:768px) {
    .st-brand-items-item {
        margin: 0 40px 0 0
    }
}

@media (min-width:1024px) {
    .st-brand-items-item {
        margin: 0 50px 0 0
    }
}

@media (min-width:1280px) {
    .st-brand-items-item {
        margin: 0 80px 0 0
    }
}

.st-brand-items-item:last-child {
    margin: 0
}

.st-brand-items-item img {
    max-width: 75%
}

@media (min-width:1024px) {
    .st-brand-items-item img {
        max-width: 90%
    }
}

@media (min-width:1280px) {
    .st-brand-items-item img {
        max-width: 100%
    }
}

.st-brand.-center {
    text-align: center
}

.st-review {
    position: relative
}

.st-review-fill {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: 75% center;
    background-repeat: no-repeat
}

.st-review-wrap {
    position: relative;
    padding: 220px 0 0 0
}

@media (min-width:375px) {
    .st-review-wrap {
        padding: 235px 0 0 0
    }
}

@media (min-width:768px) {
    .st-review-wrap {
        padding: 220px 0 0 0
    }
}

@media (min-width:1024px) {
    .st-review-wrap {
        padding: 260px 0 0 0
    }
}

@media (min-width:1280px) {
    .st-review-wrap {
        padding: 290px 0 0 0
    }
}

.st-review-container {
    position: relative;
    padding: 0 19px
}

@media (min-width:375px) {
    .st-review-container {
        padding: 0 35px
    }
}

@media (min-width:768px) {
    .st-review-container {
        padding: 0 56px
    }
}

@media (min-width:1024px) {
    .st-review-container {
        padding: 0 54px
    }
}

@media (min-width:1280px) {
    .st-review-container {
        padding: 0 62px
    }
}

@media (min-width:1440px) {
    .st-review-container {
        width: 1200px;
        margin: 0 auto;
        padding: 0
    }
}

.st-review-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

@media (min-width:768px) {
    .st-review-grid {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

@media (min-width:1280px) {
    .st-review-grid-col {
        padding: 0 0 0 40px
    }
}

@media (min-width:768px) {
    .st-review-grid-col.-left {
        width: 320px
    }
}

@media (min-width:1024px) {
    .st-review-grid-col.-left {
        width: 360px
    }
}

@media (min-width:1280px) {
    .st-review-grid-col.-left {
        width: 400px
    }
}

@media (min-width:1440px) {
    .st-review-grid-col.-left {
        width: 420px
    }
}

.st-review-grid-col.-right {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.st-review-quote {
    max-width: 46px;
    position: absolute;
    left: -7px;
    top: 310px
}

@media (min-width:768px) {
    .st-review-quote {
        position: relative;
        left: 320px;
        top: 0;
        max-width: 100%
    }
}

@media (min-width:1024px) {
    .st-review-quote {
        left: 360px
    }
}

@media (min-width:1280px) {
    .st-review-quote {
        left: 440px
    }
}

@media (min-width:1440px) {
    .st-review-quote {
        left: 460px
    }
}

.st-review-quote img {
    max-width: 100%
}

.st-review-nav {
    display: none
}

@media (min-width:1024px) {
    .st-review-nav {
        display: block
    }
}

.st-review-nav-item {
    position: absolute;
    top: 45%;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    z-index: 3
}

.st-review-nav-item button {
    width: 60px;
    height: 60px;
    font-size: 20px;
    line-height: 1;
    background: 0 0;
    border: none
}

.st-review-nav-item button:hover .st-review-nav-item-fill {
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
    transform: scale(.9)
}

.st-review-nav-item-fill {
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #f1f3f4;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -webkit-transition: -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    -o-transition: -o-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    -moz-transition: transform .25s cubic-bezier(.25, .25, .5, 1.9), -moz-transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: transform .25s cubic-bezier(.25, .25, .5, 1.9);
    transition: transform .25s cubic-bezier(.25, .25, .5, 1.9), -webkit-transform .25s cubic-bezier(.25, .25, .5, 1.9), -moz-transform .25s cubic-bezier(.25, .25, .5, 1.9), -o-transform .25s cubic-bezier(.25, .25, .5, 1.9)
}

.st-review-nav-item.-prev {
    left: 0
}

@media (min-width:1024px) {
    .st-review-nav-item.-prev {
        left: 30px
    }
}

@media (min-width:1280px) {
    .st-review-nav-item.-prev {
        left: 60px
    }
}

@media (min-width:1440px) {
    .st-review-nav-item.-prev {
        left: 0
    }
}

.st-review-nav-item.-next {
    right: 0
}

@media (min-width:1024px) {
    .st-review-nav-item.-next {
        right: 30px
    }
}

@media (min-width:1280px) {
    .st-review-nav-item.-next {
        right: 60px
    }
}

@media (min-width:1440px) {
    .st-review-nav-item.-next {
        right: 0
    }
}

.st-review-dots {
    margin: 10px 0 0 0
}

@media (min-width:768px) {
    .st-review-dots {
        position: absolute;
        bottom: 80px;
        left: 375px;
        z-index: 2;
        margin: 0
    }
}

@media (min-width:1024px) {
    .st-review-dots {
        left: 475px;
        bottom: 122px
    }
}

@media (min-width:1280px) {
    .st-review-dots {
        left: 565px
    }
}

@media (min-width:1440px) {
    .st-review-dots {
        left: 519px
    }
}

.st-review-dots button {
    display: inline-block;
    vertical-align: middle;
    width: 4px;
    height: 4px;
    padding: 0;
    margin: 0 20px 0 0;
    border: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: rgba(65, 77, 91, .3)
}

.st-review-dots button.active {
    width: 8px;
    height: 8px;
    background: #0097ff
}

@media (min-width:1024px) {
    .st-review-body {
        padding: 0 60px
    }
}

.st-review-item {
    padding: 40px 0 20px 0
}

@media (min-width:768px) {
    .st-review-item {
        min-height: 452px
    }
}

@media (min-width:1024px) {
    .st-review-item {
        min-height: 480px
    }
}

.st-review-bg {
    position: relative;
    width: 300px;
    height: 309px
}

@media (min-width:768px) {
    .st-review-bg {
        width: 370px;
        height: 379px
    }
}

@media (min-width:1024px) {
    .st-review-bg {
        width: 410px;
        height: 419px
    }
}

@media (min-width:1280px) {
    .st-review-bg {
        width: 440px;
        height: 449px
    }
}

.st-review-bg-fill {
    position: absolute;
    width: 100%;
    height: 100%
}

.st-review-bg-fill svg {
    width: 100%;
    height: 100%
}

.st-review-bg-figure {
    position: absolute;
    left: -12px;
    bottom: -65px;
    z-index: 2;
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
    transform: scale(.9)
}

@media (min-width:375px) {
    .st-review-bg-figure {
        left: -12px;
        bottom: -75px
    }
}

@media (min-width:768px) {
    .st-review-bg-figure {
        left: 0;
        bottom: -55px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1024px) {
    .st-review-bg-figure {
        bottom: -45px
    }
}

@media (min-width:1280px) {
    .st-review-bg-figure {
        bottom: -35px
    }
}

.st-review-bg-figure img {
    width: 100%
}

.st-review-bg-photo {
    position: absolute;
    top: -20px;
    left: 120px;
    z-index: 2
}

@media (min-width:768px) {
    .st-review-bg-photo {
        top: -30px;
        left: 145px
    }
}

@media (min-width:1024px) {
    .st-review-bg-photo {
        top: -14px;
        left: 185px
    }
}

.st-review-bg-photo svg {
    width: 120px;
    height: 115px;
    -webkit-transform: scale(.79);
    -moz-transform: scale(.79);
    -ms-transform: scale(.79);
    -o-transform: scale(.79);
    transform: scale(.79)
}

@media (min-width:768px) {
    .st-review-bg-photo svg {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.st-review-brief {
    position: absolute;
    top: 70px;
    left: 65px;
    z-index: 1;
    max-width: 100px;
    color: #fff
}

@media (min-width:375px) {
    .st-review-brief {
        top: 67px;
        left: 53px
    }
}

@media (min-width:768px) {
    .st-review-brief {
        top: 90px;
        left: 75px;
        max-width: 152px
    }
}

@media (min-width:1024px) {
    .st-review-brief {
        top: 95px;
        left: 85px
    }
}

@media (min-width:1280px) {
    .st-review-brief {
        top: 110px;
        left: 90px
    }
}

.st-review-brief-title {
    margin: 0 0 12px 0;
    font-size: 38px;
    letter-spacing: -.46px;
    line-height: 40px;
    font-weight: 700
}

@media (min-width:768px) {
    .st-review-brief-title {
        margin: 0 0 19px 0;
        font-size: 48px;
        letter-spacing: -.58px;
        line-height: 40px
    }
}

@media (min-width:1024px) {
    .st-review-brief-title {
        margin: 0 0 23px 0;
        font-size: 56px;
        letter-spacing: -.67px;
        line-height: 40px
    }
}

.st-review-brief-subtitle {
    font-size: 16px;
    letter-spacing: -.19px;
    line-height: 24px
}

@media (min-width:768px) {
    .st-review-brief-subtitle {
        font-size: 18px;
        letter-spacing: -.22px;
        line-height: 30px
    }
}

@media (min-width:1024px) {
    .st-review-brief-subtitle {
        font-size: 24px;
        letter-spacing: -.29px;
        line-height: 32px
    }
}

.st-review-text {
    margin: 0 0 10px 0;
    font-size: 14px;
    letter-spacing: -.27px;
    line-height: 34px
}

@media (min-width:768px) {
    .st-review-text {
        margin: 20px 0
    }
}

@media (min-width:1024px) {
    .st-review-text {
        font-size: 16px;
        letter-spacing: -.27px;
        line-height: 34px
    }
}

@media (min-width:1280px) {
    .st-review-text {
        padding: 0 89px 0 0;
        font-size: 18px;
        letter-spacing: -.3px;
        line-height: 40px
    }
}

@media (min-width:1440px) {
    .st-review-text {
        padding: 0 100px 0 0
    }
}

.st-review-author {
    font-size: 14px;
    letter-spacing: .44px;
    line-height: 34px;
    font-weight: 600;
    color: rgba(65, 77, 91, .4)
}

@media (min-width:1024px) {
    .st-review-author {
        font-size: 16px;
        letter-spacing: .5px;
        line-height: 34px
    }
}

.st-review-author span {
    font-weight: 400
}

.st-review.-top {
    margin-top: -120px
}

@media (min-width:768px) {
    .st-review.-top {
        margin-top: -60px
    }
}

.st-action-wrap {
    padding: 0 0 50px 0
}

@media (min-width:375px) {
    .st-action-wrap {
        padding: 0 0 60px 0
    }
}

@media (min-width:1024px) {
    .st-action-wrap {
        padding: 0 0 80px 0
    }
}

@media (min-width:1280px) {
    .st-action-wrap {
        padding: 0 0 130px 0
    }
}

@media (min-width:1440px) {
    .st-action-wrap {
        padding: 0 0 160px 0
    }
}

.st-action-box {
    position: relative;
    background: #0097ff;
    background: -moz-linear-gradient(left, #0097ff 0, #5163de 100%);
    background: -webkit-linear-gradient(left, #0097ff 0, #5163de 100%);
    background: -webkit-gradient(linear, left top, right top, from(#0097ff), to(#5163de));
    background: -o-linear-gradient(left, #0097ff 0, #5163de 100%);
    background: linear-gradient(to right, #0097ff 0, #5163de 100%);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-shadow: 20px 32px 45px rgba(76, 85, 166, .25);
    -moz-box-shadow: 20px 32px 45px rgba(76, 85, 166, .25);
    box-shadow: 20px 32px 45px rgba(76, 85, 166, .25)
}
/*addition*/
.st-action-button{
   margin: 0 0 20px 0;
}

.st-action-box-wrap {
    padding: 30px 0 40px 0
}

@media (min-width:375px) {
    .st-action-box-wrap {
        padding: 35px 0 45px 0
    }
}

@media (min-width:1024px) {
    .st-action-box-wrap {
        padding: 35px 0 40px 0
    }
}

@media (min-width:1280px) {
    .st-action-box-wrap {
        padding: 37px 0
    }
}

.st-action-box-container {
    padding: 0 24px
}

@media (min-width:375px) {
    .st-action-box-container {
        padding: 0 37px
    }
}

@media (min-width:768px) {
    .st-action-box-container {
        padding: 0 72px
    }
}

@media (min-width:1024px) {
    .st-action-box-container {
        padding: 0 46px
    }
}

@media (min-width:1280px) {
    .st-action-box-container {
        padding: 0 52px
    }
}

@media (min-width:1440px) {
    .st-action-box-container {
        padding: 0 103px
    }
}

.st-action-grid {
    width: 100%
}

@media (min-width:1024px) {
    .st-action-grid {
        display: table
    }
}

@media (min-width:768px) {
    .st-action-grid-col {
        display: inline-block;
        vertical-align: bottom
    }
}

@media (min-width:1024px) {
    .st-action-grid-col {
        display: table-cell;
        vertical-align: bottom
    }
}

.st-action-grid-col.-left {
    width: 100%
}

@media (min-width:1024px) {
    .st-action-grid-col.-left {
        width: 267px
    }
}

@media (min-width:1280px) {
    .st-action-grid-col.-left {
        width: 290px
    }
}

.st-action-grid-col.-right {
    text-align: right;
    width: 208px
}

.st-action-title {
    margin: 0 0 36px 0;
    font-size: 20px;
    letter-spacing: -.24px;
    line-height: 36px;
    font-weight: 700;
    color: #fff
}

@media (min-width:375px) {
    .st-action-title {
        margin: 0 0 38px 0
    }
}

@media (min-width:768px) {
    .st-action-title {
        margin: 0 0 10px 0!important;
    }
}

@media (min-width:1024px) {
    .st-action-title {
        margin: 0;
        font-size: 24px;
        letter-spacing: -.29px;
        line-height: 40px
    }
}

@media (min-width:1280px) {
    .st-action-title {
        font-size: 26px;
        letter-spacing: -.31px;
        line-height: 40px
    }
}

.st-action-input {
    margin: 0 0 25px 0
}

@media (min-width:768px) {
    .st-action-input {
        width: 278px;
        padding: 0 25px 0 0;
        margin: 0
    }
}

@media (min-width:1024px) {
    .st-action-input {
        width: 100%;
        padding: 25px 5px 0 60px
    }
}

@media (min-width:1280px) {
    .st-action-input {
        padding: 25px 20px 0 141px
    }
}

@media (min-width:1440px) {
    .st-action-input {
        padding: 25px 20px 0 120px
    }
}

.st-action.-inverse {
    background: #fbfcfc
}

.st-social-title {
    margin: 0 0 21px 0;
    font-size: 14px;
    letter-spacing: .02px;
    line-height: 19px
}

@media (min-width:1024px) {
    .st-social-title {
        display: inline-block;
        vertical-align: middle;
        margin: 0 25px 0 0
    }
}

@media (min-width:1440px) {
    .st-social-title {
        display: block;
        margin: 0 0 29px 0
    }
}

@media (min-width:1024px) {
    .st-social-items {
        display: inline-block;
        vertical-align: middle
    }
}

@media (min-width:1440px) {
    .st-social-items {
        display: block
    }
}

.st-social-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 14px;
    width: 52px;
    height: 52px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background: rgba(65, 77, 91, .05);
    font-size: 14px;
    letter-spacing: .17px;
    line-height: 52px;
    text-align: center;
    overflow: hidden
}

@media (min-width:1440px) {
    .st-social-item {
        width: 124px;
        height: 52px;
        padding: 0 0 0 16px;
        text-align: left
    }
}

.st-social-item:first-child {
    margin: 0
}

.st-social-item:focus,
.st-social-item:hover {
    background: rgba(65, 77, 91, .08);
    text-decoration: none
}

.st-social-item:hover>span {
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg)
}

.st-social-item:hover>span>span {
    opacity: 1;
    -webkit-transition-delay: 50ms;
    -moz-transition-delay: 50ms;
    -o-transition-delay: 50ms;
    transition-delay: 50ms;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    transform: rotateX(-90deg)
}

@media (min-width:1440px) {
    .st-social-item span {
        margin: 0 10px 0 0
    }
}

.st-social-item span:last-child {
    margin: 0
}

.st-social-item>span {
    display: inline-block;
    position: relative;
    -webkit-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.st-social-item>span>span {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.st-social-item-icon {
    display: inline-block;
    vertical-align: middle;
    color: #0097ff
}

.st-social-item-icon.-facebook {
    font-size: 17px
}

.st-social-item-icon.-google {
    font-size: 17px
}

.st-social-item-icon.-github {
    font-size: 18px
}

.st-social-item-text {
    display: none
}

@media (min-width:1440px) {
    .st-social-item-text {
        display: inline-block;
        vertical-align: middle
    }
}

.st-feature {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%
}

.st-feature:after,
.st-feature:before {
    content: " ";
    display: table
}

.st-feature:after {
    clear: both
}

.st-feature-wrap {
    width: 100%;
    padding: 80px 0 40px 0
}

@media (min-width:768px) {
    .st-feature-wrap {
        padding: 80px 0 68px 0
    }
}

@media (min-width:1024px) {
    .st-feature-wrap {
        padding: 85px 0 76px 0
    }
}

@media (min-width:1280px) {
    .st-feature-wrap {
        padding: 65px 0 154px 0
    }
}

@media (min-width:1440px) {
    .st-feature-wrap {
        padding: 121px 0 141px 0
    }
}

.st-feature-wrap.-xs {
    padding: 0 0 161px 0
}

.st-feature-wrap.-sm {
    padding: 0 0 50px 0
}

@media (min-width:375px) {
    .st-feature-wrap.-sm {
        padding: 0 0 70px 0
    }
}

@media (min-width:768px) {
    .st-feature-wrap.-sm {
        padding: 50px 0 60px 0
    }
}

@media (min-width:1024px) {
    .st-feature-wrap.-sm {
        padding: 30px 0 80px 0
    }
}

@media (min-width:1280px) {
    .st-feature-wrap.-sm {
        padding: 70px 0 110px 0
    }
}

@media (min-width:1440px) {
    .st-feature-wrap.-sm {
        padding: 52px 0 134px 0
    }
}

.st-feature-figure {
    position: absolute;
    z-index: 2
}

.st-feature-figure.-one {
    bottom: -20px;
    right: -75px
}

@media (min-width:375px) {
    .st-feature-figure.-one {
        bottom: -65px;
        right: -85px
    }
}

@media (min-width:768px) {
    .st-feature-figure.-one {
        bottom: -40px;
        right: -80px;
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@media (min-width:1024px) {
    .st-feature-figure.-one {
        bottom: 10px;
        right: -100px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@media (min-width:1280px) {
    .st-feature-figure.-one {
        bottom: 30px;
        right: -110px
    }
}

@media (min-width:1440px) {
    .st-feature-figure.-one {
        bottom: 30px;
        right: -110px
    }
}

.st-feature-figure.-two {
    bottom: -55px;
    right: -45px;
    -webkit-transform: scale(.7);
    -moz-transform: scale(.7);
    -ms-transform: scale(.7);
    -o-transform: scale(.7);
    transform: scale(.7)
}

@media (min-width:375px) {
    .st-feature-figure.-two {
        bottom: -65px;
        right: -45px
    }
}

@media (min-width:768px) {
    .st-feature-figure.-two {
        bottom: -50px;
        right: -90px
    }
}

@media (min-width:1024px) {
    .st-feature-figure.-two {
        bottom: -25px;
        right: -90px;
        -webkit-transform: scale(.9);
        -moz-transform: scale(.9);
        -ms-transform: scale(.9);
        -o-transform: scale(.9);
        transform: scale(.9)
    }
}

@media (min-width:1280px) {
    .st-feature-figure.-two {
        bottom: -30px;
        right: -85px
    }
}

@media (min-width:1440px) {
    .st-feature-figure.-two {
        bottom: -30px;
        right: -85px
    }
}

@media (min-width:768px) {
    .st-feature-body {
        max-width: 472px
    }
}

@media (min-width:768px) {
    .st-feature-body.-lg {
        max-width: 576px
    }
}

.st-feature-body ol {
    padding: 0 0 0 20px;
    margin: 10px 0 0 0;
    font-size: 16px;
    letter-spacing: .5px;
    line-height: 34px
}

@media (min-width:768px) {
    .st-feature-body ol {
        margin: 60px 0 0 0;
        font-size: 16px
    }
}

.st-feature-body ol li {
    padding: 0 0 0 28px;
    margin: 0 0 25px 0
}

.st-feature-body ol li:last-child {
    margin: 0
}

.st-feature-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%
}

@media (min-width:768px) {
    .st-feature-grid {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

@media (min-width:1440px) {
    .st-feature-grid {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.st-feature-grid-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 55px 0;
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    perspective: 1200px
}

.st-feature-grid-col:last-child {
    margin: 0
}

@media (min-width:768px) {
    .st-feature-grid-col {
        width: 50%;
        margin: 0
    }
}

.st-feature-grid-col.-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

@media (min-width:768px) {
    .st-feature-grid-col.-right {
        padding: 0 0 0 10px
    }
}

@media (min-width:1024px) {
    .st-feature-grid-col.-right {
        padding: 0
    }
}

.st-feature-grid-col.-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

@media (min-width:1440px) {
    .st-feature-grid-col.-xl-left {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media (min-width:1280px) {
    .st-feature-grid-col.-p-right {
        padding: 0 20px 0 0
    }
}

@media (min-width:1440px) {
    .st-feature-grid-col.-p-right {
        padding: 0 30px 0 0
    }
}

@media (min-width:768px) {
    .st-feature-grid-col.-p-left {
        padding: 0 20px
    }
}

@media (min-width:1024px) {
    .st-feature-grid-col.-p-left {
        padding: 0 0 0 40px
    }
}

@media (min-width:1440px) {
    .st-feature-grid-col.-p-left {
        padding: 0 0 0 80px
    }
}

@media (min-width:1280px) {
    .st-feature-grid-col.-p-left-lg {
        padding: 0 0 0 40px
    }
}

@media (min-width:1440px) {
    .st-feature-grid-col.-p-left-lg {
        padding: 0 0 0 90px
    }
}

.st-feature-grid-col.-start {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (min-width:768px) {
    .st-feature-grid-col.-start {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media (min-width:1280px) {
    .st-feature-grid-col.-start {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.st-feature-grid-col.-end {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (min-width:768px) {
    .st-feature-grid-col.-end {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }
}

@media (min-width:1280px) {
    .st-feature-grid-col.-end {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media (min-width:1280px) {
    .st-feature-grid.-indent {
        padding: 0 52px
    }
}

@media (min-width:1440px) {
    .st-feature-grid.-indent {
        padding: 0
    }
}

@media (min-width:1024px) {
    .st-feature-grid.-top {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

.st-feature-grid.-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.st-feature-grid.-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -moz-box-orient: vertical;
    -moz-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
}

@media (min-width:768px) {
    .st-feature-grid.-reverse {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.st-feature-title {
    margin: 0 0 20px 0
}

@media (min-width:768px) {
    .st-feature-title {
        margin: 0 0 30px 0
    }
}

@media (min-width:1280px) {
    .st-feature-title {
        margin: 0 0 40px 0
    }
}

.st-feature-title h2 {
    margin: 0;
    padding: 0;
    font-size: 28px;
    letter-spacing: -.34px;
    line-height: 44px
}

@media (min-width:768px) {
    .st-feature-title h2 {
        font-size: 32px;
        letter-spacing: -.38px;
        line-height: 52px
    }
}

.st-feature-description {
    font-size: 16px;
    letter-spacing: .5px;
    line-height: 34px
}

@media (min-width:768px) {
    .st-feature-description {
        font-size: 16px
    }
}

.st-feature-description p {
    margin: 0 0 30px 0
}

.st-feature-description p:last-child {
    margin: 0
}

.st-feature-description p.-xs {
    margin: 0 0 45px 0
}

.st-feature-brief {
    max-width: 510px;
    font-size: 22px;
    letter-spacing: -.26px;
    line-height: 34px
}

@media (min-width:768px) {
    .st-feature-brief {
        font-size: 24px;
        letter-spacing: -.29px;
        line-height: 34px
    }
}

@media (min-width:1024px) {
    .st-feature-brief {
        font-size: 26px;
        letter-spacing: -.31px;
        line-height: 44px
    }
}

.st-feature-image {
    position: relative;
    max-width: 330px;
    text-align: center;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0;
    -moz-transform-origin: 0;
    -ms-transform-origin: 0;
    -o-transform-origin: 0;
    transform-origin: 0;
    will-change: transform
}

@media (min-width:768px) {
    .st-feature-image {
        max-width: 100%
    }
}

.st-feature-image img {
    width: 85%
}

@media (min-width:768px) {
    .st-feature-image img {
        width: 100%
    }
}

.st-feature-image.-xs {
    max-width: 231px
}

@media (min-width:768px) {
    .st-feature-image.-xs {
        max-width: 325px
    }
}

@media (min-width:1024px) {
    .st-feature-image.-xs {
        max-width: 440px
    }
}

@media (min-width:1280px) {
    .st-feature-image.-xs {
        max-width: 520px
    }
}

@media (min-width:1440px) {
    .st-feature-image.-xs {
        max-width: 573px
    }
}

.st-feature-image.-sm {
    max-width: 231px
}

@media (min-width:768px) {
    .st-feature-image.-sm {
        max-width: 258px
    }
}

@media (min-width:1024px) {
    .st-feature-image.-sm {
        max-width: 297px
    }
}

@media (min-width:1280px) {
    .st-feature-image.-sm {
        max-width: 383px
    }
}

.st-feature-image.-md {
    max-width: 231px
}

@media (min-width:768px) {
    .st-feature-image.-md {
        max-width: 258px
    }
}

@media (min-width:1024px) {
    .st-feature-image.-md {
        max-width: 297px
    }
}

@media (min-width:1280px) {
    .st-feature-image.-md {
        max-width: 445px
    }
}

.st-feature-image.-lg {
    max-width: 260px
}

@media (min-width:768px) {
    .st-feature-image.-lg {
        max-width: 320px
    }
}

@media (min-width:1024px) {
    .st-feature-image.-lg {
        max-width: 390px
    }
}

@media (min-width:1280px) {
    .st-feature-image.-lg {
        max-width: 460px
    }
}

@media (min-width:1440px) {
    .st-feature-image.-lg {
        max-width: 555px
    }
}

.st-footer {
    background: #fff;
}

.st-footer-wrap {
    padding: 0 0 40px 0
}

@media (min-width:768px) {
    .st-footer-wrap {
        padding: 0 0 50px 0
    }
}

@media (min-width:1024px) {
    .st-footer-wrap {
        padding: 0 0 80px 0
    }
}

@media (min-width:1280px) {
    .st-footer-wrap {
        padding: 0 0 90px 0
    }
}

@media (min-width:1440px) {
    .st-footer-wrap {
        padding: 0 0 100px 0
    }
}

.st-footer-grid {
    width: 100%
}

@media (max-width:1023px) {
    .st-footer-grid:after,
    .st-footer-grid:before {
        content: " ";
        display: table
    }
    .st-footer-grid:after {
        clear: both
    }
}

@media (min-width:1024px) {
    .st-footer-grid {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

@media (min-width:1024px) {
    .st-footer-grid-col.-left {
        float: left
    }
}

.st-footer-grid-col.-center {
    max-height: 201px
}

@media (max-width:550px) {
    .st-footer-grid-col.-right {
        display: inline-block;
        vertical-align: top;
        float: left;
        max-height: 181px;
        margin-top: 170px!important;
    }
}

@media (min-width:768px) {
    .st-footer-grid-col.-center {
        display: inline-block;
        vertical-align: top;
        float: left;
        max-height: 181px!important;
    }
}

@media (min-width:1024px) {
    .st-footer-grid-col.-center {
        display: block;
        float: none;
        max-height: 100%
    }
}

@media (min-width:768px) {
    .st-footer-grid-col.-right {
        display: inline-block;
        vertical-align: top;
        float: right
    }
}

@media (min-width:1024px) {
    .st-footer-grid-col.-right {
        display: block;
        float: none
    }
}

.st-footer-row {
    padding: 0 0 40px 0
}

@media (min-width:768px) {
    .st-footer-row {
        padding: 0 0 50px 0
    }
}

@media (min-width:1024px) {
    .st-footer-row {
        padding: 0 0 80px 0
    }
}

@media (min-width:1280px) {
    .st-footer-row {
        padding: 0 0 90px 0
    }
}

@media (min-width:1440px) {
    .st-footer-row {
        padding: 0 0 100px 0
    }
}

.st-footer-row:last-child {
    padding: 0
}

.st-footer-logo {
    display: block;
    max-width: 107px;
    margin: 0 0 30px 0
}

@media (min-width:1024px) {
    .st-footer-logo {
        margin: 0
    }
}

@media (min-width:1440px) {
    .st-footer-logo {
        max-width: 100%
    }
}

.st-footer-logo img {
    width: 100%
}

.st-footer-items {
    display: inline-block;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    margin: -30px 0 20px 0;
    -webkit-column-gap: 65px;
    -moz-column-gap: 65px;
    column-gap: 65px
}

@media (min-width:1024px) {
    .st-footer-items {
        display: block;
        margin: -25px 0 0 40px;
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 55px;
        -moz-column-gap: 55px;
        column-gap: 55px
    }
}

@media (min-width:1280px) {
    .st-footer-items {
        -webkit-column-gap: 96px;
        -moz-column-gap: 96px;
        column-gap: 96px
    }
}

@media (min-width:1440px) {
    .st-footer-items {
        -webkit-column-gap: 126px;
        -moz-column-gap: 126px;
        column-gap: 126px
    }
}

.st-footer-items-item {
    display: block;
    padding: 30px 0 0 0;
    font-size: 14px;
    letter-spacing: .02px;
    line-height: 19px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

@media (min-width:1024px) {
    .st-footer-items-item {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px
    }
}

.st-footer-items-item:last-child {
    margin: 0
}

.st-footer-items-item a {
    display: block;
    text-decoration: none
}

.st-footer-items-item a:focus,
.st-footer-items-item a:hover {
    opacity: .8;
    text-decoration: none
}

.st-footer-action {
    width: 100%;
    max-width: 305px
}

@media (min-width:768px) {
    .st-footer-action {
        width: 305px
    }
}

@media (min-width:1024px) {
    .st-footer-action {
        width: 248px
    }
}

@media (min-width:1440px) {
    .st-footer-action {
        width: 268px
    }
}

.st-footer-action-title {
    margin: 0 0 2px 0;
    font-size: 24px;
    letter-spacing: -.29px;
    line-height: 36px
}

.st-footer-action-form {
    margin: 0 0 24px 0
}

.st-footer-action-description {
    font-size: 16px;
    letter-spacing: .02px;
    line-height: 22px
}

@media (min-width:768px) {
    .st-footer-copyright {
        text-align: center
    }
}

.st-sitemap-wrap {
    padding: 40px
}

.st-sitemap-item {
    margin: 0 0 20px 0
}

.st-sitemap-title {
    margin: 0 0 15px 0;
    font-size: 16px;
    font-weight: 600
}

.st-sitemap-links {
    padding: 0 0 0 40px;
    color: #00f;
    opacity: .5
}

.st-sitemap-links a {
    display: block;
    margin: 0 0 5px 0
}

.st-price {
    height: 100%
}

.st-price-fill {
    position: absolute;
    top: -769px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1124px;
    z-index: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: 89% 0;
    background-repeat: no-repeat
}

@media (min-width:768px) {
    .st-price-fill {
        top: -640px;
        background-position: 71% 0
    }
}

@media (min-width:1024px) {
    .st-price-fill {
        top: -620px;
        background-position: 90% 0
    }
}

@media (min-width:1280px) {
    .st-price-fill {
        top: -563px;
        background-position: 100% 0
    }
}

.st-price-figurs {
    position: relative;
    height: 100%
}

.st-price-figure {
    position: absolute
}

.st-price-figure:nth-child(1) {
    display: none
}

@media (min-width:1024px) {
    .st-price-figure:nth-child(1) {
        display: block;
        top: 300px;
        left: -215px
    }
}

@media (min-width:1280px) {
    .st-price-figure:nth-child(1) {
        top: 300px;
        left: -215px
    }
}

@media (min-width:1440px) {
    .st-price-figure:nth-child(1) {
        top: 300px;
        left: -215px
    }
}

.st-price-figure:nth-child(2) {
    left: -55px;
    top: 242px;
    z-index: 0
}

@media (min-width:375px) {
    .st-price-figure:nth-child(2) {
        left: -55px;
        top: 200px;
        z-index: 1
    }
}

@media (min-width:768px) {
    .st-price-figure:nth-child(2) {
        left: -55px;
        top: 242px;
        z-index: 1
    }
}

@media (min-width:1024px) {
    .st-price-figure:nth-child(2) {
        left: 190px;
        top: 108px;
        z-index: 1
    }
}

@media (min-width:1280px) {
    .st-price-figure:nth-child(2) {
        left: 240px;
        top: 108px;
        z-index: 1
    }
}

@media (min-width:1440px) {
    .st-price-figure:nth-child(2) {
        left: 240px;
        top: 108px;
        z-index: 1
    }
}

.st-price-figure:nth-child(3) {
    top: 530px;
    right: -180px
}

@media (min-width:768px) {
    .st-price-figure:nth-child(3) {
        top: 530px;
        right: -180px
    }
}

@media (min-width:1024px) {
    .st-price-figure:nth-child(3) {
        top: 325px;
        right: -180px
    }
}

@media (min-width:1280px) {
    .st-price-figure:nth-child(3) {
        top: 325px;
        right: -180px
    }
}

@media (min-width:1440px) {
    .st-price-figure:nth-child(3) {
        top: 325px;
        right: -180px
    }
}

.st-price-wrap {
    padding: 100px 0 120px
}

@media (min-width:768px) {
    .st-price-wrap {
        padding: 130px 0 120px
    }
}

@media (min-width:1024px) {
    .st-price-wrap {
        padding: 140px 0 120px
    }
}

.st-price-title {
    text-align: center;
    margin-bottom: 30px
}

@media (min-width:1024px) {
    .st-price-title {
        max-width: 170px;
        text-align: left;
        margin-bottom: 0
    }
}

.st-price-title h1 {
    font-size: 24px;
    line-height: 52px;
    letter-spacing: -.43px
}

@media (min-width:768px) {
    .st-price-title h1 {
        font-size: 32px
    }
}

@media (min-width:1280px) {
    .st-price-title h1 {
        font-size: 36px
    }
}

.st-price-table {
    display: none
}

@media (min-width:1024px) {
    .st-price-table {
        display: block
    }
}

.st-price-blocks {
    position: relative;
    display: block
}

@media (min-width:1024px) {
    .st-price-blocks {
        display: none
    }
}

.st-price-blocks-item:not(:last-of-type) {
    margin-bottom: 40px
}

.st-price-info {
    max-width: 320px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    text-align: center
}

@media (min-width:1024px) {
    .st-price-info {
        max-width: 235px;
        min-height: 178px
    }
}

.st-price-info-title {
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -.31px;
    margin-bottom: 22px
}

.st-price-info-wrapper {
    margin-bottom: 40px
}

.st-price-info-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: .1px;
    color: #414d5b
}

.st-price-info-button {
    z-index: 1
}

.st-price-info-subtitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -.23px;
    margin-bottom: 7px
}

.st-price-info-list-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (min-width:768px) {
    .st-price-info-list-grid {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        margin-right: -20px
    }
}

.st-price-info-list-grid.-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.st-price-info-list-grid.-left .st-price-info-list-grid-col {
    padding: 0
}

.st-price-info-list-grid-col {
    width: 100%;
    padding: 0 20px
}

.st-price-info-list-grid-col:not(:last-of-type) {
    margin-bottom: 15px
}

// @media (min-width:768px) {
//     .st-price-info-list-grid-col:not(: last-of-type) {
//         margin-bottom: 0
//     }
// }

@media (min-width:768px) {
    .st-price-info-list-grid-col {
        width: auto
    }
}

.st-price-info-list-item {
    max-width: 230px
}

.st-price-info-list-item:not(:last-of-type) {
    margin-bottom: 15px
}

.st-price-info-list-item.-xl {
    padding: 25px 0
}

.st-price-info-list-item.-xl-bottom {
    padding-bottom: 25px
}

.st-price-info-list-item.-auto {
    max-width: 100%
}

.st-price-info-feature {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.st-price-info-feature-icon svg {
    height: 12px;
    fill: #0097ff
}

.st-price-info-feature-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: -.23px;
    margin-left: 15px
}

.st-price-block {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background-color: #fff
}

.st-price-block-wrap {
    padding: 55px 0 40px
}

.st-price-block-header {
    padding: 0 20px;
    margin-bottom: 30px
}

@media (min-width:375px) {
    .st-price-block-header {
        padding: 0 40px
    }
}

@media (min-width:768px) {
    .st-price-block-header {
        margin-bottom: 40px;
        padding: 0
    }
}

.st-price-block-body {
    border-bottom: 1px solid rgba(151, 151, 151, .11)
}

.st-price-block-body-wrap {
    padding: 0 20px 30px 20px
}

@media (min-width:768px) {
    .st-price-block-body-wrap {
        padding: 0 79px 30px 61px
    }
}

.st-price-block-footer-wrap {
    padding-top: 30px
}

.st-price-icon {
    display: inline-block;
    line-height: 0
}

.st-price-icon svg {
    height: 12px;
    fill: #0097ff
}

.st-price-icon.-close svg {
    height: 12px;
    fill: #dedede
}

.st-faq-wrap {
    padding: 102px 0 60px 0
}

@media (min-width:768px) {
    .st-faq-wrap {
        padding: 150px 0
    }
}

@media (min-width:768px) {
    .st-faq-wrap {
        padding: 180px 0 150px 0
    }
}

@media (min-width:1280px) {
    .st-faq-wrap {
        padding: 180px 0 110px 0
    }
}

@media (min-width:1440px) {
    .st-faq-wrap {
        padding: 180px 0 140px 0
    }
}

.st-faq-container {
    padding: 0 19px
}

@media (min-width:375px) {
    .st-faq-container {
        padding: 0 35px
    }
}

@media (min-width:768px) {
    .st-faq-container {
        padding: 0 56px
    }
}

@media (min-width:1024px) {
    .st-faq-container {
        width: 701px;
        margin: 0 auto;
        padding: 0
    }
}

@media (min-width:1280px) {
    .st-faq-container {
        width: 921px
    }
}

@media (min-width:1440px) {
    .st-faq-container {
        width: 990px
    }
}

.st-faq-title {
    margin: 0 40px 10px 0
}

@media (min-width:768px) {
    .st-faq-title {
        margin: 0 0 10px 0
    }
}

@media (min-width:1024px) {
    .st-faq-title {
        margin: 0 0 30px 0
    }
}

@media (min-width:1280px) {
    .st-faq-title {
        margin: 0 0 35px 0
    }
}

.st-faq-title h1 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    letter-spacing: -.29px;
    line-height: 34px
}

@media (min-width:768px) {
    .st-faq-title h1 {
        font-size: 32px;
        letter-spacing: -.38px;
        line-height: 52px
    }
}

.st-accordion-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.st-accordion-item {
    border-bottom: 1px solid rgba(65, 77, 91, .2)
}

.st-accordion-item:last-child {
    border-bottom: none
}

.st-accordion-icon {
    position: relative;
    padding: 0 25px 0 0;
    color: #0097ff
}

@media (min-width:768px) {
    .st-accordion-icon {
        padding: 3px 30px 0 0
    }
}

@media (min-width:1280px) {
    .st-accordion-icon {
        padding: 3px 37px 0 0
    }
}

@media (min-width:1440px) {
    .st-accordion-icon {
        padding: 3px 44px 0 0
    }
}

.st-accordion-icon svg {
    -webkit-transition: .25s;
    -o-transition: .25s;
    -moz-transition: .25s;
    transition: .25s
}

.st-accordion-icon svg:last-child {
    position: absolute;
    top: 5px;
    left: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
}

@media (min-width:768px) {
    .st-accordion-icon svg:last-child {
        top: 8px
    }
}

.st-accordion-icon.-active svg:first-child {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
}

.st-accordion-icon.-active svg:last-child {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

@media (min-width:768px) {
    .st-accordion-title {
        padding: 0 60px 0 0
    }
}

.st-accordion-title h2 {
    padding: 0;
    margin: 0;
    font-size: 16px;
    letter-spacing: -.27px;
    line-height: 28px;
    font-weight: 700
}

@media (min-width:768px) {
    .st-accordion-title h2 {
        font-size: 18px;
        letter-spacing: -.3px;
        line-height: 32px
    }
}

.st-accordion-brief {
    font-size: 14px;
    letter-spacing: .44px;
    line-height: 30px
}

@media (min-width:768px) {
    .st-accordion-brief {
        font-size: 16px;
        letter-spacing: .5px;
        line-height: 34px
    }
}

.st-accordion-brief p {
    margin: 0 0 35px 0
}

.st-accordion-brief p:last-child {
    margin: 0
}

.st-accordion-header {
    padding: 20px 0;
    cursor: pointer
}

@media (min-width:768px) {
    .st-accordion-header {
        padding: 27px 0
    }
}

@media (min-width:1280px) {
    .st-accordion-header {
        padding: 37px 0
    }
}

.st-accordion-body {
    display: none
}

.st-accordion-body-wrap {
    padding: 0 0 20px 0
}

@media (min-width:768px) {
    .st-accordion-body-wrap {
        padding: 0 0 30px 0
    }
}

@media (min-width:1280px) {
    .st-accordion-body-wrap {
        padding: 0 0 40px 0
    }
}

.st-accordion-body-container {
    padding: 0 0 0 40px
}

@media (min-width:768px) {
    .st-accordion-body-container {
        padding: 0 60px 0 45px
    }
}

@media (min-width:1280px) {
    .st-accordion-body-container {
        padding: 0 60px 0 52px
    }
}

@media (min-width:1440px) {
    .st-accordion-body-container {
        padding: 0 60px 0 59px
    }
}

.st-filebox-grid {
    width: 100%;
    display: table
}

.st-filebox-grid-col {
    display: table-cell;
    vertical-align: middle;
    font-size: 0
}

.st-filebox-grid-col.-right {
    text-align: right
}

.st-filebox-box {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 72px;
    padding: 0 0 0 16px;
    background: rgba(76, 85, 166, .02);
    border: 1px dashed rgba(82, 82, 82, .12);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px
}

@media (min-width:375px) {
    .st-filebox-box {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0
    }
}

@media (min-width:768px) {
    .st-filebox-box {
        height: 78px
    }
}

.st-filebox-box input {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0
}

.st-filebox-icon {
    display: inline-block;
    vertical-align: middle;
    margin: -4px 0 0 0;
    font-size: 11px
}

@media (min-width:768px) {
    .st-filebox-icon {
        font-size: 12px
    }
}

.st-filebox-text {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 9px;
    font-size: 14px;
    letter-spacing: .1px;
    line-height: 18px;
    color: #414d5b
}

@media (min-width:768px) {
    .st-filebox-text {
        margin: 0 0 0 12px;
        font-size: 16px;
        letter-spacing: .12px;
        line-height: 22px
    }
}

@media (min-width:1280px) {
    .st-filebox-text {
        margin: 0 0 0 14px
    }
}

.st-filebox-text a {
    text-decoration: underline
}

.st-filebox-text a:focus,
.st-filebox-text a:hover {
    text-decoration: underline;
    opacity: .8
}

.st-filebox-preview {
    display: none
}

.st-filebox-preview-icon {
    display: inline-block;
    vertical-align: middle;
    margin: -2px 0 0 0;
    font-size: 11px
}

@media (min-width:768px) {
    .st-filebox-preview-icon {
        font-size: 12px
    }
}

.st-filebox-preview-text {
    display: inline-block;
    vertical-align: middle;
    margin: 0 9px 0 0;
    font-size: 14px;
    letter-spacing: .1px;
    line-height: 18px;
    color: #414d5b
}

@media (min-width:768px) {
    .st-filebox-preview-text {
        margin: 0 12px 0 0;
        font-size: 16px;
        letter-spacing: .12px;
        line-height: 22px
    }
}

@media (min-width:1280px) {
    .st-filebox-preview-text {
        margin: 0 14px 0 0
    }
}

.st-filebox-preview-text a {
    text-decoration: underline
}

.st-filebox-preview-text a:focus,
.st-filebox-preview-text a:hover {
    text-decoration: underline;
    opacity: .8
}

.st-filebox-progressbar {
    position: absolute;
    top: 12px;
    right: 6px;
    width: 24px;
    height: 24px
}

@media (min-width:768px) {
    .st-filebox-progressbar {
        top: 11px;
        right: 10px;
        width: 32px;
        height: 32px
    }
}

.st-filebox.-loaded .st-filebox-content {
    display: none
}

.st-filebox.-loaded .st-filebox-preview {
    display: block
}

.st-progressbar {
    position: relative;
    width: 100%;
    height: 100%
}

.st-progressbar-circle {
    fill: none;
    stroke-width: 2px;
    stroke-dasharray: 100px;
    stroke-dashoffset: 100px;
    stroke-linejoin: round;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.st-progressbar-fill {
    position: absolute;
    stroke-dasharray: 100px;
    stroke-linejoin: round;
    stroke-dashoffset: 0;
    stroke: rgba(76, 85, 166, .3)
}

.st-progressbar-bg {
    stroke: #0097ff;
    stroke-dasharray: 100px;
    stroke-linejoin: round;
    stroke-dashoffset: 25px
}

.st-progressbar-icon {
    position: absolute;
    top: 4px;
    left: 7px;
    right: 0;
    font-size: 10px;
    color: #0097ff;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    -webkit-transition: .2s;
    -o-transition: .2s;
    -moz-transition: .2s;
    transition: .2s
}

@media (min-width:768px) {
    .st-progressbar-icon {
        font-size: 14px;
        top: 5px;
        left: 9px
    }
}

.st-progressbar-icon.-visible {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.st-contact {
    position: relative;
    z-index: 1
}

.st-contact-wrap {
    padding: 102px 0 0 0
}

@media (min-width:768px) {
    .st-contact-wrap {
        padding: 131px 0 0 0
    }
}

@media (min-width:1024px) {
    .st-contact-wrap {
        padding: 140px 0 0 0
    }
}

.st-contact-title {
    margin: 0 0 30px 0;
    text-align: center
}

@media (min-width:1024px) {
    .st-contact-title {
        margin: 0 0 38px 0
    }
}

@media (min-width:1280px) {
    .st-contact-title {
        margin: 0 0 58px 0
    }
}

.st-contact-title h1 {
    font-size: 24px;
    letter-spacing: -.29px;
    line-height: 34px
}

@media (min-width:768px) {
    .st-contact-title h1 {
        font-size: 32px;
        letter-spacing: -.36px;
        line-height: 52px
    }
}

@media (min-width:768px) {
    .st-contact-title h1 {
        font-size: 36px;
        letter-spacing: -.43px;
        line-height: 52px
    }
}

.st-contact-map-container {
    position: relative
}

@media (min-width:1024px) {
    .st-contact-map-container {
        padding: 0 54px
    }
}

@media (min-width:1280px) {
    .st-contact-map-container {
        padding: 0 62px
    }
}

@media (min-width:1440px) {
    .st-contact-map-container {
        width: 1200px;
        margin: 0 auto;
        padding: 0
    }
}

.st-contact-map-box {
    width: 100%;
    height: 360px
}

@media (min-width:375px) {
    .st-contact-map-box {
        height: 436px
    }
}

@media (min-width:768px) {
    .st-contact-map-box {
        height: 424px
    }
}

@media (min-width:1024px) {
    .st-contact-map-box {
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px
    }
}

.st-contact-map #tab1 {
    display: none
}

.st-contact-map #map {
    width: 100%;
    height: 100%
}

.st-contact-nav {
    margin: 0 0 30px 0
}

@media (min-width:768px) {
    .st-contact-nav {
        margin: 0 0 48px 0
    }
}

.st-contact-nav-item {
    display: block;
    position: relative;
    margin: 0 0 30px 0;
    color: rgba(65, 77, 91, .6);
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    -moz-transition: color .3s;
    transition: color .3s
}

.st-contact-nav-item:focus,
.st-contact-nav-item:hover {
    text-decoration: none
}

.st-contact-nav-item:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    width: 20px;
    height: 4px;
    background: #0097ff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

.st-contact-nav-item:last-child {
    margin: 0
}

@media (min-width:375px) {
    .st-contact-nav-item {
        margin: 0 0 35px 0
    }
}

@media (min-width:768px) {
    .st-contact-nav-item {
        margin: 0 60px 0 0;
        display: inline-block;
        vertical-align: middle
    }
}

@media (min-width:1440px) {
    .st-contact-nav-item {
        margin: 0 52px 0 0
    }
}

.st-contact-nav-item-title {
    display: block;
    font-size: 16px;
    letter-spacing: -.27px;
    line-height: 28px;
    font-weight: 700
}

@media (min-width:1024px) {
    .st-contact-nav-item-title {
        font-size: 18px;
        letter-spacing: -.3px;
        line-height: 28px
    }
}

.st-contact-nav-item-subtitle {
    display: block;
    font-size: 14px;
    letter-spacing: .23px;
    line-height: 30px
}

@media (min-width:1024px) {
    .st-contact-nav-item-subtitle {
        font-size: 16px;
        letter-spacing: .27px;
        line-height: 30px
    }
}

.st-contact-nav-item.-active {
    color: #414d5b
}

.st-contact-nav-item.-active:after {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1)
}

.st-contact-form {
    width: 100%;
    margin: 0 auto 10px auto;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background: #fff;
    -webkit-box-shadow: 0 7px 24px rgba(174, 189, 205, .2);
    -moz-box-shadow: 0 7px 24px rgba(174, 189, 205, .2);
    box-shadow: 0 7px 24px rgba(174, 189, 205, .2);
    overflow: hidden
}

@media (min-width:375px) {
    .st-contact-form {
        margin: 0 auto;
        -webkit-transform: translateY(-60px);
        -moz-transform: translateY(-60px);
        -ms-transform: translateY(-60px);
        -o-transform: translateY(-60px);
        transform: translateY(-60px)
    }
}

@media (min-width:768px) {
    .st-contact-form {
        margin: 0 auto 30px auto;
        height: 698px;
        -webkit-transform: translateY(-50px);
        -moz-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        -o-transform: translateY(-50px);
        transform: translateY(-50px)
    }
}

@media (min-width:1024px) {
    .st-contact-form {
        max-width: 791px;
        height: 510px;
        margin: -35px auto 120px auto;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0)
    }
}

@media (min-width:1280px) {
    .st-contact-form {
        max-width: 923px;
        margin: -35px auto 140px auto
    }
}

@media (min-width:1440px) {
    .st-contact-form {
        max-width: 963px;
        margin: -35px auto 160px auto
    }
}

.st-contact-form-container {
    padding: 0 24px
}

@media (min-width:375px) {
    .st-contact-form-container {
        padding: 0 34px
    }
}

@media (min-width:768px) {
    .st-contact-form-container {
        padding: 0 72px
    }
}

@media (min-width:1024px) {
    .st-contact-form-container {
        padding: 0 44px
    }
}

@media (min-width:1280px) {
    .st-contact-form-container {
        padding: 0 48px 0 53px
    }
}

@media (min-width:1440px) {
    .st-contact-form-container {
        padding: 0 32px
    }
}

@media (min-width:1024px) {
    .st-contact-form-container.-sm {
        padding: 0 25px
    }
}

@media (min-width:1280px) {
    .st-contact-form-container.-sm {
        padding: 0 30px
    }
}

@media (min-width:1440px) {
    .st-contact-form-container.-sm {
        padding: 0 32px
    }
}

.st-contact-form-wrap {
    padding: 30px 0
}

@media (min-width:375px) {
    .st-contact-form-wrap {
        padding: 40px 0 30px 0
    }
}

@media (min-width:768px) {
    .st-contact-form-wrap {
        padding: 40px 0 25px 0
    }
}

@media (min-width:1024px) {
    .st-contact-form-wrap {
        padding: 32px 0
    }
}

@media (min-width:375px) {
    .st-contact-form-wrap.-sm {
        padding: 40px 0
    }
}

@media (min-width:768px) {
    .st-contact-form-wrap.-sm {
        padding: 40px 0 45px 0
    }
}

@media (min-width:1024px) {
    .st-contact-form-wrap.-sm {
        padding: 32px 0
    }
}

.st-contact-form-grid {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

@media (min-width:1024px) {
    .st-contact-form-grid {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.st-contact-form-grid-col {
    width: 100%
}

.st-contact-form-grid-col.-right {
    color: #fff;
    background: #0097ff
}

@media (min-width:1024px) {
    .st-contact-form-grid-col.-right {
        max-width: 214px;
        height: 100%
    }
}

@media (min-width:1280px) {
    .st-contact-form-grid-col.-right {
        max-width: 245px
    }
}

@media (min-width:1440px) {
    .st-contact-form-grid-col.-right {
        max-width: 266px
    }
}

.st-contact-form-group {
    position: relative;
    margin: 0 0 10px 0
}

.st-contact-form-group:last-child {
    margin: 0
}

@media (min-width:768px) {
    .st-contact-form-group {
        display: inline-block;
        vertical-align: middle;
        margin: 0 60px 0 0
    }
}

@media (min-width:1024px) {
    .st-contact-form-group {
        display: block;
        margin: 0 0 45px 0
    }
}

@media (min-width:1024px) {
    .st-contact-form-row {
        margin: -31px 0 0 0
    }
}

.st-contact-form-title {
    margin: 0 0 35px 0
}

@media (min-width:768px) {
    .st-contact-form-title {
        margin: 0 0 47px 0
    }
}

.st-contact-form-title h2 {
    padding: 0;
    margin: 0;
    font-size: 20px;
    letter-spacing: -.24px;
    line-height: 40px;
    font-weight: 700
}

@media (min-width:768px) {
    .st-contact-form-title h2 {
        font-size: 24px;
        letter-spacing: -.29px;
        line-height: 40px
    }
}

@media (min-width:1024px) {
    .st-contact-form-title h2 {
        font-size: 26px;
        letter-spacing: -.31px;
        line-height: 40px
    }
}

.st-contact-form-title.-inverse {
    margin: 0 0 13px 0;
    color: #fff
}

@media (min-width:1024px) {
    .st-contact-form-title.-inverse {
        margin: 0 0 60px 0
    }
}

.st-contact-form-label {
    margin: 0 0 7px 0;
    font-size: 12px;
    letter-spacing: .01px;
    line-height: 17px;
    color: rgba(255, 255, 255, .4)
}

.st-contact-form-text {
    font-size: 14px;
    letter-spacing: .02px;
    line-height: 19px
}

@media (min-width:768px) {
    .st-contact-form-text {
        font-size: 16px;
        line-height: 22px
    }
}

.st-contact-figure {
    position: absolute
}

.st-contact-figure:nth-child(1) {
    display: none
}

@media (min-width:768px) {
    .st-contact-figure:nth-child(1) {
        display: block;
        bottom: -195px;
        left: -82px
    }
}

@media (min-width:1024px) {
    .st-contact-figure:nth-child(1) {
        bottom: -35px;
        left: -10px
    }
}

@media (min-width:1280px) {
    .st-contact-figure:nth-child(1) {
        bottom: -15px;
        left: -70px
    }
}

@media (min-width:1440px) {
    .st-contact-figure:nth-child(1) {
        bottom: -30px;
        left: -175px
    }
}

.st-contact-figure:nth-child(2) {
    display: none
}

@media (min-width:375px) {
    .st-contact-figure:nth-child(2) {
        display: block;
        bottom: -570px;
        left: -40px;
        z-index: -1
    }
}

@media (min-width:768px) {
    .st-contact-figure:nth-child(2) {
        bottom: -620px;
        left: -40px
    }
}

@media (min-width:1280px) {
    .st-contact-figure:nth-child(2) {
        bottom: -620px;
        left: 20px
    }
}

@media (min-width:1440px) {
    .st-contact-figure:nth-child(2) {
        bottom: -635px;
        left: -40px
    }
}

.st-contact-figure:nth-child(3) {
    top: -200px;
    right: -100px
}

@media (min-width:768px) {
    .st-contact-figure:nth-child(3) {
        top: -90px;
        right: -90px
    }
}

@media (min-width:1024px) {
    .st-contact-figure:nth-child(3) {
        top: -50px;
        right: -90px
    }
}

@media (min-width:1280px) {
    .st-contact-figure:nth-child(3) {
        top: 120px;
        right: -40px
    }
}

@media (min-width:1440px) {
    .st-contact-figure:nth-child(3) {
        top: 120px;
        right: -115px
    }
}

.st-contact-figure:nth-child(4) {
    display: none
}

@media (min-width:375px) {
    .st-contact-figure:nth-child(4) {
        display: block;
        right: -100px;
        bottom: -820px;
        z-index: 2
    }
}

@media (min-width:768px) {
    .st-contact-figure:nth-child(4) {
        right: -100px;
        bottom: -300px
    }
}

@media (min-width:1024px) {
    .st-contact-figure:nth-child(4) {
        right: -35px;
        bottom: -440px
    }
}

@media (min-width:1280px) {
    .st-contact-figure:nth-child(4) {
        right: -105px;
        bottom: -340px
    }
}

@media (min-width:1440px) {
    .st-contact-figure:nth-child(4) {
        right: -150px;
        bottom: -320px
    }
}

.btn-stay-blue{
    background-color: #0097ff!important;
    color: #fff;
}
.btn-stay-blue:hover{
    background-color: #0097ff!important;
    color: #fff;
}

@media screen and (max-width: 450px){
    .st-footer-adjustment{
        margin-top: 60px!important;
    }
}

@media screen and (max-width: 550px){
    .st-footer-adjustment{
        margin-top: 45px!important;
    }
}

.news{
    color: black;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}


ul.recent_work_list li{
    float: left;
    width: 23%;
    margin: 1px 9px;
    margin-bottom: 24px !important;
}

// ul.multiple-image-carousel li {
//     height: auto;
//     margin: 0px 15px;
//     margin-bottom: 30px;
//     .card.card-floating-wide, .card.card-floating-boxed {
//         margin: 0px;
//         position: relative;
//         /* overflow: hidden; */
//         display: grid;
//         padding-bottom: 60px;
//     }
//     .readmore_button{
//         position: absolute;
//         left: 14px;
//         bottom: 1px;
//     }
// }
// .multiple-image-carousel{
//     .slick-track{
//         display: flex;  
//     }
// } 

// .slick-initialized .slick-slide {
//     display: flex;
// }



@media(max-width: 1470px){
  ul.recent_work_list li {
    float: left;
    width: 31%;
    margin: 1px 9px;
    }
}
@media(max-width: 1000px){
   ul.recent_work_list li {
    float: left;
    width: 46%;
    margin: 1px 9px;
    }
}

@media(max-width: 767px){
    ul.recent_work_list li {
    float: left;
    width: 100%;
    margin: 1px 9px; 
   }
}
