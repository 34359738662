/*! _layout-utils.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Resets and Utils 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================

0. Resets and base setup
1. Back to top button
2. Progress bars
3. Input placeholders
4. Window scrollbar
=============================================================================
***/



/* ==========================================================================
0. Resets and base setup
========================================================================== */

body {
    // font-family: 'Ubuntu', sans-serif;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #4a4a4a !important;
}

a, a:active, i, i:active, div, div:active, div:focus, span:focus, span:active, {
    outline: none !important;
}

.button.is-active, .button:active, .button:focus {
    box-shadow: none !important;
}

section:focus {
    outline: none !important;
}

@font-face {
	font-family: 'ecoicon';
	src:url('../fonts/ecoicon/ecoicon.eot');
	src:url('../fonts/ecoicon/ecoicon.eot#iefix') format('embedded-opentype'),
		url('../fonts/ecoicon/ecoicon.woff') format('woff'),
		url('../fonts/ecoicon/ecoicon.ttf') format('truetype'),
		url('../fonts/ecoicon/ecoicon.svg#ecoicon') format('svg');
	font-weight: normal;
	font-style: normal;
}


/* ==========================================================================
1. Back to top button
========================================================================== */

#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 9999;
    transition: 0.35s;
    transform: scale(0.7);
    transition: all 0.5s;
}

#backtotop.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);

}

#backtotop.visible a:hover {
    outline: none;
    opacity: 0.9;
    background: $secondary;
}

#backtotop a {
    outline: none;
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 46px;
    height: 46px;
    background-color: $primary;
    opacity: 1;
    transition: all 0.3s;
    border-radius: 50%;
    text-align: center;
    font-size: 26px
}

body #backtotop a {
    outline: none;
    color: #fff;
}

#backtotop a:after {
    outline: none;
    content: "\f106";
    font-family: "FontAwesome";
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
}

/* ==========================================================================
1. Workbook Back to top button
========================================================================== */

#workbookbtt {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 9999;
    transition: 0.35s;
    transform: scale(0.7);
    transition: all 0.5s;
}

#workbookbtt.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);

}

#workbookbtt.visible a:hover {
    outline: none;
    opacity: 0.9;
    background: $white;
}

#workbookbtt a {
    outline: none;
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 46px;
    height: 46px;
    background-color: $orange;
    opacity: 1;
    transition: all 0.3s;
    border-radius: 50%;
    text-align: center;
    font-size: 26px
}

body #workbookbtt a {
    outline: none;
    color:$black;
}

#workbookbtt a:after {
    outline: none;
    content: "\f106";
    font-family: "FontAwesome";
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
}

/* ==========================================================================
2. Progress bars
========================================================================== */

.progress {
    width: 100% !important;
}

.progress.primary::-webkit-progress-value {
    background-color: $primary !important;
}

.progress.secondary::-moz-progress-bar {
    background-color: $secondary !important;
}


/* ==========================================================================
3. Input placeholders
========================================================================== */

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder;
}
:-moz-placeholder { /* Firefox 18- */
    color: $placeholder;
}


/* ==========================================================================
4. Window scrollbar
========================================================================== */

body::-webkit-scrollbar {
    width: 10px;
}
body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0,0,0,0.2)
}