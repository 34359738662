/* common */

h1.title {
  font-size: $h1font !important;
  line-height: 86px !important;
  font-weight: 100 !important;
}

h2.title {
  font-size: $h2font !important;
  line-height: 52px !important;
  font-weight: 100 !important;
}

h3.title {
  font-size: $h3font !important;
  line-height: 34px !important;
  font-weight: 100 !important;
}

h4.title {
  font-size: $h4font !important;
  line-height: 29px !important;
  font-weight: 100 !important;
}

h5.title {
  font-size: $h5font !important;
  line-height: 22px !important;
  font-weight: 100 !important;
}

h6.title {
  font-size: $h6font !important;
  line-height: 20px !important;
  font-weight: 100 !important;
}

.st_imgboxshadow{
  display: block;
  -webkit-transform: scale(0.94);
  transform: scale(0.94);
  border-radius: 6px;
  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.whitenavbar-fade {
    padding-top: 40px;
}

.navbar-fade.is-transparent .navbar-menu .navbar-end .st-btn_nav.-painted.-inverse .st-btn_nav-fill {
  background: $white !important;
}

.navbar-fade.is-transparent .navbar-menu .navbar-end .st-btn_nav.-painted.-inverse {
  color: $primary !important;
}

.whitenavbar-fade.is-transparent .navbar-menu .navbar-end .st-btn_nav.-painted.-inverse .st-btn_nav-fill {
  background: $primary !important;
}

.whitenavbar-fade.is-transparent .navbar-menu .navbar-end .st-btn_nav.-painted.-inverse {
  color: $white !important;
  z-index: 1;
}

.chosen-container .chosen-results li.highlighted {
  color: $primary !important;
}

.has-initial-overflow{
  overflow: initial!important;
}

//blog

.list-style-blog {
  list-style-type: disc !important;
  margin-left: 2.5rem !important;
}

.blog-sub-heading {
  color: $primary;
}

.blog-sub-heading-secondary {
  color: $secondary;
}
//home - features

.st_ondemandicons li:nth-child(1)::before {
  content:'';
  background-image: url(../images/home/burger.png);
  padding: 1em;
  vertical-align: middle;
  display: inline-block;
  margin-right: 0.7em;
  background-repeat: no-repeat;
}


.st_ondemandicons li:nth-child(2)::before {
  content:'';
  background-image: url(../images/home/taxi.png);
  padding: 1em;
  vertical-align: middle;
  display: inline-block;
  margin-right: 0.7em;
  background-repeat: no-repeat;
}

.st_ondemandicons li:nth-child(3)::before {
  content:'';
  background-image: url(../images/home/delivery-truck.png);
  padding: 1em;
  vertical-align: middle;
  display: inline-block;
  margin-right: 0.7em;
  background-repeat: no-repeat;
}


.st_ondemandicons li:nth-child(4)::before {
  content:'';
  background-image: url(../images/home/service.png);
  padding: 1em;
  vertical-align: middle;
  display: inline-block;
  margin-right: 0.7em;
  background-repeat: no-repeat;
}


.st_ondemandicons li {
  display: inline-flex;
  margin-top: 0.7em;
}


.content ul {
  margin-left: 1em !important;
}

.has_boxraised {
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
}

.has-overflowhidden {
  overflow: hidden;
}

.st_noborder {
  border: none !important;
}

.st_background_grey {
  background-color: $grey;
}

.chosen-container-single .chosen-single span {
  color: rgba(0, 0, 0, 0.5) !important;
}

::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 15px !important;
}

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 15px !important;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 15px !important;
}

.has_textjustify {
  text-align: justify;
}

.has_fright {
  float: right;
}

.has_fleft {
  float: left;
}

.st_fsize14 {
  font-size: 14px;
}

.work-svg {
  height: 25px;
  width: 25px;
}

.work-marg {
  margin: 0 auto;
  display: inline-flex;
}


.st_getaquotebtn a {
  background-color: $white !important;
  border-radius: 50px;
  padding: 0.8em 1.6em !important;
  letter-spacing: 1px;
  color: $primary !important;
}

.st_getaquotebtn:hover a {
  background-color: $white !important;
  border-radius: 50px;
  padding: 0.8em 1.6em !important;
  letter-spacing: 1px;
  color: $primary !important;
}

.st_getaquotebtn2 a {
  background-color: $primary !important;
  border-radius: 50px;
  padding: 0.8em 1.6em !important;
  letter-spacing: 1px;
}

.st_getaquotebtn2:hover a {
  background-color: $primary !important;
  border-radius: 50px;
  padding: 0.8em 1.6em !important;
  letter-spacing: 1px;
}

.navbar-item a {
  color: $white;
}

.has_primarybg {
  background-color: $primary;
}

.has_secondarybg {
  background-color: $secondary;
}

.height-86 {
  height: 86px;
}

.worklite-banner {
  height:100vh;
  background-image: url("../images/workbook/workbook-bg.png");
  background-repeat: no-repeat;
}

.bg-black {
  background-color: black !important;
}

.font-orange {
  color: $orange !important;
}

.blog-t-ht {
  height: 80px !important;
}

.blog-p-ht {
  height: 400px !important;
}


// .navigation-tabs .navtab-content.is-active.has_flex {
//   display: flex;
// }

// .multiple-image-carousel.carousel-item {
//   margin: 0px;
// }
/* .white-navbar {
  background-color: #f0f3f4 !important;
} */

@media (max-width: 500px) {
  h1.h1-mob {
    font-size: 45px !important;
  }
  h3.h3-mob {
    font-size: 18px !important;
  }
  .h4-rotator-mob {
    font-size: 60px !important;
  }
  .sub-rotator-mob {
    font-size: 1.5rem !important;
  }
  .hero-body-align {
    padding: 0 1.5rem !important;
    padding-top: 0px !important;
  }
  .product-box {
    display: flex;
    flex-direction: column;
  }
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  h3.title-font {
    font-size: 20px !important;
  }
  h2.title-font-30 {
    font-size: 30px !important;
  }
  h5.subtitle-font {
    font-size: 12px !important;
  }
  .subtitle-font {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .mob-align {
    text-align: center !important;
    align-content: center !important;
    align-items: center !important;
  }
}

.has-inline-flex {
  display: inline-flex;
}

@media (min-width: 769px) {
  .work-filter-flex {
    display: flex;
  }
}

@media (max-width: 1089px) {
  .st_contactdetails {
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    background: #0097ff;
  }
  .st_contactform {
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
  }
  .navbar.navbar-light.is-dark-mobile {
    background: #5c62da;
  }
  .whitenavbar-fade.navbar-light.is-transparent {
    background: #fff !important;
  }
  .wid-100 {
    width: 100%;
  }
}

a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:hover, .navbar-link.is-active {
  background-color: transparent !important;
}

@media (min-width: 1800px) {
  .blog-p-ht {
    height: 250px !important;
  }
  .blog-t-ht {
    height: 70px !important;
  }
}

@media (min-width: 1600px) {
  .blog-p-ht {
    height: 310px !important;
  }
  .blog-t-ht {
    height: 70px !important;
  }
}

@media (max-width: 1088px) {
  .tab-align {
    text-align: center !important;
    align-content: center !important;
    align-items: center !important;
  }
  .tab-marg-social {
    margin: 0 auto;
  }
  .tab-social {
    margin-right: 1rem !important;
    margin-left:1rem;
  }
  .navbar-fade {
    padding-top: 0px !important;
  }
  .whitenavbar-fade {
    padding-top: 0px !important;
  }
  .subtitle-resp {
    text-align: center !important;
  }
  .custom-burger {
    display: flex !important;
  }
  .navbar .navbar-menu .navbar-link, .navbar .navbar-menu .navbar-item, .navbar .navbar-menu .navbar-dropdown {
    width: 100%;
    text-align: center;
  }
  .navbar.navbar-light.is-dark-mobile .navbar-menu {
    background: rgb(92, 98, 218);
  }
  .burger-btn-fill {
    z-index: 0 !important;
  }
  .burger-btn {
    z-index: 1;
    position: relative;
  }
  .navbar-brand {
    min-height: 4.6rem;
  }
  .navbar-wrapper.workbooknavbar-faded .navbar-brand img {
    height: 48px;
  }
  .navbar-wrapper.navbar-faded .navbar-brand img {
    height: 48px;
  }
  .pad-2rem {
    padding: 2rem !important;
  }
  .mg-rem {
    margin: 0.5rem 1.5rem !important;
  }
  .margin-auto {
    margin: 0 auto;
  }
  .blog-p-ht {
    height: 400px !important;
  }
  .blog-t-ht {
    height: 100px !important;
  }
  // .work-no-padding {
  //   padding: 0px !important;
  // }
  // .no-padding-btm {
  //   padding-bottom: 0px !important;
  // }
}

@media (min-width: 1472px) {
  .fluid-fix {
    margin-left: 185px !important;
    margin-right: 185px !important;
  }
}

@media (max-width: 1472px) {
  .background-cover {
    background-size: cover!important;
  }
}

@media (max-width: 1279px) {
  .des-margin {
    margin-right: 2.3vw !important;
  }
}


@media (max-width:950px) {
  .blog-p-ht {
    height: 460px !important;
  }
  .blog-t-ht {
    height: 120px !important;
  }
}

@media (max-width:900px) {
  .blog-p-ht {
    height: 350px !important;
  }
  .blog-t-ht {
    height: 100px !important;
  }
}

@media (max-width:768px) {
  .blog-p-ht {
    height: 175px !important;
  }
  .blog-t-ht {
    height: 75px !important;
  }
  .blog-p {
    font-size: 14px !important;
    line-height: 24px !important;
  }
}

@media (max-width:500px) {
  .blog-p-ht {
    height: 210px !important;
  }
  .blog-t-ht {
    height: 95px !important;
  }
  .blog-h4 {
    font-size: 16px !important;
  }
  .blog-p {
    font-size: 12px !important;
    line-height: 22px !important;
  }
}


@media (min-width: 768px) {
  .has_displayflex {
    display: flex;
  }
}

@media (min-width: 1089px) {
  .navbar-wrapper.navbar-light .navbar-item.is-slide:after, .navbar-wrapper.navbar-light .navbar-link.is-slide:after {
    content: "";
    display: block;
    width: 0;
    min-width: 0;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #f4579b;
    transition: width 0.3s;
  }
}

.has_opacity3 {
  opacity: 0.3;
}

.is-displayblock {
  display: block !important;
}

.is-displayflex {
  display: flex;
}

.has-width-100 {
  width: 100%;
}

.has_boxshadow {
  -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
}

.portfolioContainer .element {
  padding-bottom: 1em;
}

.is-commonfill {
  background-image: url("../images/commonfill1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: 60vh;
}

.is-commonfill-serv {
  background-image: url("../images/commonfill3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: 60vh;
}

@media(max-width:1279px){
  .is-commonfill {
    background-size: cover;
  }
}

//animated UI svg
.vivus-svg {
  max-height: 100%;
  width: 100%;
}

.vivus-svg.line-drawing path {
  fill: none;
  stroke: #000;
  stroke-width: 1;
}

.banner.line-drawing path {
  fill: none;
  stroke: #000;
  stroke-width: 1;
}

// .about-svg {
//   width: 25% !important;
//   height: 25% !important;
// }

.margin-auto-full {
  margin: 0 auto;
}

.display-flex {
  display: flex;
}
// .vision::before {
//   content: url("../images/about/vision.png");
//   margin-right: 30px;
// }

// .mission::before {
//   content: url("../images/about/mission.png");
//   margin-right: 20px;
// }

// .services.line-drawing path {
//   fill: none;
//   stroke: rgba(0, 151, 255, 1);
//   stroke-width: 5;
// }

@media(max-width:1087px) {
  .vivus-svg.services {
    max-height: 250px;
    width: 180px;
  }
}

.odaqi-font {
  font-size: 0.8rem !important;
}

.st_vivus p {
  line-height: 24px;
}

.services.s1.line-drawing path {
  fill: none;
  stroke: rgba(108, 4, 58, 1);
  stroke-width: 5;
}

.services.s2.line-drawing path {
  fill: none;
  stroke: rgba(10, 14, 110, 1);
  stroke-width: 5;
}

.services.s3.line-drawing path {
  fill: none;
  stroke: rgba(83, 45, 142, 1);
  stroke-width: 5;
}

.about.line-drawing path {
  fill: none;
  stroke: rgb(244, 87, 155);
  stroke-width: 2;
}

.is-primary {
  color: $primary !important;
}

.is-primary-cnst {
  color: $primary;
}

.is-secondary {
  color: $secondary !important;
}

.has_primary_gradient {
  background: $hero-gradient;
}

.has_primary_gradient {
  background: $hero-secondarygradient;
}

.hero-body.st_commontitle {
  transform: translate(0, 25%);
  padding: 1rem 1.5rem!important;
}

.hero-body.st_workbooktitle {
  transform: translate(0, 5%);
  padding: 8rem 1.5rem!important;
}

.marg-footer-btm {
  margin: 20% auto;
}

/* common end */

.parsley-required {
  font-size:  12px !important;
  color: $secondary !important;
}

/* navbar */

.navbar_primary a {
  color: $black !important;
}

.navbar-item img {
  max-height: 100px;
}

.navbar-fade {
  padding-top: 40px;
}

.navbar-faded {
  padding-top: 0;
}

a.navbar-item {
  margin: 0.5rem 1.5rem !important;
  padding: 0;
}

@media (min-width: 1600px) {
  a.navbar-item {
    margin: 0.5rem 3rem !important;
    padding: 0;
  }

  // .vivus-svg.services {
  //   max-height: 50%;
  //   width: 50%;
  // }

  .st_imgmleft {
    margin-left: -1.5vw;
  }
}

.bg-light {
  background-color: rgb(33, 31, 31);
}

.nav-up {
  top: -64px;
}

.pad-title {
  padding: 8rem .75rem;
}

@media(max-width:1086px) {
  .pad-title {
    padding: 0rem .75rem;
  }
}

.has-display-inlinegrid {
  display: -ms-inline-grid !important;
  display: inline-flex;
}

/* navbar end */

.mb-custom {
  margin-bottom: 8rem !important;
}

.home-btn {
  background: #ffffff !important;
  border: none;
}

.home-btn-clr {
  color: #3d426f !important;
}

/* home - services */

.service-li {
  width: 270px !important;
  height: 142px !important;
  margin-right: 10px !important;
}

.service-a {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  padding: 10px 15px !important;
  background: #fff !important;
  border-radius: 10px !important;
}

.service-b {
  width: 175px !important;
  height: 142px !important;
  display: block !important;
  padding: 10px 15px !important;
  background: #fff !important;
  border-radius: 10px !important;
  margin-bottom: 20px !important;
  margin-right: 0 !important;
}

.home-img {
  max-width: 800px;
  height: 100% !important;
}

.home-service-img {
  width: 143px !important;
  height: 85px !important;
  margin: 0  auto !important;
}

.border-none {
  border-bottom-style: none !important;
}

.is-float-right {
  float: right !important;
}

.is-float-left {
  float:  left !important;
}

.service-desc-right {
  max-width: 450px !important;
  float: left !important;
  text-align: left !important;
}

.service-desc-left {
  max-width: 450px !important;
  float: right !important;
  text-align: right !important;
}

.home-service-i {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.null-border {
  border: none !important;
}

.flex-card.null-border {
  border: none !important;
}

/* banner */

.is-fill {
  background-image: url("../images/home/test-background.png");
  background-repeat: no-repeat;
  background-size: contain;
}

// .st_clientsbg{
//   width: 50%!important;
//   height: 600px;
//   border-radius: 300px;
//   -webkit-transform: rotate(-25deg) translate(-70px,30px);
//   -ms-transform: rotate(-25deg) translate(-70px,30px);
//   -o-transform: rotate(-25deg) translate(-70px,30px);
//   -moz-transform: rotate(-25deg) translate(-70px,30px);
//   transform: rotate(-25deg) translate(-70px,30px);
// }

// .st_boximage{
//   background-image: url(http://t.commonsupport.com/score/images/background/2.jpg);
//   left: 0px;
//   top: 0px;
//   width: 100%;
//   height: 100%;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center top;
//   -webkit-transform: rotate(25deg) scale(1.5,1.5);
//   -ms-transform: rotate(25deg) scale(1.5,1.5);
//   -o-transform: rotate(25deg) scale(1.5,1.5);
//   -moz-transform: rotate(25deg) scale(1.5,1.5);
//   transform: rotate(25deg) scale(1.5,1.5);
// }

.st_clientsbg img {
  // border-radius: 300px;
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
  -webkit-transform: rotate(-25deg) translate(-70px, 30px);
  -ms-transform: rotate(-25deg) translate(-70px, 30px);
  -o-transform: rotate(-25deg) translate(-70px, 30px);
  -moz-transform: rotate(-25deg) translate(-70px, 30px);
  transform: rotate(-25deg) translate(-70px, 30px);
}

.st_clientimg .client {
  padding-bottom: 30px;
}

.st_ctitle {
  position: relative;
  -webkit-transform: translate(30%, 380%);
  -ms-transform: translate(30%, 380%);
  transform: translate(30%, 380%);
  z-index: 10;
  color: $white !important;
  font-family: "Satisfy", cursive;
}

.st_expertiselist.is-active {
  color: #fff;
}

.st_expertiselist {
  color: rgba(0,0,0,0.7);
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 0.4s cubic-bezier(0.17, 0.67, 0.32, 0.87);
  -o-transition: all 0.4s cubic-bezier(0.17, 0.67, 0.32, 0.87);
  transition: all 0.4s cubic-bezier(0.17, 0.67, 0.32, 0.87);
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  font-size: $h4font;
  margin-right: 5.3vw;
}

.st_expertiselist::after {
  content: attr(data-hovermenu);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: $primary;
  white-space: nowrap;
  -webkit-transition: all 0.8s cubic-bezier(0.17, 0.67, 0.32, 0.87);
  -o-transition: all 0.8s cubic-bezier(0.17, 0.67, 0.32, 0.87);
  transition: all 0.8s cubic-bezier(0.17, 0.67, 0.32, 0.87);
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  pointer-events: none;
}

.st_expertiselist::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: $secondary;
  -webkit-transition: all 0.8s cubic-bezier(0.17, 0.67, 0.32, 0.87);
  -o-transition: all 0.8s cubic-bezier(0.17, 0.67, 0.32, 0.87);
  transition: all 0.8s cubic-bezier(0.17, 0.67, 0.32, 0.87);
}

.st_expertiselist:hover:after,
.st_expertiselist:hover:before {
  width: 100%;
}

/* banner end */

/* who we are */
.suffixcounterplus::after {
  content: "+";
}

.suffixcounterper::after {
  content: "%";
}

.st_whowearembtm {
  margin-bottom: 8em;
}

/* who we are end*/

.usecase-img {
  border-bottom-right-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}

.showsvg {
  opacity: 1;
}

.hidesvg {
  opacity: 0;
}

.st_shover a {
  color: #4a4a4a !important;
}

.st_shover a:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}

.litabs.is-active {
  color: $primary;
}

.has-text-grey {
  color: rgb(135, 135, 135);
  font-weight: 200 !important;
}

/* services */

.st_servicemtop {
  margin-top: -20em;
}

@media (max-width: 767px) {
  .st_tablink.tab-link a {
    font-size: $h5font;
  }
  .mob-social {
    margin-right: 1rem !important;
    margin-left:1rem;
  }
  .marg-social {
    margin: 0 auto;
  }
  .mob-contact {
    padding-bottom: 0px !important;
    padding-top: 40px !important;
  }
  h2.mob-font {
    font-size: 22px !important;
    line-height: 32px !important;
  }
  .workbook-navbar {
    background-color: #0a0a0a !important;
    box-shadow: 0 8px 16px rgba(226, 127, 23, 0.1) !important;
    padding: 0.5rem 0;
  }
}

@media (min-width: 768px) {
  .st_tablink.tab-link a {
    font-size: $h3font;
  }
  .st_tabs {
    overflow-x: unset !important;
    overflow: unset !important;
  }
}


.st_tablink.tab-link {
  // margin-right: 5vw;
  margin-right: 5.9vw;
}

.st_tablink.tab-link a {
  color: #fff !important;
  opacity: 0.7;
  padding: 0.5em 0.2em 0.7em 0.2em !important;
}

.st_tablink.tab-link.is-active a {
  color: #fff !important;
  opacity: 1;
  border-bottom-color: $white !important;
}

.st_tabs ul {
  border-bottom-color: $white !important;
}

.st_tabcontent li {
  padding-bottom: 20px !important;
}

.st_tabcontent li a {
  font-size: $h3font !important;
  color: $white !important;
  padding-bottom: 20px !important;
}

// .st_tabcontent li a:hover {
//   text-decoration: underline;
// }

.st_tabcontent li a:last-child {
  padding-bottom: 0;
}

.st_fixedprice {
  border-top: 6px solid rgba(244, 87, 155, 1);
}

.st_timeandmaterial {
  border-top: 6px solid rgba(107, 31, 64, 1);
}

.st_milestonebilling {
  border-top: 6px solid rgba(10, 14, 110, 1);
}

.st_businessmodel p {
  padding-left: 20px;
  padding-right: 20px;
}

.IconsContainer {
  width: 3000px;
  height: 420px;
  position: relative;
  left: -20px;
}

.Icon {
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  -webkit-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.07);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.07);
}

.Icon,
.Icon span {
  position: absolute;
  will-change: transform, opacity;
}

.Icon span {
  left: 50%;
  bottom: -40px;
  padding: 5px 12px;
  background: #fff;
  -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 50px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  color: #566b78;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px);
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.Icon:hover {
  z-index: 9999;
}

.Icon:hover span {
  opacity: 0.9;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.Header-title {
  text-align: center;
  margin: 0;
  padding: 0.2em 0.2em 2em;
}

.Header-subTitle {
  color: #7795f8;
}

.orange-raised {
  box-shadow: 0px 5px 25px 0px #422f09;
}

@media (min-width: 670px) {
  .Header-subTitle {
    display: block;
  }
}

.mb-calc {
  margin-bottom: calc(4.5rem - .75rem) !important;
}

.bdr-rem {
  border-radius: 1rem;
}

.Header-intro {
  text-align: center;
  padding-bottom: 40px !important;
}

.Icon {
  background-size: 1076px 1076px;
  background-image: url(../images/services/header-logos.png);
}

@media (-webkit-min-device-pixel-ratio: 1.3),
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
  .Icon {
    background-image: url(../images/services/header-logos@2x.png);
  }
}

.Icon-imgAffirm {
  background-position: -154px 0;
  width: 152px;
  height: 152px;
}

.Icon-imgAllianz {
  background-position: -154px -616px;
  width: 152px;
  height: 152px;
}

.Icon-imgAmazon {
  background-position: 0 -154px;
  width: 152px;
  height: 152px;
}

.Icon-imgBlueApron {
  background-position: -154px -154px;
  width: 152px;
  height: 152px;
}

.Icon-imgCatawiki {
  background-position: -308px 0;
  width: 152px;
  height: 152px;
}

.Icon-imgCityofBoston {
  background-position: -308px -154px;
  width: 152px;
  height: 152px;
}

.Icon-imgDeliveroo {
  background-position: 0 -308px;
  width: 152px;
  height: 152px;
}

.Icon-imgDiDi {
  background-position: -154px -308px;
  width: 152px;
  height: 152px;
}

.Icon-imgDoordash {
  background-position: -308px -308px;
  width: 152px;
  height: 152px;
}

.Icon-imgExpedia {
  background-position: -462px 0;
  width: 152px;
  height: 152px;
}

.Icon-imgFacebook {
  background-position: -462px -154px;
  width: 152px;
  height: 152px;
}

.Icon-imgFender {
  background-position: -462px -308px;
  width: 152px;
  height: 152px;
}

.Icon-imgFitbit {
  background-position: 0 -462px;
  width: 152px;
  height: 152px;
}

.Icon-imgGoogle {
  background-position: -154px -462px;
  width: 152px;
  height: 152px;
}

.Icon-imgIndiegogo {
  background-position: -308px -462px;
  width: 152px;
  height: 152px;
}

.Icon-imgInstacart {
  background-position: -462px -462px;
  width: 152px;
  height: 152px;
}

.Icon-imgKickstarter {
  background-position: -616px 0;
  width: 152px;
  height: 152px;
}

.Icon-imgLyft {
  background-position: -616px -154px;
  width: 152px;
  height: 152px;
}

.Icon-imgNasdaq {
  background-position: -616px -308px;
  width: 152px;
  height: 152px;
}

.Icon-imgNat-Geo {
  background-position: -616px -462px;
  width: 152px;
  height: 152px;
}

.Icon-imgOpenTable {
  background-position: 0 -616px;
  width: 152px;
  height: 152px;
}

.Icon-imgPanic {
  background-position: 0 0;
  width: 152px;
  height: 152px;
}

.Icon-imgPinterest {
  background-position: -308px -616px;
  width: 152px;
  height: 152px;
}

.Icon-imgPostmates {
  background-position: -462px -616px;
  width: 152px;
  height: 152px;
}

.Icon-imgRackspace {
  background-position: -616px -616px;
  width: 152px;
  height: 152px;
}

.Icon-imgReddit {
  background-position: -770px 0;
  width: 152px;
  height: 152px;
}

.Icon-imgSalesforce {
  background-position: -770px -154px;
  width: 152px;
  height: 152px;
}

.Icon-imgShopify {
  background-position: -770px -308px;
  width: 152px;
  height: 152px;
}

.Icon-imgSlack {
  background-position: -770px -462px;
  width: 152px;
  height: 152px;
}

.Icon-imgSpotify {
  background-position: -770px -616px;
  width: 152px;
  height: 152px;
}

.Icon-imgSquarespace {
  background-position: 0 -770px;
  width: 152px;
  height: 152px;
}

.Icon-imgTarget {
  background-position: -154px -770px;
  width: 152px;
  height: 152px;
}

.Icon-imgTaskRabbit {
  background-position: -308px -770px;
  width: 152px;
  height: 152px;
}

.Icon-imgTed {
  background-position: -462px -770px;
  width: 152px;
  height: 152px;
}

.Icon-imgTheGuardian {
  background-position: -616px -770px;
  width: 152px;
  height: 152px;
}

.Icon-imgTicketSwap {
  background-position: -770px -770px;
  width: 152px;
  height: 152px;
}

.Icon-imgTwitch {
  background-position: -924px 0;
  width: 152px;
  height: 152px;
}

.Icon-imgUber {
  background-position: -924px -154px;
  width: 152px;
  height: 152px;
}

.Icon-imgWeTransfer {
  background-position: -924px -308px;
  width: 152px;
  height: 152px;
}

.Icon-imgWish {
  background-position: -924px -462px;
  width: 152px;
  height: 152px;
}

.Icon-imgXero {
  background-position: -924px -616px;
  width: 152px;
  height: 152px;
}

.Icon-imgYelp {
  background-position: -924px -770px;
  width: 152px;
  height: 152px;
}

.Icon-imgZillow {
  background-position: 0 -924px;
  width: 152px;
  height: 152px;
}

/* service end */

/* work */


.st_carouselitem{
  padding: 5em 0 0;
}



@media (min-width: 768px) {
  .portfolioFilter a {
    margin-right: 3vw;
  }
  .card .card-image .title{
    height: 56px;
  }
}

.portfolioFilter {
  padding: 15px 0 40px;
}

.portfolioFilter a {
  margin-right: 2vw;
  color: #000;
  text-decoration: none;
  padding: 4px 15px;
  display: inline-block;
  font-size: 20px;
}

.portfolioFilter a.current {
  color: #f4579b;
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  transition-property: transform, opacity;
}

/* work end */

/* contact */

.st_contacttabs ul {
  border-bottom: none;
}

.st_contacttabs ul li.is-active a {
  opacity: 1;
  border-bottom-color: #fff;
}

.st_contacttabs ul li a {
  border-bottom-color: #fff;
}

.st_contacttabs ul li.is-active h3 span {
  opacity: 1;
}

.st_contacttabs ul li:hover h3 span {
  opacity: 1;
}

.st_contacttabs ul li a {
  opacity: 0.6;
  padding: 0;
  margin-right: 5vw;
}

.st_contacttabs ul li h3 span {
  font-size: $h5font;
  opacity: 0.6;
}

.st_contacttabs ul li::after {
  content: "";
  position: absolute;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.st_contacttabs ul li.is-active::after {
  content: "";
  position: absolute;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  width: 40px;
  height: 4px;
  background: #f4579b;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.st_contactform {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.st_contactdetails {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: #0097ff;
}

.st_contactdetails .card-body h5 a:hover {
  color: $white !important;
}

.st_contactmtop {
  margin-top: -5%;
}

.has_fleft .st_fsize14 {
  color: rgba(0, 0, 0, 0.5);
  line-height: 22px;
}

.has_fleft .st_fsize14 a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
  text-underline-position: under;
  -ms-text-underline-position: under;
  border-bottom: 1px thin rgba(0, 0, 0, 0.5);
}

.has_fleft .st_fsize14 a:hover {
  color: rgba(0, 0, 0, 0.5);
}

.st_contactbtn {
  background-color: #0097ff !important;
  padding: 1.3em 1.4em !important;
  letter-spacing: 1px;
  color: #fff !important;
  cursor: pointer;
  font-size: 14px;
}


/* contact end */

/* footer */

.level .level-left a.level-item {
  margin-right: 2rem;
}

.st_facebook {
  color: $facebook;
}

.st_twitter {
  color: $twitter;
}

.st_googleplus {
  color: $google-plus;
}

.st_linkedin {
  color: $linkedin;
}

.st_pinterest {
  color: $pinterest;
}

.s_fcontact {
  border-radius: 1rem;
  background-color: rgba(83, 45, 142, 1);
}

.s_fcontact .control-material .bar::after {
  background-color: rgba(255, 255, 255, 1);
}

.s_fcontact .control-material .bar::before {
  background-color: rgba(255, 255, 255, 1);
}

.s_fcontact .control-material .material-input {
  color: #ffffff !important;
}

.s_fcontact .control-material.is-primary input.material-input:focus~label {
  color: #ffffff !important;
}

.s_fcontact .control-material.is-primary input.material-input:valid~label {
  color: #ffffff !important;
}

.s_fcontact label {
  color: $white;
}

.st_footerbtn button {
  // padding: 19px 0 19px 25px;
  // width: 216px;
  background: #fff;
  border: none;
  outline: 0;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  color: rgba(83, 45, 142, 1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  font-family: "Quicksand", sans-serif !important;
  font-size: $h5font;
  cursor: pointer;
  padding: 5px 5px 5px 20px;
}

.hi-icon {
  display: inline-block;
  font-size: 0px;
  cursor: pointer;
  // margin: 15px 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;
  vertical-align: middle;
}

.hi-icon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.hi-icon:before {
  margin-top: -20px;
  line-height: 90px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: block;
  -webkit-font-smoothing: antialiased;
  font-size: $h4font;
  color: rgba(83, 45, 142, 1);
}

/* Effect 5 */
.hi-icon-effect-5 .hi-icon {
  // box-shadow: 0 0 0 4px rgba(255,255,255,1);
  overflow: hidden;
  -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
  -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
  transition: background 0.3s, color 0.3s, box-shadow 0.3s;
  background: rgba(76, 85, 166, 0.1);
}

.hi-icon-effect-5 .hi-icon:after {
  display: none;
}

.hi-icon-effect-5:hover .hi-icon {
  background: rgba(237, 237, 246, 1);
  color: #0097ff;
  // box-shadow: 0 0 0 8px rgba(255,255,255,0.3);
}

.hi-icon-effect-5a:hover .hi-icon:before {
  -webkit-animation: toRightFromLeft 0.3s forwards;
  -moz-animation: toRightFromLeft 0.3s forwards;
  animation: toRightFromLeft 0.3s forwards;
}

@-webkit-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translate(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes toRightFromLeft {
  49% {
    -moz-transform: translate(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translate(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes toRightFromLeft {
  49% {
    transform: translate(100%);
  }

  50% {
    opacity: 0;
    transform: translate(-100%);
  }

  51% {
    opacity: 1;
  }
}

/* footer end */






svg {
  pointer-events: none;
}

/* Demo 1 */

.demo-drawings figcaption {
  margin: 0 auto 0 auto;
  max-width: 40em;
  width: 100%;
  text-align: center;
}

.demo-drawings figure:first-child figcaption {
  margin-top: 8em;
}

.demo-drawings figcaption h2 {
  font-weight: 300;
  font-size: 3em;
  margin-bottom: 0.2em;
}

.demo-drawings figcaption p {
  font-size: 1.3em;
}

.drawings {
  position: relative;
  // max-height: 600px;
}

.illustration {
  position: absolute;
  // top: 50%;
  // left: 50%;
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.line-drawing,
.illustration {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.line-drawing path {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
}

.line-drawing polygon {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
}

.line-drawing ellipse {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
}


path.line-round {
  stroke-linecap: round;
}

path.stroke-medium {
  stroke-width: 1;
}

path.stroke-thin {
  stroke-width: 1;
}

/* Demo 2 and demo 3 */
.demo-loading .show,
.demo-loading .hide {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.codrops-logo {
  position: relative;
  margin: 0 auto 30px;
  width: 80px;
  height: 80px;
  background: url(../images/codrops_logo.png) no-repeat 50% 50%;
}

.demo-loading .headline {
  position: relative;
  color: #2173a3;
  text-align: center;
}

.demo-loading .headline div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5ca9d6;
}

.demo-loading .headline h1 {
  margin: 0;
  padding: 3em 0;
  font-size: 3em;
  position: relative;
  z-index: 10;
}

.demo-loading .headline h1 span:last-child {
  display: block;
  font-weight: 300;
  font-size: 50%;
}

.demo-loading section {
  margin: 2em auto 8em;
  max-width: 700px;
  text-align: center;
}

.demo-loading section figure {
  position: relative;
  display: inline-block;
  margin: 0 15px 20px;
  width: 200px;
  text-align: left;
}

.demo-loading section figure img {
  display: block;
  margin: 0 auto 10px;
}

.demo-loading svg.line-drawing {
  position: absolute;
  max-width: 100%;
  z-index: 10;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.demo-loading svg.line-drawing path {
  fill: none;
  stroke-width: 2;
  stroke: #ddd;
}

.demo-loading svg.line-drawing path.darker {
  stroke: #aaa;
}

.demo-loading svg#rectangle {
  width: 100%;
  height: 100%;
}

.demo-loading svg#headline {
  top: 50%;
  margin-top: 2px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.demo-loading svg#related {
  width: 100%;
  height: 100%;
  max-width: 490px;
}

.is-primary:hover {
    color: $secondary !important;
}

.is-secondary:hover {
    color: $primary !important;
}

@media(max-width:1080px) {
  .home-service-resp {
    max-width: 500px;
    text-align: center !important;
    margin: 0 auto;
  }
  .home-service-img-resp {
    max-width: 600 !important;
  }
}

.artboard-img {
  max-width: 100%;
  max-height: 700px !important;
}

@media(min-width:1080px) {
  .ml-per-25 {
    margin-left: 25% !important;
  }
  .ml-per-20 {
    margin-left: 20% !important;
  }
  .rotatory-resp {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: -5rem !important;
  }
}

.team-card-height {
  height: 350px !important;
}

.null-pt-mb {
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}

.team-image {
  width: 150px !important;
  height: 150px !important;
}

.br-20 {
  border-radius: 20px !important;
}



/* our work single page */


// .background:after {
//   content: "";
//   position: absolute;
//   display: block;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABhJREFUeNpiYWBg2M+ABpgYsAAKBQECDABPDADMTfZLVgAAAABJRU5ErkJggg==);
// }

// @media (max-width: 1030px) {
//   .background {
//       position: fixed;
//   }
// }

// .content {
//   position: relative;
//   background-position: 50% 50%;
// }

// .content-wrapper {
//   padding-top: 83px;
//   padding-bottom: 85px;
//   background-color: #fff;
// }

// .content--bgColor--transparent {
//   color: #fff;
//   background-color: transparent;
// }

// .content--bgColor--grey {
//   background-color: #f7f7f7;
// }

// .content-title h3 {
//   margin-top: 0;
//   margin-bottom: 32px;
//   font-size: 40px;
//   line-height: 42px;
//   color: #d13a7a;
// }

// .content--bgColor--transparent .content-title {
//   color: #fff;
// }

// .content-description {
//   margin-top: 32px;
//   margin-bottom: 19px;
//   font-size: 25px;
//   line-height: 38px;
//   color: #234;
// }

// .content-title+.content-description {
//   margin-top: 0;
// }

// .content-description p {
//   margin-top: 0;
//   margin-bottom: 0;
// }

// .content--bgColor--transparent .content-description {
//   color: #fff;
// }

// .content p:last-child {
//   margin-bottom: 0;
// }

// .content-asset {
//   position: relative;
//   margin-top: 20px;
//   margin-bottom: 20px;
//   line-height: 0;
// }

// .content-asset:after,
// .content-asset:before {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   display: none;
//   content: "";
// }

// .content-asset:first-child {
//   margin-top: 0;
// }

// .content-asset:last-child {
//   margin-bottom: 0;
// }

// .content-asset:before {
//   width: 60px;
//   height: 60px;
//   margin-top: -30px;
//   margin-left: -30px;
//   background: #000;
//   background: rgba(0, 0, 0, 0.8);
//   border-radius: 5px;
//   -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
//   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
// }

// .screenshots-zoom:after,
// .stickyNavigation-link:after {
//   border-radius: 50%;
//   content: "";
// }

// .content-asset:after,
// .intro,
// .intro-content ul li:after,
// .screenshots-zoom:after {
//   background-repeat: no-repeat;
// }

// .content-asset:after {
//   width: 32px;
//   height: 32px;
//   margin-top: -16px;
//   margin-left: -16px;
//   background-position: 50% 50%;
//   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAklJREFUeNrU189LFGEcx/FdSwMVsrpomZX9uBR50ahD0alDYNEliToIXSK8CkVEh4igS0X0ByQoeqqLdIlOBRVBRD+0XwZlhVZCedDU2N5f+gwM2+z67LPPGA68WOaZ2X0+88zM93k2m8vlMv9zyzoEaMUB7ECD2qYxjPvo0X4qAc6hE5UYwShmUIdNaMQQzuCuVwILkGA3PuEdOgucY1ajL/d3GyhyXkFJjYf0g1djbfsxiKd4iQe4jGodb8EXvCg3QKs6P6H9LXiOCfRoNA7iLB7iF87Hvj+GR74B7HmYxZVY20ncQ12BHziq4yu1X6ULOO0T4AImfe5jgVtYU0qA7fiB4wECmA95t2beAJfwFUsDBbDRfOZyboXexha8wlygAjeIFaid78QoQD2+BaywIypeDa4BKgNevW2z+lzmGmASNQEDWKm2Gj/hGuA9mgIGaMMUxlwD3EEzNgYK0IG3+O06GVWrzvcFeAWjariv1Eq4R19cV2aAXgz7Tkb9mmB8Oz+ii6gvZzp+jY+oLbHzw5jDNLqxExt8ApjH+IkOh45X4YaufEadDmnfRnOtTwBzCt9V0y9iF9ZoeK2TY7iptcITHb+OW1pNdeGagjT7BDDLNavZCmhUq57PWni8wW20JyxoumJtA8VClHKPbag3YxuasCThHDs2nreoiR7uxBCZQNNv3Hp11u8SIo0AGXWSFKJX646taQcoFmJcq6/UA8RDRP8ZuvVqti9UgCjElMqzbXsX4hnI16ji9M8ElV0M/45T3f4IMAC3vGdsRRn5owAAAABJRU5ErkJggg==);
// }

// .content-asset--zoom--no:after,
// .content-asset--zoom--no:before {
//   display: none;
// }

// .content-img {
//   max-width: 100%;
// }

// .content--list--type1 ul {
//   position: relative;
//   margin: 0 0 40px;
//   padding: 30px 27px 0 20px;
//   list-style-type: none;
//   color: #fff;
//   background-color: #d13a7a;
// }

// .content--list--type1 ul:after {
//   content: "";
//   display: table;
//   width: 100%;
// }

// .content--list--type1 ul li {
//   position: relative;
//   padding-left: 45px;
//   width: 50%;
//   float: left;
//   margin-bottom: 35px;
// }

// .content--list--type1 ul li:after {
//   content: "";
//   display: block;
//   position: absolute;
//   width: 18px;
//   height: 15px;
//   top: 7px;
//   left: 15px;
//   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAYAAADphp8SAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAANpJREFUeNpi/P//PwOFQAiIFZgoNMQbiHOB+DwLmQZwA3E/EIsDcRAQ/2cAeY1EbALEN4H4BBBzwcRJNSQViH8A8WMgFkeWI8WQ8v8Q8B2IjdHliTUkGYj/QQ1KwqYGmdMAxPOBmBtNkSMQ/4EashSXZTBG438EuA7EOlBxSSB+BRV/CMQC+AxCNgQGvgJxAhCvhfL/Ql3GgM+gSCD+8B8/6CMUjjCGPBAfwWHIIyDmIdYgEGYG4hog/o1mUCAxMYtN0AKI70ANOUxsOsMlwQfEC4DYgViDAAIMAJxkwVLjoK8LAAAAAElFTkSuQmCC);
// }

// .content--list--type2 ul {
//   margin: 0 0 20px;
//   padding: 0;
//   list-style-type: none;
// }

// .content--list--type2 ul li {
//   position: relative;
//   padding-left: 45px;
// }

// .content--list--type2 ul li+li {
//   margin-top: 15px;
// }

// .content--list--type2 ul li:after {
//   content: "";
//   display: block;
//   position: absolute;
//   width: 15px;
//   height: 11px;
//   top: 11px;
//   left: 23px;
//   background-repeat: no-repeat;
//   background-position: 0 0;
//   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAANlJREFUeNqU0T8LQVEYx3EXSVaTxW6ymf1PiO7gJRgMMhlMTAaTxeoNUGIwKNMti8WglFlJBklJJt9Tz61DqHvqU+fp3t/pnOcxTCvmcriqiKLudRisYQADHreDYEMLPrG0w0H4/gSb6GvBCsYqHIKF0Y8DWujJ/gETM1Wo8BARlDCBXwt20JX9HWXM7Y9u6d5e6jymCEiorQWLWOhXUt0+IK4aIDfIYoew/HNDQZ72tuyGHZHAVmo7eEXuW1APq3VCEhupL8hg9WsEn3M+IyVvS2P9b/AvAQYAvxkoInbfMQUAAAAASUVORK5CYII=);
// }

// .content-attention {
//   margin-top: 40px;
//   margin-bottom: 40px;
//   color: #fff;
//   background-color: #d13a7a;
//   padding: 30px 51px;
// }

// .content-attention p:last-child,
// .content-attention:last-child {
//   margin-bottom: 0;
// }

// .content-attention p:first-child {
//   margin-top: 0;
// }

// .content-num {
//   position: absolute;
//   top: 0;
//   left: -50px;
//   font-size: 80px;
//   line-height: 80px;
//   color: #f7f7f8;
//   opacity: 0;
//   -webkit-transition: opacity 0.2s ease;
//   transition: opacity 0.2s ease;
// }

// .content .slider,
// .description,
// .intro {
//   position: relative;
// }

// .content-num--visible,
// .stickyNavigation+.content--navigation .content-num {
//   opacity: 1;
// }

// .content--bgColor--grey .content-num {
//   color: #eff0f0;
// }

// .content--bgColor--dark .content-num,
// .content--bgColor--transparent .content-num {
//   color: #fff;
//   opacity: 0;
// }

// .content--bgColor--transparent .content-num--visible.content-num {
//   opacity: 0.1;
// }

// .content .slider .owl-wrapper-outer {
//   padding-top: 20px;
//   padding-bottom: 20px;
//   background-color: #d13a7a;
// }

// .content .slider-item {
//   padding-left: 15px;
//   padding-right: 15px;
//   line-height: 0;
// }

// .content .slider-img {
//   width: 100%;
// }

// .content .slider .owl-theme .owl-controls {
//   margin-top: 40px;
// }

// .content .slider .owl-theme .owl-controls .owl-page span {
//   width: 10px;
//   height: 10px;
//   margin-top: 0;
//   margin-left: 7px;
//   margin-right: 7px;
//   background-color: #d7d7d7;
//   opacity: 1;
// }

// .content .slider .owl-theme .owl-controls .owl-page.active span {
//   background-color: #d13a7a;
//   opacity: 1;
// }

// @media (max-width: 1440px) {
//   .content-num {
//       left: -150px;
//   }
// }

// @media (max-width: 1030px) {
//   .content {
//       min-height: 0;
//   }

//   .content-wrapper {
//       padding-left: 20px;
//       padding-right: 20px;
//   }

//   .content-list .contentList {
//       width: 100%;
//       display: block;
//   }

//   .content-list .contentList+.contentList {
//       margin-left: 0;
//       margin-top: 34px;
//   }
// }

// @media (max-width: 767px) {
//   .content-wrapper {
//       padding: 45px 5px 50px;
//   }

//   .content-description {
//       font-size: 21px;
//       line-height: 32px;
//   }

//   .content--list--type1 ul {
//       padding-top: 25px;
//       padding-left: 5px;
//       padding-right: 15px;
//   }

//   .content--list--type1 ul li {
//       width: 100%;
//       height: auto !important;
//       float: none;
//       margin-bottom: 25px;
//   }

//   .content-attention {
//       padding: 20px 30px;
//   }

//   .content-title {
//       font-size: 36px;
//       line-height: 40px;
//   }

//   .content-asset:after,
//   .content-asset:before {
//       display: block;
//   }

//   .content-asset--zoom--no:after,
//   .content-asset--zoom--no:before {
//       display: none;
//   }
// }

// .intro-content ul li:after,
// .intro-wrapper {
//   display: block;
//   content: "";
// }

// @media only screen and (-webkit-min-device-pixel-ratio: 2),
// only screen and (min--moz-device-pixel-ratio: 2),
// only screen and (-o-min-device-pixel-ratio: 2/1),
// only screen and (min-device-pixel-ratio: 2) {
//   .contentList-item:after {
//       background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAYAAADXYyzPAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAcNJREFUeNq8lssrxUEUx11kIVuFyKNI/gBLRCR55LFAIUlSFsrCgh0L4S5YeHTLoyjdYmVBEpKFhUgiEiKP8igSdbnxnTq/mn7N+b343VOfxcy5zWdm7vzmjKdyOzssBFEC6kAz+BYdkSGQVgA/iALRNIFAuMvSGkkqogosirab4lqwIEm1KAWzbokbwByIUOSewaAb4hYwzUgfQT7Y/29xG/Ax0geQBw5FQy8uB7EOpR1gHHgUuTuSHmsdsrgJLIFNEGdT2gVGGekNyAWncqcmbgVTtEVZYAMkWJR2g2FGekXSc31CiNvBpG71mbTyJBNpLxhgchckvVQlhSyGmW062AIpzMD9oI/JnYEccM3NWIiHQCf4UeRTaeVpun6xyh5mzBM6SLdGW6Vt7widSpU8mVaeQTvjpf9VFUckvTc7GHKRGANfYELxmSXSytdAIzPWASgET1ZOpF7go5snqPhtvIF0DxRYlarEImbomw5aHGOXpC92PnzuypwH9bT1RrEDisCr3WvO6K72U2kLMHlx4IrBm5P71axIiCu0WiFfp+fMu9NqYqU6LdPz5ZPaq6AMfPyljFktiyvS20mehOP4FWAAo4pWDuMiMFQAAAAASUVORK5CYII=);
//       background-size: 15px 11px;
//   }

//   .content-list .contentList-item:after {
//       background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeCAYAAABE4bxTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAbZJREFUeNrMlr1LAzEYh1t1KH4UFKRbRRxEUAcHdXC61cVNQRT8GtStg4MoVHf1PxARF3cRipPVwVFEcbB0cldQURGMv9AEz5jc5e6Su77wlKN5E57em75JmhCSqpPoAPmGOpEZB6vguilhkSzYBRkwQ79I8g054AbkwDyo7R26h2ImA/bAN7gCze7xuGUGwS2pRQV0ijlxiaRBAXwwmWcwIMuNQyYHzshvfAJHlW9bZgQ8kr8x6zXHpsySq0Q8dvzm2ZLZJP+jBBqTECpIZKqgXWe+aZlp1l/c8Q6GdNcwKeOwf5AYi0HWUQ0UwSFo1Vyol/UWMY6C/jCVDI970O+zSAu4k8jQTpyNKrQlWfgNzHksciyZQ0s3HKb0fjLi6xdLuKzIXQu7F/nDNtELdwm7wYsk51Kn33gJ6cq4S7gATiVjr6Anyr+VfkyBJ2ImVqK2D/7QBcoRZcrsmmFEKMXqvgG+QsjQQ7TPRINVXRkqAYWKpjq+aqANHGjKVNk92aoQZ1Jjw0+YPKB1kvLgXCFzYfr6optIN/y6ZMOPJSXEoefTA5M5sXHbDDOJnmf7YNSG0I8AAwBztc8GMuGO0gAAAABJRU5ErkJggg==);
//       background-size: 18px 15px;
//   }

//   .content-asset:after {
//       background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABRpJREFUeNrsm11sVEUYhretUFsipfwEASulFJC/GENMDRiU5QKNpnIBQQOYmAhkI4RwIRcmJgheeAEEE6Jc4IUIegEmAtGIkoIk/IUEqpQSsBYsRQEJpdAKQmF5v/ieMDk5u909O3POnGUneTKn29k5870z8505880WJZPJ2KOcigoCFATQJsAEEAdTwTgwElSCYiA36QBt4Cw4DPaDxqgLMBgsAm+DZ3x8vxVsA5+Dv6MkgPTsh2AJKOdn3WAvOMiebWGvd4IK0B+MBpPBdDATDOB374IvwCpwOVAFRIAsmQ+uJB+mg2AeKMuynlLwBvhZqasTJECRj3b5IpvC5WCL0tgTYIamhjxPIZ30HagMQoBMp8AQsBvUgR7wAVgP7nmUlWE9DTwHqkApuAMugibwC7jqNR3BYrABPA6awSzQHvYUGAKa2DMy9OtSlIuDneBuMn26B34Cr6UY6hPBeZa9AKrCHAHi4BrY863skRZXmVH04rOUz37lo66VzlHqqWY9U9jbMTrMxextNY0AP4JJ4DR4EVwLYwRsUXq+1uP/r9NxSfoXrAU1vdQ5AqxRvncLLPAoNwycY5nvTTnG3rx9kkPaa9gPBjdZpgGMzPLmQ+nsJPWAsR5lxoMullkRpAADlEfdyjQVyLzfAEp8NkB69SOKnarMO2xHF0dPIAKs500bczBOJw1sz1dBOEFZ3v5JxyVr+30WvLOIM/wN3AdjwDldFRd7fLaIxh+yxPgY1w+yDikBy00/BZo53N60YOirzGS7Lumclu4pIK+0p/jsltXfLYte3Yu5KhwGXuaKUvsUiDPfa5nxMc7/H3g9w5QPeEFZodmYnF6fZkqA8cwbLRWgiflYUxsi8pY2CNTofNRoTP1AF7fY+qR4G81pBFQy77B0BHTzdVxepp4wMQWcvzst3sjtZt7fhADOfKiwWIAy5jdMCNChU11Dxvd1jQStArQxH22pALXM27mTrF2As8rLh41pIvPfTa0DDjN/yVIBnHYdMSXAAWVJXGqZ8fLoe8W1ItQuwAluZFa6NjltSHXcWO3Q+Zru9Rj8htdLLRNgGfPtsf/jDEaWwpKGg/NcasoW9nELjK+hgy6hI2w2uSP0F/iS15/GHu7hh5nW0fidOo1PtSPkbFnf4A7MuyHvBM1mO/4DY4KMCyzljbsZrgrD+ColgLI66MCI+IfdvHkreDJg4ytcMcLHwgiPDwKn2YiTnBpBGd/sCqp+Etb5gKdBOxvRAsYZNv4ppeclHXOJIDvCW3mQIrADEhL3O8NGSDxwoSHj6xXnqwZFE8pnp5TrRJAnRCQYuke5+R4GL3UYXg2+Vep2gq7/gMks857y//u6RPATzHyfoXCnITvAdFDsowF1jPc5hyruMHQuvueoIsKzYJsSSa7ndMhZBL/KiV/42tUTbWATeIu95j401RdMAHPBRvCHy8ntcoXIKxQRbisC1StlchYh16FbS2OupDgOIyPluhLjdyf532YwKUX9A8FVV8+7y+Qkgi7n1Yexu4/BPnAxhcES1zvARr/Ko3Lp6t2qTLX6NOV8i2DycVZGx1nNg1blPupIZLEO8CWCTdHfUESIggBGRYiKAMZEiJIARkSImgDaRYiiAFpFiKoA2YgQ5yIqybPMeSNAJiLElfeWXVyO55UA6UTo1fh8EcBLhIyMzycB3CL0ZGJ8Nr8YiUpKgM94LSdL5/QWRcrHH04mGESdm0kIrfDL0YIABQEebQEeCDAAanzo3ALz7+oAAAAASUVORK5CYII=);
//       background-size: 32px 32px;
//   }
// }

// .description {
//   padding-top: 64px;
//   padding-bottom: 60px;
//   background-color: #fff;
// }

// .description-text {
//   margin: 0;
//   font-size: 25px;
//   line-height: 38px;
//   color: #234;
// }

// .description-text p {
//   margin: 0;
// }

// .description-text p+p {
//   margin-top: 15px;
// }

// @media (max-width: 1030px) {
//   .description {
//       padding-left: 35px;
//       padding-right: 35px;
//   }
// }

// @media (max-width: 767px) {
//   .description {
//       padding-left: 5px;
//       padding-right: 5px;
//   }

//   .description-text {
//       font-size: 21px;
//       line-height: 32px;
//   }
// }

// .intro {
//   padding-top: 137px;
//   padding-bottom: 36px;
//   color: #fff;
//   background-position: -9999px 50%;
//   background-size: cover;
// }

// .intro-wrapper {
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   background-color: #fff;
//   opacity: 0;
// }

// .intro-title {
//   margin-top: 0;
//   margin-bottom: 50px;
// }

// .intro-content ul {
//   margin: 0 0 20px;
//   padding: 0;
//   list-style-type: none;
// }

// .intro-content ul li {
//   position: relative;
//   padding-left: 30px;
// }

// .intro-content ul li+li {
//   margin-top: 12px;
// }

// .intro-content ul li:after {
//   position: absolute;
//   width: 15px;
//   height: 11px;
//   top: 8px;
//   left: 3px;
//   background-position: 0 0;
//   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAK5JREFUeNpi+P//PwOJOBWIpwAxIwsDaSATiKcCMSMQM5NiYz4Q//sPAT+BOAQmIQzEbHg0lv5HAJBGP5A4SEISiK8B8UYcBlQiafwOxF4wORCxHUlyKxBzIGlsQJL7CsRuyAaDCBkgvomkaCcQcwFxG5pGR3RXwRgwp8PAQyT2JyC2xRYWyBxxIL78HxV8AGIrXAGJLiAKxOehGt8BsSm+6MMmKAT1txGhuAcIMACEbu3iPA+9UAAAAABJRU5ErkJggg==);
// }

// @media (max-width: 1030px) {
//   .intro {
//       padding-left: 20px;
//       padding-right: 20px;
//   }
// }

// @media (max-width: 767px) {
//   .intro {
//       padding-top: 92px;
//       padding-left: 5px;
//       padding-right: 5px;
//   }

//   .intro-title {
//       font-size: 42px;
//       line-height: 54px;
//   }

//   .intro-col {
//       width: 100%;
//       float: none;
//   }
// }

// @media only screen and (-webkit-min-device-pixel-ratio: 2),
// only screen and (min--moz-device-pixel-ratio: 2),
// only screen and (-o-min-device-pixel-ratio: 2/1),
// only screen and (min-device-pixel-ratio: 2) {
//   .intro-content ul li:after {
//       background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAYAAADXYyzPAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAWVJREFUeNpi+P//PwMdsBcQLwZiFpgYPSz1B+Kf/yFgLRCz0cPiECRLYWAzyHJGsO20ARFAvASImbHIraCVT2OB+M9/7OANEBvSwtJkPJa+AmI9WsRxOhD/w2HpcyDWwpWq/YBYlExLc/BY+hSI1ZHVI2uMhwbRVSCWINHSYjyWPgJiFXQ9MEYqEP9FUnwdiKWItLT8P25wH4gVsekDEZk4XHsLiGUJWFqDx9K7QCyHSy+IKMWj+R4QK+DQ3IJH300glsbnaBgjH08cPQBiJTSNHXgsvQbEkoSiCJmThcfyx0CsBsSgkq4Xj6WXgViMmLSBLoCeyJDBMyBeiMfS80AsQmxOwCaYgKfkwQXOALEQKVkQl0Q0CZafAGJ+UgscfJJhQPyLgKVHgJiPnJKOkIIgLPUpDBwAYh5yy3ViFPlgsXwPEHNRUqEQq9ADiL9BLd0BxJyU1mSkKHYF4pVAzE6NKhQgwADPixbtF7PrcwAAAABJRU5ErkJggg==);
//       background-size: 15px 11px;
//   }
// }

// .screenshots {
//   position: relative;
//   padding-top: 75px;
//   padding-bottom: 56px;
//   background-color: #fff;
// }

// .screenshots-title {
//   position: relative;
//   margin-bottom: 54px;
//   text-align: center;
// }

// .screenshots-title h3 {
//   margin-top: 0;
//   margin-bottom: 0;
//   font-size: 39px;
//   line-height: 39px;
//   text-transform: uppercase;
// }

// .screenshots-container {
//   position: relative;
//   height: 420px;
//   overflow: hidden;
// }

// .screenshots-list {
//   margin: 0;
//   padding: 0;
//   list-style-type: none;
//   opacity: 0;
//   -webkit-transition: opacity 0.3s ease;
//   transition: opacity 0.3s ease;
// }

// .screenshots-zoom,
// .screenshots-zoom:after {
//   -webkit-transition: all 0.2s ease;
//   transition: all 0.2s ease;
//   position: absolute;
// }

// .screenshots.inited .screenshots-list {
//   opacity: 1;
// }

// .screenshots-items {
//   position: relative;
//   display: inline-block;
//   vertical-align: top;
//   max-width: 100%;
//   font-size: 0;
//   line-height: 0;
// }

// .screenshots-popup .mfp-content,
// .statistics-cell {
//   vertical-align: middle;
// }

// .screenshots.ready .screenshots-items {
//   height: 370px;
// }

// .screenshots.ready .screenshots-items img {
//   max-width: 100%;
//   max-height: 370px;
// }

// .screenshots-zoom {
//   display: none;
//   width: 100px;
//   height: 100px;
//   top: 50%;
//   left: 50%;
//   margin-top: -50px;
//   margin-left: -50px;
//   opacity: 1;
//   cursor: pointer;
//   z-index: 8;
// }

// .screenshots-items.hasZoom .screenshots-zoom {
//   display: block;
// }

// .screenshots-zoom:after {
//   display: block;
//   width: 44px;
//   height: 44px;
//   top: 50%;
//   left: 50%;
//   margin-top: -22px;
//   margin-left: -22px;
//   background-position: 50% 50%;
//   background-image: url(../fonts/ico-case-screenshot.svg);
//   background-size: 20px;
//   background-color: #d13a7a;
// }

// .screenshots-zoom:hover:after {
//   -webkit-transform: scale(1.1);
//   -ms-transform: scale(1.1);
//   transform: scale(1.1);
// }

// .screenshots.center .lightSlider {
//   margin-left: auto;
//   margin-right: auto;
//   -webkit-transform: translate3d(0, 0, 0) !important;
//   transform: translate3d(0, 0, 0) !important;
// }

// .screenshots.center .lightSlider li {
//   margin-left: 50px !important;
//   margin-right: 50px !important;
// }

// .screenshots.center .lightSlider * {
//   cursor: default !important;
// }

// .screenshots.center .lSPager {
//   display: none;
// }

// .screenshots-clone {
//   position: absolute;
//   width: 100px;
//   left: -9999px;
// }

// @media (max-width: 767px) {
//   .screenshots {
//       padding-top: 45px;
//       cursor: pointer;
//   }

//   .screenshots-container {
//       height: 300px;
//   }

//   .screenshots.ready .screenshots-items {
//       width: 100% !important;
//       height: 250px;
//   }

//   .screenshots.ready .screenshots-items img {
//       max-height: 250px;
//   }
// }

// .screenshots .lSSlideOuter .lSPager.lSpg {
//   margin-top: 24px !important;
// }

// .screenshots-popup .mfp-bg {
//   background: rgba(0, 0, 0, 0.6);
//   z-index: 9998;
// }

// .screenshots-popup .mfp-wrap {
//   z-index: 9999;
// }

// .statistics {
//   position: relative;
//   padding-top: 60px;
//   padding-bottom: 50px;
//   color: #fff;
//   background-color: #4abd30;
//   background-repeat: repeat;
//   background-position: 0 0;
//   background-image: url(../images/case.statistics.pattern.png);
// }

// .statistics:after {
//   content: "";
//   position: absolute;
//   display: block;
//   width: 1px;
//   height: 120px;
//   top: 50%;
//   left: 50%;
//   margin-top: -60px;
//   background-color: #5ec447;
// }

// .statistics-item {
//   display: inline-block;
// }

// .statistics-table {
//   display: table;
// }

// .statistics-row {
//   display: table-row;
// }

// .statistics-cell {
//   position: relative;
//   display: table-cell;
//   text-align: left;
// }

// .statistics-num {
//   float: left;
//   padding-right: 23px;
//   font-size: 90px;
//   line-height: 90px;
// }

// .statistics-title {
//   margin-bottom: 4px;
//   padding-top: 15px;
//   font-size: 36px;
//   line-height: 36px;
// }

// .statistics-description {
//   font-size: 19px;
//   line-height: 30px;
// }

// .statistics-icon {
//   position: absolute;
//   right: 100%;
//   top: 50%;
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
//   transform: translateY(-50%);
//   margin-right: -30px;
// }

// @media (max-width: 1030px) {
//   .statistics:after {
//       display: none;
//   }
// }

// @media (max-width: 767px) {
//   .statistics {
//       padding: 30px 5px;
//   }

//   .statistics-icon {
//       display: none;
//   }

//   .statistics-num {
//       display: block;
//       width: 100%;
//       float: none;
//       padding-right: 10px;
//       font-size: 58px;
//       line-height: 58px;
//       text-align: right;
//   }

//   .statistics-title {
//       margin-bottom: 0;
//       padding-top: 0;
//       font-size: 21px;
//       line-height: 32px;
//   }

//   .statistics-description {
//       font-size: 16px;
//       line-height: 25px;
//   }
// }

// @media (max-width: 560px) {
//   .statistics-col {
//       width: 100%;
//       float: none;
//   }

//   .statistics-col+.statistics-col {
//       margin-top: 20px;
//   }

//   .statistics-item {
//       display: block;
//   }

//   .statistics-table {
//       width: 100%;
//   }

//   .statistics-cell {
//       width: 40%;
//   }

//   .statistics-cell+.statistics-cell {
//       width: 60%;
//   }
// }

// .stickyNavigation {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
// }

// .stickyNavigation .container,
// .stickyNavigation .row {
//   height: 100%;
// }

// .stickyNavigation-holder {
//   display: block;
//   height: 100%;
//   float: none;
// }

// .stickyNavigation-wrapper {
//   position: absolute;
//   width: 200px;
//   height: 100%;
//   left: 0;
//   top: 0;
//   padding-top: 192px;
//   padding-bottom: 150px;
// }

// @media(min-width:1600px){
// .stickyNavigation-wrapper {
//   left: 100px!important;
// }
// }

// .stickyNavigation-col {
//   position: relative;
//   height: 100%;
//   padding-right: 18px;
//   border-right: 1px solid #eaeaea;
//   border-right: 1px solid rgba(128, 128, 128, 0.3);
//   z-index: 1;
// }

// .stickyNavigation-container {
//   position: relative;
//   width: 100%;
//   z-index: 2;
// }

// .stickyNavigation-list {
//   position: relative;
//   padding: 0;
//   margin: 0;
//   list-style-type: none;
// }

// .stickyNavigation-item {
//   position: relative;
//   display: block;
//   text-align: right;
//   padding-bottom: 25px;
// }

// .stickyNavigation-item+.stickyNavigation-item {
//   margin-top: 11px;
// }

// .stickyNavigation-link {
//   font-size: 20px;
//   line-height: 25px;
//   color: #999;
//   text-decoration: none;
//   -webkit-transition: color 0.4s ease;
//   transition: color 0.4s ease;
// }

// .stickyNavigation-link:hover {
//   color: #d13a7a;
// }

// .stickyNavigation-link:active {
//   color: #fff;
// }

// .stickyNavigation-link:after {
//   position: absolute;
//   display: block;
//   width: 11px;
//   height: 11px;
//   top: 11px;
//   right: -24px;
//   background-color: #eaeaea;
//   -webkit-transition: all 0.2s ease 0.3s;
//   transition: all 0.2s ease 0.3s;
// }

// .stickyNavigation-link--active {
//   color: #d13a7a;
// }

// .stickyNavigation-list--white .stickyNavigation-link--active {
//   color: #fff;
// }

// .stickyNavigation-link--active:after {
//   width: 19px;
//   height: 19px;
//   top: 7px;
//   right: -28px;
//   background-color: #d13a7a;
// }

// @media (max-width: 1200px) {
//   .stickyNavigation-wrapper {
//       left: 0;
//   }
// }

// @media (max-width: 1030px) {
//   .stickyNavigation {
//       display: none;
//   }
// }

// .tags-section+.tags-section {
//   margin-top: 10px;
// }

// .tags-title {
//   margin-bottom: 11px;
//   font-size: 19px;
//   list-style-type: 30px;
//   color: #fff;
//   text-transform: none;
// }

// .tags-item {
//   font-size: 15px;
// }

// .technologies {
//   position: relative;
//   padding-top: 60px;
//   background-color: #fff;
// }

// .technologies.center .lightSlider {
//   margin-left: auto;
//   margin-right: auto;
//   -webkit-transform: translate3d(0, 0, 0) !important;
//   transform: translate3d(0, 0, 0) !important;
// }

// .technologies.center .lightSlider * {
//   cursor: default !important;
// }

// .technologies .lightSlider {
//   font-size: 0;
// }

// .lSSlideOuter .lSPager.lSpg>li a {
//   width: 10px;
//   height: 10px;
//   background-color: #d7d7d7;
// }

// .lSSlideOuter .lSPager.lSpg>li.active a,
// .lSSlideOuter .lSPager.lSpg>li:hover a {
//   background-color: #d13a7a;
// }

// .technologies.center .lSPager {
//   display: none;
// }

// .technologies .slider li:first-child .slider-item {
//   padding-left: 0;
// }

// .technologies .slider li:last-child .slider-item {
//   padding-right: 0;
// }

// .technologies .slider-item {
//   position: relative;
//   display: inline-block;
//   vertical-align: top;
//   max-width: 100%;
//   height: 56px;
//   padding-left: 30px;
//   padding-right: 30px;
//   font-size: 0;
//   line-height: 56px;
//   text-align: center;
//   overflow: hidden;
// }

// .technologies .slider-item img {
//   display: inline-block;
//   vertical-align: middle;
//   max-width: 100%;
//   max-height: 50px;
// }

// .technologies .lSSlideWrapper:after,
// .technologies .lSSlideWrapper:before {
//   content: "";
//   position: absolute;
//   display: block;
//   width: 50px;
//   height: 100%;
//   z-index: 5;
// }

// .technologies .lSSlideWrapper:before {
//   top: 0;
//   left: 0;
//   background: -webkit-linear-gradient(left,
//       rgba(255, 255, 255, 1) 0,
//       rgba(255, 255, 255, 0) 100%);
//   background: -webkit-gradient(linear,
//       left top,
//       right top,
//       color-stop(0, rgba(255, 255, 255, 1)),
//       to(rgba(255, 255, 255, 0)));
//   background: linear-gradient(to right,
//       rgba(255, 255, 255, 1) 0,
//       rgba(255, 255, 255, 0) 100%);
//   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
// }

// .technologies .lSSlideWrapper:after {
//   top: 0;
//   right: 0;
//   background: -webkit-linear-gradient(left,
//       rgba(255, 255, 255, 0) 0,
//       rgba(255, 255, 255, 1) 100%);
//   background: -webkit-gradient(linear,
//       left top,
//       right top,
//       color-stop(0, rgba(255, 255, 255, 0)),
//       to(rgba(255, 255, 255, 1)));
//   background: linear-gradient(to right,
//       rgba(255, 255, 255, 0) 0,
//       rgba(255, 255, 255, 1) 100%);
//   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
// }

// .technologies.slider--mobile:after {
//   content: "";
//   position: absolute;
//   display: block;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
// }

// .technologies.slider--mobile .lightSlider {
//   width: 100% !important;
//   height: auto !important;
// }

// .technologies.slider--mobile .lightSlider li {
//   display: inline-block;
//   vertical-align: top;
//   float: none !important;
//   margin-bottom: 30px;
// }

// .technologies.slider--mobile .slider-item {
//   width: 100% !important;
// }

// .technologies.slider--mobile .lSPager,
// .technologies.slider--mobile .lSSlideWrapper:after,
// .technologies.slider--mobile .lSSlideWrapper:before {
//   display: none;
// }

// .cs-hidden {
//   height: 1px;
//   opacity: 0;
//   filter: alpha(opacity=0);
//   overflow: hidden;
// }

// .technologies.slider--mobile .slider li:first-child .slider-item {
//   padding-left: 30px;
// }

// .technologies.slider--mobile .slider li:last-child .slider-item {
//   padding-right: 30px;
// }

// @media (max-width: 460px) {
//   .technologies.slider--mobile .lightSlider li {
//       width: 50%;
//   }
// }

// .wrapper {
//   position: relative;
// }



/* our work single page end*/




/* submit loading */


.loading{
  font-size:0;
  width:30px;
  height:30px;
  margin-top:5px;
  border-radius:15px;
  padding:0;
  border:3px solid #0069f9;
  border-bottom:3px solid rgba(255,255,255,0.0);
  border-left:3px solid rgba(255,255,255,0.0);
  background-color:transparent !important;
  animation-name: rotateAnimation;
  -webkit-animation-name: wk-rotateAnimation;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  }

  @keyframes rotateAnimation {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
  }
  @-webkit-keyframes wk-rotateAnimation {
  0%   {-webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}
  }

  /* submit loading end*/

  .mail-success{
    color:$secondary;
    font-weight: 500;
  }