/*! _responsive.scss | Bulkit | CSS Ninja */

/* ==========================================================================
General responsive styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. max-width 767px
1. max-width 768px
2. Between 768px and 1024px portrait orientation
3. max-width 1007px
4. Tablet Landscape
=============================================================================
***/

/* ==========================================================================
0. max-width 767px
========================================================================== */

@media (max-width: 767px) {
  //section title
  .section-title-wrapper {
    padding: 40px 0 !important;
  }
  .content-wrapper {
    padding-top: 40px !important;
  }
  .client-content-wrapper {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

/* ==========================================================================
1. max-width 768px
========================================================================== */

@media (max-width: 768px) {
  //Nav items
  .nav-item.is-tab {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .nav .nav-item.is-tab.is-active,
  .nav-menu.is-active .nav-item.is-tab.is-active {
    border-bottom: none !important;
    color: $primary !important;
  }
  .nav-item.nav-inner {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  a.nav-item.is-not-mobile {
    display: none !important;
  }
  .nav-item.is-menu-mobile {
    display: block !important;
    color: #7a7a7a !important;
  }
  .navbar {
    &.navbar-light {
      &.is-dark-mobile {
        background: rgb(92, 98, 218);
        .navbar-menu {
          background: $primary;
          a {
            color: $white;
          }
        }
        .white-navbar {
          background: $white;
          a {
            color: $primary;
          }
        }
      }
    }
    .navbar-menu {
      .navbar-link,
      .navbar-item,
      .navbar-dropdown {
        width: 100%;
        text-align: center;
      }
      .navbar-link {
        &:after {
          display: block !important;
          right: 43%;
        }
      }
      .button {
        width: 100%;
      }
    }
  }
  // .navbar-brand {
  //   min-height: 4.6rem;
  // }
  // .custom-burger {
  //   display: flex !important;
  // }
  .navbar-burger {
    height: 4.6rem;
    width: 4.6rem;
  }
  //Footer social icons
  .level-left.level-social {
    display: flex;
    justify-content: flex-start;
    .level-item:last-child .icon i {
      position: relative !important;
    }
  }
  //Main hero background position
  #main-hero {
    max-height: 100vh;
    background-position-x: 55% !important;
  }
  //Main title
  /*.title.main-title {
    margin-top: 45px;
}*/
  //Parallax overlay
  .parallax-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0 !important;
  }
  //Section title
  .title.section-title {
    font-size: 2rem !important;
  }
  //mockup and hero for components sections
  .hero.is-fullheight.is-halfed-mobile {
    height: 35vh !important;
  }

  //Client grids
  .three-grid,
  .four-grid,
  .five-grid {
    .column {
      border: none !important;
    }
    .is-separator {
      border: none !important;
    }
  }
  //Carousels
  .single-image-carousel .image-wrapper img {
    min-height: 50px !important;
  }
  .single-image-carousel .image-wrapper .caption {
    display: none;
  }
  //Video background
  .video-caption {
    left: 5%;
  }
  //menu toggles visibility on mobile
  #navigation-trigger,
  .navigation-close {
    opacity: 1 !important;
  }
  //Darken transparent navbar when responsive menu is open on mobile (only if it is a light navbar)
  .navbar-wrapper.navbar-fade.navbar-light.mobile-menu-dark {
    background-color: $sidebar !important;
    .nav-menu.is-active {
      background-color: $sidebar !important;
      .nav-inner.is-menu-mobile {
        color: $white !important;
        &.is-active {
          color: $primary !important;
        }
      }
    }
  }
  .navbar-wrapper.navbar-fade.mobile-menu-dark {
    background-color: $white !important;
  }
}

/* ==========================================================================
1. Between 768px and 1024px portrait orientation
========================================================================== */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  //tablet portrait orientation adjustments
  .columns {
    padding: 0 10%;
    &.is-tablet-portrait {
      padding: 10%;
    }
    &.is-not-tablet-portrait {
      padding: 0 !important;
    }
  }

  .content-wrapper {
    padding-top: 0 !important;
  }
}

/* ==========================================================================
2. max-width 1007px
========================================================================== */
@media (max-width: 1007px) {
  //Slide description padding
  .column.slide-description {
    padding: 20px 0 !important;
  }
  //Account
  .account {
    display: none;
  }
  //Media card settings
  .media-card {
    min-height: 300px !important;
  }
  .column.is-4.media-stretch {
    width: 100% !important;
    .media-card-image {
      min-height: 150px;
    }
  }
  .mcard-controls {
    position: relative !important;
  }
}

/* ==========================================================================
4. Tablet Landscape
========================================================================== */

@media only screen and (min-device-width: 768px) and (max-device-width: 1088px) and (orientation: landscape) {
  .navbar-brand {
    min-height: 4.6rem;
    height: 4.6rem;
    .custom-burger {
      display: flex;
    }
  }
  //Darken transparent navbar when responsive menu is open on mobile (only if it is a light navbar)
  .navbar-wrapper.navbar-fade.navbar-light.mobile-menu-dark {
    background-color: $sidebar !important;
    .nav-menu.is-active {
      background-color: $sidebar !important;
      .nav-inner.is-menu-mobile {
        color: $white !important;
        &.is-active {
          color: $primary !important;
        }
      }
    }
  }
  .navbar-wrapper.navbar-fade.mobile-menu-dark {
    background-color: $white !important;
  }
  .navbar {
    // &.navbar-light {
    //   &.is-dark-mobile {
    //     background: $sidebar;
    //     .navbar-menu {
    //       background: $sidebar;
    //       a {
    //         color: $white;
    //       }
    //     }
    //   }
    // }
    .navbar-menu {
      .navbar-link,
      .navbar-item,
      .navbar-dropdown {
        width: 100%;
        text-align: center;
      }
      .navbar-link {
        &:after {
          display: block !important;
          right: 43%;
        }
      }
      .button {
        width: 100%;
      }
      #signup-btn,
      #signup-btn-alt {
        width: 30% !important;
      }
    }
  }
  .tab-align {
    text-align: center !important;
    align-content: center !important;
    align-items: center !important;
  }
}
